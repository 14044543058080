/* eslint-disable */
/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

const SPIFFY_BLUE = 'rgb(51, 122, 183)';

const BadgeProgressContainer = styled.div`
  width: 96%;
  height: 18px;
  margin-top: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const BadgeIndicator = styled.div`
  width: 12px;
  height: 2px;
  border-radius: 1px;
  margin-left: 1px;
  margin-right: 1px;
`;

const ShowBadgeProgress = ({
  currentLevel,
  maxLevel,
  isBadgeFullyCompleted,
}) => {
  const indicatorArray = Array.from(Array(maxLevel).keys()).map(l => l + 1);

  return (
    <BadgeProgressContainer>
      {
        isBadgeFullyCompleted ? (
          <span style={{ fontSize: 10, fontWeight: 600, color: 'lightseagreen' }}>
            All Levels Complete
          </span>
        ) : (
          indicatorArray.map(l => (
            <BadgeIndicator
              key={l}
              style={{
                backgroundColor: (l === currentLevel + 1)
                  ? 'orange'
                  : l > currentLevel
                    ? 'lightgrey'
                    : SPIFFY_BLUE,
              }}
            />
          ))
        )
      }
    </BadgeProgressContainer>
  );
};

export default ShowBadgeProgress;

ShowBadgeProgress.propTypes = {
  currentLevel: PropTypes.number.isRequired,
  maxLevel: PropTypes.number.isRequired,
  isBadgeFullyCompleted: PropTypes.bool.isRequired,
};
