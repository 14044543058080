/* eslint-disable */
import React from 'react';
import styled from 'styled-components/macro';

const Wrapper = styled.div`
  width: 100%;
  max-width: ${props => props.maxWidth}px;
  margin: auto;
  margin-top: 20px;
  padding: 0px 10px;
`;

const MaxWidthWrapper = ({
  maxWidth = 1400,
  children,
}) => {
  return (
    <Wrapper maxWidth={maxWidth}>
      {children}
    </Wrapper>
  )
}

export default MaxWidthWrapper