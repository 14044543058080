/* eslint-disable */
import React from 'react'
import styled from 'styled-components/macro';
import { Paper, Typography } from '@mui/material';
import CompletionDetails from './CompletionDetails'
const Wrapper = styled(Paper)`
  position: absolute;
  left: 50%;
  top: 50%;
  text-align: center;
  transform: translate(-50%, -50%);
  min-height: 440px;
  min-width: 820px;
  background: ${props => props.theme.palette.background.paper};
`;

const SubmissionReviewModal = ({
  reviewSubmissionInfo,
}) => {
  return (
    <Wrapper>
      <CompletionDetails reviewInfo={reviewSubmissionInfo} />
    </Wrapper>
  )
}

export default SubmissionReviewModal;
