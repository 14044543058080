import { useQuery } from 'react-query';
import API from '../axios/API';

const getQuizzes = async () => {
  const { data } = await API.get('/quizzes/?version=3');
  return data;
};

const useAllModules = () => useQuery('availQuizzes', getQuizzes);

export default useAllModules;
