import { useQuery } from 'react-query';
import API from '../axios/API';

const getLeaderboard = async (type, teamId) => {
  let URL = `leaderboard/?type=${type}`;
  if (teamId) {
    URL = URL.concat(`&tm_id=${teamId}`);
  }
  const { data } = await API.get(URL);
  return data;
};

const useLeaderboard = (type, teamId) => useQuery(['moduleInfo', type, teamId], () => getLeaderboard(type, teamId));

export default useLeaderboard;
