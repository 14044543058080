import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { InputAdornment, Typography } from '@mui/material';
import styled from 'styled-components/macro';
import { ShoppingCart, Search } from 'react-feather';
import ComponentLoader from '../../../components/Loaders/ComponentLoader';
import AvailableBrand from './AvailableBrand';
import { CustomTextField } from '../../../styles/forms';
import NoBrandsFound from './NoBrandsFound';
import {
  ScrollBrands,
  FakeTab,
  BrandsContainer,
  CenterWrapper,
  FlexDiv,
  FlexBetween,
  ClearBtn,
} from './styles';

const NoneAvailableWrapper = styled.div`
  width: 100%;
  height: 212px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CustomTextFieldResponsive = styled(CustomTextField)`
  min-width: 200px;

  @media(max-width: 540px) {
    min-width: 100px;
    width: 100px;
  }

  @media (max-width: 400px){
    display: none;
  }

`;

const NoneAvailable = () => (
  <NoneAvailableWrapper>
    <Typography variant="h6">
      No Brands Available To Follow 🙃
    </Typography>
  </NoneAvailableWrapper>
);

const AvailableBrands = ({
  data,
  error,
  refetch,
  isLoading,
}) => {
  const [search, setSearch] = useState('');

  const brands = useMemo(() => {
    if (isLoading) return null;
    if (!data) return null;
    if (search.length > 2) {
      return data.companies
        .filter(company => company.name.toLowerCase().includes(search.toLowerCase()));
    }

    return data.companies;
  }, [data, search]);

  if (isLoading) return <CenterWrapper><ComponentLoader /></CenterWrapper>;
  if (error) return <CenterWrapper><div>Error getting data</div></CenterWrapper>;

  return (
    <BrandsContainer>
      <FlexBetween>
        <FakeTab>
          <div style={{
            height: 32,
            width: 32,
            border: '1px solid lightgray',
            marginRight: 8,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            background: 'white',
            borderRadius: 6,
          }}
          >
            <ShoppingCart />
          </div>
          <span style={{ whiteSpace: 'nowrap' }}>
            Available Brands
          </span>
          <span
            style={{
              marginLeft: 4,
              fontSize: 12,
              color: 'slategrey',
            }}
          >
            {`(${data.companies.length})`}
          </span>
        </FakeTab>
        <FlexDiv>
          <CustomTextFieldResponsive
            type="text"
            placeholder="Search brands..."
            size="small"
            variant="outlined"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search height={14} width={14} />
                </InputAdornment>
              ),
              endAdornment: null,
            }}
          />
          <ClearBtn
            variant="text"
            size="small"
            sx={{ ml: 1 }}
            disabled={search.length < 3}
            onClick={() => setSearch('')}
          >
            Clear
          </ClearBtn>
        </FlexDiv>
      </FlexBetween>
      <ScrollBrands>
        {
          (search.length < 3 && brands.length === 0) && (
            <NoneAvailable />
          )
        }
        {
          (search.length > 2 && brands.length === 0) && <NoBrandsFound />
        }
        {
          brands && brands.map(brand => (
            <AvailableBrand brand={brand} key={brand.id} refetch={refetch} />
          ))
        }
      </ScrollBrands>
    </BrandsContainer>
  );
};

export default AvailableBrands;

AvailableBrands.propTypes = {
  data: PropTypes.object.isRequired,
  error: PropTypes.bool,
  refetch: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

AvailableBrands.defaultProps = {
  error: null,
};
