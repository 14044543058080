import { useQuery } from 'react-query';
import API from '../axios/API';

const getAllSubmissions = async () => {
  const { data } = await API.get('/submissions/history/');
  return data;
};

const useAllSubmissions = () => useQuery('useAllSubmissions', getAllSubmissions, {
  refetchOnMount: false,
  refetchOnWindowFocus: false,
  refetchOnReconnect: false,
});

export default useAllSubmissions;
