import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

const ACTIONS = {
  INCREASE_INDEX: 'increaseIndex',
  DECREASE_INDEX: 'decreaseIndex',
  INCREASE_ACTIVE_QUESTION: 'increaseActiveQuestion',
  DECREASE_ACTIVE_QUESTION: 'decreaseActiveQuestion',
  TOGGLE_DISCLAIMER: 'toggleDisclaimer',
  SET_INITIAL_QUESTIONS: 'setInitialQuestions',
  SET_SAVED_ANSWER: 'setSavedAnswer',
  EDIT_QUESTION: 'editQuestion',
  SUBMIT_MODULE: 'submitModule',
  RESET_MODULE: 'resetModule',
  SET_VIDEO_WATCH_PERCENT: 'setVideoWatchPercent',
  JUMP_TO_QUESTION: 'jumpToQuestion',
};

const Wrapper = styled.div`
  min-width: 200px;
  max-width: 500px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 3px;
`;

const Bubble = styled.div`
  height: 34px;
  width: 34px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  border: 1px solid ${props => {
    if (props.isActiveQuestion === 'Y') {
      return 'black';
    }
    return props.hasAnswer === 'Y' ? 'rgba(46, 139, 87, 0.7)' : 'lightgray';
  }};
  background-color: ${props => props.hasAnswer === 'Y' ? 'rgba(146, 255, 146, 0.18)' : 'white'}};

  span {
    font-weight: 600;
    color: black;
  }
`;

const ShowQuestionProgress = ({
  state,
  dispatch,
}) => (
  <Wrapper>
    <span style={{ color: 'darkgrey', fontSize: 16, marginRight: 6 }}>
      Questions
    </span>
    {
      state.questions.map((q, i) => (
        <Bubble
          key={q.id}
          isActiveQuestion={state.activeQuestion === i ? 'Y' : 'N'}
          onClick={() => dispatch({ type: ACTIONS.JUMP_TO_QUESTION, payload: { index: i } })}
          hasAnswer={(q.savedAnswer === null || q.savedAnswer.length === 0) ? 'N' : 'Y'}
        >
          <span>{i + 1}</span>
        </Bubble>
      ))
    }
  </Wrapper>
);

export default ShowQuestionProgress;

ShowQuestionProgress.propTypes = {
  state: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
};
