/* eslint-disable */
import React, { 
  useState, 
  useContext, 
  useRef } from 'react';
import styled from 'styled-components/macro';
import LOGO from '../../assets/main-logo.png';
import sidebarRoutes from '../../routes/sidebarRoutes';
import { NavLink } from 'react-router-dom';
import { Slide } from '@mui/material';
import GetOurApp from './GetOurApp';
import { GlobalState } from '../../store/global';

const Wrapper = styled.nav`
  width: 100%;
  height: 100%;
  background-color: #EEEEEE;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

const ImageWrapper = styled.div`
  width: 100%;
  height: 64px;
  padding: 6px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const HeadImage = styled.img`
  padding: 10px;
  object-fit: contain;
  height: 100%;
  margin-left: 18px;
`;

const LinkContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  padding-top: 6px;
  flex-grow: 1;
  
`;

const CustomLink = styled(NavLink)`
  height: ${props => props.smaller === 'yes' ? 32 : 42}px;
  width: 94%;

  padding: 4px 26px;

  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-decoration: none;
  margin: ${props => props.smaller === 'yes' ? 2 : 4}px;
  align-self: center;
  border-radius: 6px;
  font-weight: 300;
  font-family: 'Raleway', sans-serif;

  svg{
    transition: 200ms ease-in;
    color:  slategray;
    height: ${props => props.smaller === 'yes' ? 14 : 16}px;
    width: ${props => props.smaller === 'yes' ? 14 : 16}px;
  }

  &.active {
    background-color: white;
    border: 1px solid gainsboro;
    border-radius: 6px;

    svg{
      color: ${props => props.theme.palette.primary.main};
    }
    
    span{
      color:  black;
      text-decoration: none;
      font-weight: 600;
    }
  }
  
  span {
    font-size: 16px;
    color:  ${props => props.theme.palette.background.bw};
  }
  
  &:hover{
    cursor: pointer;
    background-color: white;
    border: 1px solid gainsboro;

    svg{ 
      color: black;
      transform: scale(1.05);
    }
  }
`;


const IconWrapper = styled.div`
  margin-right: 16px; 
  height: 32px;
  width: 32px; 
  display: flex;
  align-items: center; 
  justify-content: center; 
  border-radius: 8px; 
  border: 1px solid lightgray;
  background: white;
`


const ChildrenContainer = styled.div`
  display: flex;
  width: 100%;
  margin: auto;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  span{
    font-size: 12px;
  }
`;

const ShowRoute = ({
  route
}) => {
  return (
    <CustomLink 
      key={route.name} 
      to={route.url}
    > 
      <IconWrapper>
        <route.icon />
      </IconWrapper>
      <span>
        {route.name}
      </span>
    </CustomLink>
  )
}



const NavDrawer = ({closeDrawer}) => {
  const { themeLight } = useContext(GlobalState)
  const logoRef = useRef(null);

  return (
    <Wrapper>
      <ImageWrapper ref={logoRef}>
        <Slide 
          direction="right" 
          in={themeLight === 'light'} 
          mountOnEnter
          unmountOnExit
          timeout={{
            appear: 300,
            enter: 300,
            exit: 150,
          }}
          container={logoRef.current} 
        >
          <HeadImage src={LOGO} />
        </Slide>
        {/* <Slide 
          direction="left"
          in={themeLight !== 'light'} 
          timeout={{
            appear: 400,
            enter: 400,
            exit: 150,
          }}
          mountOnEnter  
          unmountOnExit
          container={logoRef.current}
        >
          <HeadImage src={LOGO_DARK_MODE} />
        </Slide> */}
      </ImageWrapper>
      <LinkContainer>
        {sidebarRoutes.map(route => (
          <ShowRoute key={route.name} route={route} closeDrawer={closeDrawer} />
        ))}
      </LinkContainer>
      <GetOurApp />
    </Wrapper>
  )
}

export default NavDrawer;
