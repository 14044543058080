import { IconButton } from '@mui/material';
import styled from 'styled-components/macro';
import { X } from 'react-feather';

export const SmallIconButton = styled(IconButton)`
  &:hover {
    background-color: ${props => props.theme.palette.nav.background};
  }

  svg{
    height: 16px;
    width: 16px;
  }
`;

export const AbsoluteIconButton = styled(IconButton)`
  position: absolute; 
  top: 10px;
  right: 10px;

  &:hover {
    background-color: ${props => props.theme.palette.nav.background};
  }

  svg{
    height: 16px;
    width: 16px;
    color: ${props => props.theme.palette.background.bw};
  }
`;

export const ExitModal = styled(X)`
  height: 18px;
  width: 18px;
  position: absolute;
  top: 12px;
  right: 12px;
  cursor: pointer;
`;
