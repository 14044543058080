/* eslint-disable */
/* eslint-disable import/prefer-default-export */
import styled from 'styled-components/macro';

const SPIFFY_BLUE = 'rgb(51, 122, 183)';

export const MaxWidthWrapper = styled.div`
  width: 100%;
  max-width: 1400px;
  margin: auto;
  margin-top: 20px;
  padding: 0px 10px;
`;

const Header = styled.div`
  display: flex; 
  align-items: center;
  justify-content: space-between;
  padding: 4px 6px;
  border-radius: 4px;
  background-color: #eeeeee;
  width: 100%;

  span {
    font-weight: 5400;
    font-size: 14px;
  }

  svg {
    height: 14px;
    width: 14px;
    color: rgb(51, 122, 183);
  }

`

export const HeaderDiv = ({ 
    title, 
    icon, 
    SecondaryComponent = null,
  }) => (
  <Header>
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start'}}>
      <div 
        style={{ 
          height: 30, 
          width: 30, 
          borderRadius: 4, 
          marginRight: 8, 
          display: 'flex', 
          alignItems: 'center', 
          justifyContent: 'center', 
          background: 'white', 
          border: '1px solid lightgray',
      }}
      >
        {icon}
      </div>
      <span>
        {title}
      </span>
    </div>
    {SecondaryComponent ? (
      <SecondaryComponent />
    ) : (
      <div />
    )}
  </Header>
)