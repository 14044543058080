import { useQuery } from 'react-query';
import API from '../axios/API';

const getBrandData = async id => {
  const { data } = await API.get(`following/company-details/?id=${id}`);
  return data;
};

const useBrandOverview = id => useQuery(['brand-overview', id], () => getBrandData(id));

export default useBrandOverview;
