/* eslint-disable consistent-return */
/* eslint-disable no-case-declarations */
/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import {
  Clipboard,
  Book,
  Award,
  Home,
  List,
} from 'react-feather';
import { Button, Paper } from '@mui/material';
import ShowQuestionProgress from './ShowQuestionProgress';
import useWindowSize from '../../../hooks/useWindowSize';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
  margin-top: -16px;

  @media(max-width: 660px) {
    margin-bottom: 0px;
    max-width: 96vw;
  }
`;

const SmallScreenButtonWrapper = styled.div`
  margin-bottom: 6px; 
  width: 100%; 
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @media(max-width: 660px) {
    margin-top: 0px;

  }
`;

const PrimaryActionButton = styled(Button)`
  background-color: ${props => props.isbuttondisabled === 'yes' ? 'darkgrey' : 'mediumseagreen'};
  color: white;
  white-space: nowrap;

  &:hover {
    background-color: seagreen;
  }
`;

const StepperWrapper = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 12px;
  margin-left: auto;
  margin-right: auto;
  margin-top: -8px;
  
  @media(max-width: 660px) {
    display: none;
  }
`;

const HeaderWrapper = styled(Paper)`
  width: 100%;
  min-height: 40px;
  margin-top: 8px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${props => props.theme.palette.background.paper};
  padding: 4px 8px;
  border-radius: 2px;
  box-shadow: ${props => props.theme.palette.boxShadow.secondary};
`;

const Step = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex: .2;
  padding: 8px;
  margin-right: 4px;
  margin-left: 4px;
  border-left: 3px solid ${props => props.active === 'yes' ? props.theme.palette.primary.main : 'transparent'};
  background-color: ${props => {
    if (props.active === 'yes') {
      return props.theme.palette.background.paper;
    }
    return null;
  }};

  span{
    color: ${props => {
    if (props.active === 'yes') {
      return props.theme.palette.background.bw;
    }
    return props.theme.palette.grey[500];
  }};
      
    font-size: 14px;
  }

  svg{
    height: 16px;
    width: 16px;
    color: ${props => {
    if (props.active === 'yes') {
      return props.theme.palette.primary.main;
    }
    return props.theme.palette.background.bw;
  }};
    margin-right: 6px;
  }
`;

const evaluateModule = (questions, scoreToPass) => {
  let questionsCorrect = 0;
  const updatedQuestions = [];
  questions.forEach(question => {
    // Multiple Choice Question
    if (question.type === 'mc') {
      if (question.correct_answer === question.savedAnswer) {
        questionsCorrect += 1;
        return updatedQuestions.push({
          ...question,
          correct: true,
        });
      }
      return updatedQuestions.push({
        ...question,
        correct: false,
      });
    }
    // True False Question
    if (question.type === 'tf') {
      if (question.correct_answer.toLowerCase() === String(question.savedAnswer)) {
        questionsCorrect += 1;
        return updatedQuestions.push({
          ...question,
          correct: true,
        });
      }

      return updatedQuestions.push({
        ...question,
        correct: false,
      });
    }

    // Select All Question
    if (question.type === 'sa') {
      // Arrays are same length + all saved answers are inculded in teh correct answers array
      if (
        (question.correct_answer.length === question.savedAnswer.length)
        && question.savedAnswer.every(ans => question.correct_answer.includes(ans))
      ) {
        questionsCorrect += 1;
        return updatedQuestions.push({
          ...question,
          correct: true,
        });
      }
      return updatedQuestions.push({
        ...question,
        correct: false,
      });
    }
  });

  return {
    passed: questionsCorrect >= scoreToPass,
    updatedQuestions,
    questionsCorrect,
  };
};

const getHeaderText = (index, state, dispatch) => {
  switch (index) {
    case 0:
      return {
        title: <span style={{ color: 'darkgrey', fontWeigth: 300, fontSize: 16 }}>Overview</span>,
        primaryBtn: 'Start Module',
        secondaryBtn: null,
        primaryDisabled: (state.hasDisclaimer && !state.disclaimerChecked),
      };
    case 1:
      return {
        title: <span style={{ color: 'darkgrey', fontWeigth: 300, fontSize: 16 }}>Content</span>,
        secondaryBtn: 'Back to Overview',
        primaryBtn: 'Go To Questions',
        primaryDisabled: false,
      };
    case 2:
      const allQuestionsAnswered = state.questions.every(q => {
        if (q.type === 'sa') {
          return q.savedAnswer && q.savedAnswer.length > 0;
        }
        return q.savedAnswer !== null;
      });

      return {
        title: <ShowQuestionProgress state={state} dispatch={dispatch} />,
        secondaryBtn: 'Back to Content',
        primaryBtn: 'Review Answers',
        primaryDisabled: !allQuestionsAnswered,
      };
    case 3:
      return {
        title: <span style={{ color: 'darkgrey', fontWeigth: 300, fontSize: 16 }}>Review Your Answers</span>,
        secondaryBtn: 'Back to Questions',
        primaryBtn: 'Submit Module',
        primaryDisabled: false,
      };
    case 4:
      return {
        title: <span style={{ color: 'darkgrey', fontWeigth: 300, fontSize: 16 }}>Results</span>,
        secondaryBtn: null,
        primaryBtn: null,
        primaryDisabled: true,
      };
    default:
      return null;
  }
};

const Stepper = ({
  state,
  dispatch,
  ACTIONS,
  scoreToPass,
}) => {
  const { index } = state;

  const { width } = useWindowSize();

  const {
    title,
    primaryBtn,
    primaryDisabled,
    secondaryBtn,
  } = getHeaderText(index, state, dispatch);

  return (
    <Wrapper>
      <StepperWrapper>
        <Step active={index === 0 ? 'yes' : 'no'}>
          <Home />
          <span>
            Overview
          </span>
        </Step>
        <Step active={index === 1 ? 'yes' : 'no'}>
          <Book />
          <span>
            Content
          </span>
        </Step>
        <Step active={index === 2 ? 'yes' : 'no'}>
          <List />
          <span>
            Questions
          </span>
        </Step>
        <Step active={index === 3 ? 'yes' : 'no'}>
          <Clipboard />
          <span>
            Review
          </span>
        </Step>
        <Step active={index === 4 ? 'yes' : 'no'}>
          <Award />
          <span>
            Results
          </span>
        </Step>
      </StepperWrapper>
      {
        width < 601 && (
          <SmallScreenButtonWrapper>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexWrap: 'wrap',
              }}
            >
              {
                secondaryBtn && (
                  <Button
                    color="secondary"
                    variant="text"
                    size="small"
                    sx={{
                      mr: 2,
                      whiteSpace: 'nowrap',
                    }}
                    onClick={() => dispatch({ type: ACTIONS.DECREASE_INDEX })}
                  >
                    {secondaryBtn}
                  </Button>
                )
              }
              {
                  (index !== 4) && (
                    <PrimaryActionButton
                      variant="contained"
                      size="small"
                      isbuttondisabled={primaryDisabled ? 'yes' : 'no'}
                      disabled={primaryDisabled}
                      onClick={() => {
                        if (index === 3) {
                          return dispatch({ type: ACTIONS.SUBMIT_MODULE, payload: evaluateModule(state.questions, scoreToPass) });
                        }
                        return dispatch({ type: ACTIONS.INCREASE_INDEX });
                      }}
                    >
                      {primaryBtn}
                    </PrimaryActionButton>
                  )
                }
            </div>

          </SmallScreenButtonWrapper>
        )
      }
      <HeaderWrapper>
        {title}
        {
          width > 600 ? (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexWrap: 'nowrap',
              }}
            >
              {
                secondaryBtn && (
                  <Button
                    color="secondary"
                    variant="text"
                    size="small"
                    sx={{
                      mr: 2,
                      whiteSpace: 'nowrap',
                    }}
                    onClick={() => dispatch({ type: ACTIONS.DECREASE_INDEX })}
                  >
                    {secondaryBtn}
                  </Button>
                )
              }
              {
                  (index !== 4) && (
                    <PrimaryActionButton
                      variant="contained"
                      size="small"
                      isbuttondisabled={primaryDisabled ? 'yes' : 'no'}
                      disabled={primaryDisabled}
                      onClick={() => {
                        if (index === 3) {
                          return dispatch({ type: ACTIONS.SUBMIT_MODULE, payload: evaluateModule(state.questions, scoreToPass) });
                        }
                        return dispatch({ type: ACTIONS.INCREASE_INDEX });
                      }}
                    >
                      {primaryBtn}
                    </PrimaryActionButton>
                  )
                }
            </div>

          ) : <div />
        }
      </HeaderWrapper>
    </Wrapper>
  );
};

export default Stepper;

Stepper.propTypes = {
  state: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  ACTIONS: PropTypes.object.isRequired,
  scoreToPass: PropTypes.number.isRequired,
};
