/* eslint-disable */
import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { Outlet } from 'react-router-dom';
import {
  Drawer,
  AppBar,
  Toolbar,
  IconButton,
  Box,
  Modal,
} from '@mui/material';
import styled from 'styled-components/macro';
import { ChevronDown, Menu } from 'react-feather';
import NavDrawer from './NavDrawer';
import UserProfile from '../../components/Header/UserProfile';
import SelectMode from '../../components/Header/SelectMode';
import RecommendAppModal from '../../components/Modals/RecommendAppModal';
import NewBadgeModal from '../../components/Modals/NewBadgeModal';
import API from '../../axios/API'
import { GlobalState } from '../../store/global';
import SubmissionReviewModal from '../../components/Modals/SubmissionReviewModal';

const DRAWERWIDTH = 270;

const Root = styled.div`
  width: 100vw;
  min-height: 100vh;
  display: flex;
`;

const CustomAppBar = styled(AppBar)`
  background-color: white;
  box-shadow: none;
`;

const MainBox = styled(Box)`
  background-color: ${props => props.theme.palette.background.main};
`;

const CustomIconButton = styled(IconButton)`
  margin-left: 12ppx;

  svg{
    color: ${props => props.theme.palette.background.bw};
  }
`;

const CustomToolbar = styled(Toolbar)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${props => props.theme.palette.nav.toolbar};
`;

const StayRight = styled.div`
  margin-right: 8px;
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: center;

  &.Avatar {
    margin-right: 8px;
  }
`;


const Dashboard = props => {
  const { window } = props;
  
  const { 
    setShowEditInfoModal, 
    showPushAppstoreModal, 
    setShowPushAppstoreModal,
    newBadgeData,
    setNewBadgeData,
    reviewSubmissionInfo,
    setReviewSubmissionInfo,
  } = useContext(GlobalState)
  const [open, setOpen] = useState(false);

  const getAgeGateInfo = async () => {
    try {
      const { data } = await API.get('/users/age-gating-info/');
      setShowEditInfoModal(data.ask_for_age_gating_info);
    } catch (error) {
      return null;
    }
  }

  useEffect(() => {
    // when dashboard layout renders, check if their age gate info is filled, if not, require it;
    getAgeGateInfo()
  }, []);

  const closeDrawer = () => setOpen(false);

  const container = window !== undefined ? () => window().document.body : undefined;

  const handleDrawerToggle = () => {
    setOpen(p => !p);
  };


  return (
    <Root>
      <CustomAppBar
        position="fixed"
        sx={{
          width: { md: `calc(100% - ${DRAWERWIDTH}px)` },
          ml: { md: `${DRAWERWIDTH}px` },
          zIndex: 100,
        }}
      >
        <CustomToolbar>
          <CustomIconButton
            aria-label="open drawer"
            onClick={handleDrawerToggle}
            edge="start"
            size="large"
            sx={{
              mr: 2,
              display: { md: 'none' },
              ml: 2,
            }}
          >
            <Menu />
          </CustomIconButton>
          <StayRight>
            {/* <SelectMode /> */}
            <UserProfile />
          </StayRight>
        </CustomToolbar>
      </CustomAppBar>
      <Box
        component="nav"
        sx={{ width: { md: DRAWERWIDTH }, flexShrink: { md: 0 } }}
      >
        <Drawer
          container={container}
          variant="temporary"
          open={open}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            zIndex: 1500,
            display: { xs: 'block', md: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: DRAWERWIDTH },
          }}
        >
          <NavDrawer />
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', md: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: DRAWERWIDTH },
          }}
          open
        >
          <NavDrawer closeDrawer={closeDrawer} />
        </Drawer>
      </Box>
      <MainBox
        component="main"
        sx={{
          flexGrow: 1,
          p: 0,
          width: { md: `calc(100% - ${DRAWERWIDTH}px)` },
          marginTop: 16,
          position: 'relative',
        }}
      >
        <Outlet />
      </MainBox>
      <Modal
        open={showPushAppstoreModal}
        onClose={() => setShowPushAppstoreModal(false)}
      >
        <div>
          <RecommendAppModal 
          // checks if using mobile, if so recommends they use app
            setShowPushAppstoreModal={setShowPushAppstoreModal}
          />
        </div>
      </Modal>
      <Modal
        open={Boolean(newBadgeData)}
        onClose={() => setNewBadgeData(null)}
      >
        <div>
          <NewBadgeModal setNewBadgeData={setNewBadgeData} data={newBadgeData} />
        </div>
      </Modal>
      <Modal
        open={Boolean(reviewSubmissionInfo)}
        onClose={() => setReviewSubmissionInfo(null)}
      >
        <div>
          <SubmissionReviewModal 
            reviewSubmissionInfo={reviewSubmissionInfo}
            setReviewSubmissionInfo={setReviewSubmissionInfo}
          />
        </div>
      </Modal>
    </Root>
  );
};

export default Dashboard;

Dashboard.propTypes = {
  window: PropTypes.object,
};

Dashboard.defaultProps = {
  window: undefined,
};
