/* eslint-disable */
import React, { useContext } from 'react';
import { Paper, Typography, Button } from '@mui/material';
import styled from 'styled-components/macro'
import { addLogoString } from '../../../../helpers';
import { useNavigate } from 'react-router-dom';
import { GlobalState } from '../../../../store/global';

const Card = styled(Paper)`
  margin: 4px 0px;
  width: 100%;
  padding: 10px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

`

const ModuleImage = styled.img`
  height: 54px;
  width: 54px;
  border-radius: 4px;
  object-fit: contain;
  border: 1px solid #EEEEEE;
  margin-right: 12px;
`;


const ModuleCard = ({
  mod,
  setFollowAndStartModuleId,
  isCurrentlyFollowing,
}) => {

  const navigate = useNavigate();

  const { setReviewSubmissionInfo,
  } = useContext(GlobalState);

  const handleStartModule = id => {

    if (!isCurrentlyFollowing) {
      return setFollowAndStartModuleId(id)
    }

    return navigate(`/module/${id}`)
  };


  return (
    <Card
      style={{ borderLeft: mod.completed ? '4px solid #50c878' : '4px solid #FAA0A0'}}
    
    >
      <ModuleImage src={addLogoString(mod.image)} alt="no image"/>
      <div style={{ height: 54, flexGrow: 1, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start'}}>
        <Typography 
          style={{ 
            fontSize: 17, 
            fontWeight: 400, 
            letterSpacing: -1, 
            lineHeight: 1.0, 
            flex: .4,
            marginRight: 4,
          }}          
        >
          {mod.name}
        </Typography>
        <Typography variant='body1' style={{ lineHeight: 1.2, fontWeight: 300, flex: .8, maxHeight: 60, textOverflow: 'ellipsis', overflowY: 'hidden'}}>
          {mod.description}
        </Typography>
        <div style={{ width: '100%', display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end', flex: .25}}>
          
          {
            mod.completed ? (
            <Button
            size='small'
            color='inherit'
            variant="contained"
            style={{ color: 'black'}}
            onClick={() => setReviewSubmissionInfo({ id: mod.submission_id, logo: addLogoString(mod.image) })}
          >
            Review
          </Button>
            ) : (
          <Button
            size='small'
            color='primary'
            variant="contained"
            style={{ color: 'white'}}
            onClick={() => handleStartModule(mod.id)}
          >
            Start
          </Button>
            )
          }
        </div>
      </div>
    </Card>
  )
}

export default ModuleCard