/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import {
  Paper,
  Typography,
  Checkbox,
} from '@mui/material';
import {
  Briefcase,
  Hash,
  Target,
  Clock,
  Tv,
  Key,
} from 'react-feather';

const ModulePaper = styled(Paper)`
  background-color: ${props => props.theme.palette.background.paper};
  width: 100%;
  display: flex;
  flex-direction: row;

  @media(max-width: 660px) {
    flex-wrap: wrap;
    width: 360px;
    margin: auto;
    margin-top: 12px;
    margin-bottom: 6px;
  }

  @media(max-width: 400px) {
    width: 90vw;
  }
`;

const ModuleImage = styled.img`
  width: 320px;
  height: 320px;
  object-fit: contain;

  @media (max-width: 660px) {
    width: 360px;
    height: 300px;
  }

  @media (max-width: 400px) {
    width: 220px;
    height: 220px;
    border-widt: 2px;
    margin: auto;
  }
`;

const ModuleRight = styled.div`
  padding: 10px;
  flex-grow: 1;
  height: 320px;
  display: flex;
  min-width: 320px;
  flex-direction: column;
  align-items: flex-start;

  @media (max-width: 660px) {
    min-height: 360px;
    min-width: 100%;
  }
`;

const SmallDisclaimerSpan = styled.span`
  font-size: 12px;
  font-weight: 300;
  color: ${props => props.checked === 'Y' ? 'mediumseagreen' : 'tomato'};
`;

const FlexDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  span{
    color: ${props => props.theme.palette.background.bw};
  }
`;

const HoldDisclaimer = styled(Paper)`
  padding: 2px;
  border-radius: 2px;
  width: 100%;
  border: 1px solid #EEEEEE;
  border-left: 2px solid ${props => props.checked === 'Y' ? 'mediumseagreen' : 'tomato'};
  margin-top: 4px;
  margin-bottom: 6px;
  padding: 10px;
`;

const ModuleInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%; 
  border: 1px solid #EEEEEE;
  border-radius: 4px;
  padding: 2px;
  margin-top: 4px;
  padding-right: 8px;
`;

const IconContainer = styled.div`
  margin-right: 8px;
  height: 28px; 
  width: 28px; 
  border-radius: 4px;
  border: 1px solid #EEEEEE; 
  display: flex;
  align-items: center; 
  justify-content: center;
  background: linear-gradient(#EEEEEE, aliceblue);


  svg{
    color: slategray;
  }
`;

const Overview = ({
  module,
  state,
  dispatch,
  ACTIONS,
}) => {
  const { disclaimerChecked } = state;

  const hasPdf = Boolean(module.media);
  const hasVideo = Boolean(module.video);
  const hasBoth = (hasPdf && hasVideo);

  return (
    <>
      <ModulePaper>
        <ModuleImage src={module.image} />
        <ModuleRight>
          <Typography
            sx={{
              mt: 1,
              fontSize: 22,
              fontWeight: 500,
            }}
          >
            {module.name}
          </Typography>
          <Typography
            align="left"
            sx={{
              fontSize: 11,
              mb: 2,
              color: 'black',
              maxWidth: '98%',
              padding: '2px',
            }}
          >
            {module.description}
          </Typography>
          <div
            style={{
              width: '100%',
              flexGrow: 1,
              overflowY: 'scroll',
              paddingRight: 12,
            }}
          >
            <ModuleInfoWrapper>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                <IconContainer>
                  <Briefcase height={16} width={16} />
                </IconContainer>
                <span style={{ fontWeight: 300 }}>
                  Company
                </span>

              </div>
              <span
                style={{
                  fontSize: 13,
                  fontWeight: 500,
                }}
              >
                {
                  module.marketplace_parent_company_name
                    ? module.marketplace_parent_company_name
                    : module.company_name
                }
              </span>
            </ModuleInfoWrapper>
            <ModuleInfoWrapper>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                <IconContainer>
                  <Hash
                    height={16}
                    width={16}
                  />
                </IconContainer>
                <span style={{ fontWeight: 300 }}>
                  Number Of Questions
                </span>

              </div>
              <span style={{ fontSize: 13, fontWeight: 500 }}>
                {
                  module.total_questions
                }
              </span>
            </ModuleInfoWrapper>
            <ModuleInfoWrapper>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                <IconContainer>
                  <Target
                    height={16}
                    width={16}
                  />
                </IconContainer>
                <span style={{ fontWeight: 300 }}>
                  Score To Pass
                </span>

              </div>
              <span style={{ fontSize: 13, fontWeight: 500 }}>
                {
                  module.pass_score
                }
              </span>
            </ModuleInfoWrapper>
            <ModuleInfoWrapper>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                <IconContainer>
                  <Clock
                    height={16}
                    width={16}
                  />
                </IconContainer>
                <span style={{ fontWeight: 300 }}>
                  Estimated Time
                </span>
              </div>
              <span
                style={{ fontSize: 13, fontWeight: 500 }}
              >
                {module.estimated_time} minutes
              </span>
            </ModuleInfoWrapper>
            <ModuleInfoWrapper>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                <IconContainer>
                  <Key height={16} width={16} />
                </IconContainer>
                <span style={{ fontWeight: 300 }}>
                  Answer Key
                </span>
              </div>
              <span style={{ fontSize: 13, fontWeight: 500 }}>
                {module.display_answers ? 'On' : 'Off'}
              </span>
            </ModuleInfoWrapper>
            <ModuleInfoWrapper>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                <IconContainer>
                  <Tv height={16} width={16} />
                </IconContainer>
                <span style={{ fontWeight: 300 }}>
                  Media Type
                </span>

              </div>
              <span
                style={{ fontSize: 13, fontWeight: 500 }}
              >
                {
                hasBoth
                  ? 'Video + PDF'
                  : hasVideo
                    ? 'Video' : 'PDF'
                }
              </span>
            </ModuleInfoWrapper>

          </div>
        </ModuleRight>
      </ModulePaper>
      {
          module.disclaimer && (
            <HoldDisclaimer
              checked={disclaimerChecked ? 'Y' : 'N'}

            >
              <SmallDisclaimerSpan
                checked={disclaimerChecked ? 'Y' : 'N'}
              >
                Disclaimer
              </SmallDisclaimerSpan>
              <FlexDiv>
                <span style={{ color: 'black', lineHeight: 1.2 }}>
                  {module.disclaimer_text}
                </span>
                <Checkbox
                  checked={disclaimerChecked}
                  size="small"
                  onChange={(e) => dispatch({
                    type: ACTIONS.TOGGLE_DISCLAIMER,
                    payload: e.target.checked,
                  })}
                  sx={{ ml: 2, p: 0 }}
                />
              </FlexDiv>
            </HoldDisclaimer>
          )
        }
    </>
  );
};

export default Overview;

Overview.propTypes = {
  module: PropTypes.object.isRequired,
  state: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  ACTIONS: PropTypes.object.isRequired,
};
