import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { LinearProgress } from '@mui/material';

const Wrapper = styled.div`
  width: 100%; 
  display: flex; 
  flex-direction: column;
  align-items: center;
  justify-content: center;

`;

const CustomLinearProgress = styled(LinearProgress)`
  width: 100%;
  height: 11px;
  border-radius: 6px;
  border: 1px solid #EEEEEE;
  background-color: white;
  
  .MuiLinearProgress-bar1Determinate {
    background-color: rgb(51, 122, 183);
  }
`;

const ShowLevelProgress = ({
  level,
  progressData,
}) => {
  const currentProgress = Object.values(progressData);
  const requirementsArray = !level?.requirements ? [] : Object.values(level?.requirements);
  const progressNeeded = requirementsArray.length === 1
    ? Object.values(requirementsArray[0]) : null;

  if (!progressNeeded) return null;

  const progressPercentage = Math.floor((currentProgress / progressNeeded) * 100);

  return (
    <Wrapper>
      <span style={{ fontSize: 10, color: 'darkgrey' }}>
        {currentProgress} of {progressNeeded} complete ({progressPercentage}%)
      </span>
      <CustomLinearProgress variant="determinate" value={progressPercentage} />
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%',
          padding: '0px 4px',
        }}
      >
        <span>🏳️</span>
        <span>🏁</span>
      </div>
    </Wrapper>
  );
};

export default ShowLevelProgress;

ShowLevelProgress.propTypes = {
  level: PropTypes.object.isRequired,
  progressData: PropTypes.object.isRequired,
};
