import { useQuery } from 'react-query';
import API from '../axios/API';

const getModuleDetail = async id => {
  const { data } = await API.get(`quizzes/details/?version=2&id=${id}`);
  return data;
};

const useModuleInfo = moduleId => useQuery(['moduleInfo', moduleId], () => getModuleDetail(moduleId));

export default useModuleInfo;
