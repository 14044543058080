/* eslint-disable */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import {
  Typography,
  CircularProgress,
  Button,
} from '@mui/material';
import { useQueryClient } from 'react-query';
import API from '../../../axios/API';
import useSnack from '../../../store/snack';
import {
  BrandCard,
  BrandImage,
} from '../../../styles/cards';
import { useNavigate } from 'react-router-dom';

const BrandName = styled.div`
  margin: auto;
  padding: 4px 8px;
  border-radius: 10px;
  width: 100%;
  overflow: hidden;

  p {
    margin-top: -4px;
    font-size: 16px;
    font-weight: 500;
    color: black;

    @media(max-width: 500px) {
      font-size: 12px;
      font-weight: 500;
    }
  }
`;

const Hidden = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
  gap: 4px;
  background-color: rgba(0, 0, 0, .6);
  border-radius: 10px;
  z-index: 19;
  opacity: 0;
  transition: opacity 300ms ease-in;

  &:hover {
    opacity: 1;
  }
`;

const BrandButton = styled(Button)`
  border: 2px solid ${props => props.theme.palette.primary.main};
  border-radius: 4px;
  padding: 4px 14px;
  color: ${props => props.theme.palette.primary.main};
  background-color: rgba(0, 0, 0, .2);
  z-index: 20;
  font-size: 14px;
  width: 140px;


  &:hover{
    background-color:${props => props.theme.palette.primary.main};
    border: 2px solid transparent;
    color: white;
  }
`;

const ViewButton = styled(Button)`
  border: 2px solid white;
  border-radius: 4px;
  padding: 4px 14px;
  color: white;
  background-color: rgba(0, 0, 0, .2);
  z-index: 20;
  font-size: 14px;
  width: 140px;


  &:hover{
    background-color: white;
    border: 2px solid transparent;
    color: black;
  }
`;

const AvailableBrand = ({
  brand,
  refetch,
}) => {
  const queryClient = useQueryClient();
  const [updating, setUpdating] = useState(false);
  const { snack } = useSnack();
  const { setSnack, SNACK_TYPES } = snack;
  const navigate = useNavigate();

  const handleFollow = async () => {
    setUpdating(true);
    try {
      await API.post('following/', {
        company_id: brand.id,
      });
      await refetch();
      await queryClient.invalidateQueries('followingCompanies');
      queryClient.invalidateQueries('availQuizzes', { refetchInactive: true });
      setUpdating(false);
      return setSnack(`Followed brand ${brand.name}`, SNACK_TYPES.SUCCESS);
    } catch (error) {
      setUpdating(false);
      return setSnack(`Failed to follow ${brand.name}`, SNACK_TYPES.ERROR);
    }
  };

  return (
    <BrandCard>
      <Hidden>
        <ViewButton
          variant='text'
          onClick={() => navigate(`/explore/${brand.id}`)}
        >
          View
        </ViewButton>
        <BrandButton
          variant="text"
          onClick={handleFollow}
          disabled={updating}
          startIcon={updating ? <CircularProgress size={15} color="primary" /> : null}
        >
          {updating ? 'Following...' : 'Follow' }
        </BrandButton>
      </Hidden>
      <BrandImage src={brand.logo} />
      <BrandName>
        <Typography noWrap>
          {brand.name}
        </Typography>
      </BrandName>
    </BrandCard>
  );
};

export default AvailableBrand;

AvailableBrand.propTypes = {
  brand: PropTypes.object.isRequired,
  refetch: PropTypes.func.isRequired,
};
