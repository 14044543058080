/* eslint-disable */
/* eslint-disable arrow-body-style */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import {
  Modal,
  Typography,
  Tooltip,
  Button,
  Paper,
  Menu, 
  MenuItem,
} from '@mui/material';
import {
  MapPin,
  Tag,
} from 'react-feather';
import useSnack from '../../../../store/snack';
import ConfirmLeaveTeamModal from './ConfirmLeaveTeamModal';
import ModifyRolesModal from './ModifyRolesModal';
import ModifyTeamLocationModal from './ModifyTeamLocationModal';

const Wrapper = styled(Paper)`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  position: relative;
  border-radius: 8px;
  min-height: 180px;
  background-color: white;
  padding: 10px;
  width: 280px;
  min-width: 280px;
  margin-right: 2px;
`;

const TeamTextSmaller = styled(Typography)`
  font-size: 11px;
  font-weight: 300;
  color: black;
`;

const StyledMenuItem = styled(MenuItem)`
  display: flex;
  align-items: flex-start: 
  justify-content: center;
  color: ${props => props.theme.palette.background.bw};
  
  svg {
    height: 12px;
    width: 12px;
    margin-right: 8px;
    color: ${props => props.theme.palette.grey[500]};
  }
`;



const TeamLogo = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 6px;
  margin-bottom: 6px;

  object-fit: contain;
  background-color: white;
  border: 1px solid #eeeeee;

  @media(max-width: 560px) {
    height: 40px;
    width: 40px;
  }
`;

const HoldButtons = styled.div`
  width 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2px;
  margin-top: 16px;
`

const HoldInfo = styled.div`
  display: flex;
  width: 100%;
  border-radius: 2px;
  min-height: 60px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  padding: 4px;
  position: relative;
  margin-top: 8px;
  background-color: #eeeeee84;

  @media(max-width: 400px) {
    justify-content: center;
  }

`;


const TeamName = styled(Typography)`
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  color: darkgrey;

  @media(max-width: 560px) {
    font-size: 12px;
    font-weight: 500;
  }
`;

const FlexDiv = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  padding-right: 8px;
`;



const ShowTeam = ({
  team,
  refetch,
}) => {
  const { snack } = useSnack();
  const { setSnack, SNACK_TYPES } = snack;
  const [modifyRolesInfo, setModifyRolesInfo] = useState(null);
  const [openModifyLocation, setOpenModifyLocation] = useState(false);
  const [openLeaveTeam, setOpenLeaveTeam] = useState(false)
  const [anchor, setAnchor] = useState(false);

  return (
    <Wrapper>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', padding: '2px', width: '100%'}}>
        <TeamLogo src={team.company__logo} />
        <TeamName noWrap>
          {team.company__name}
        </TeamName>
        <TeamTextSmaller noWrap>
          {team.approved
            ? (
              <Tooltip
                title="You have been approved by an admin at this company"
              >
                <span style={{
                  cursor: 'pointer',
                  backgroundColor: 'mediumseagreen',
                  padding: '2px 6px',
                  color: 'white',
                  fontSize: 9,
                  fontWeight: 700,
                  textTransform: 'uppercase',
                  borderRadius: 4,
                  letterSpacing: 1,
                }}
                >
                  Approved
                </span>
              </Tooltip>
            )
            : (
              <Tooltip
                title="You must be approved by this company before recieving modules"
              >
                <span style={{
                  cursor: 'pointer',
                  backgroundColor: 'darkorange',
                  padding: '2px 6px',
                  // color: '#bb7900',
                  color: 'white',
                  fontSize: 9,
                  fontWeight: 700,
                  textTransform: 'uppercase',
                  borderRadius: 4,
                  letterSpacing: 1,

                }}
                >
                  Awaiting Approval
                </span>
              </Tooltip>
            )}
        </TeamTextSmaller>
      </div>
      <HoldInfo>
        <div style={{ border: '1px solid black', position: 'absolute', top: -6, right: -6, height: 26, width: 26, borderRadius: 13, display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: 'lightgray', border: '1px solid #EEEEEE'}}>
          <MapPin style={{ color: 'dodgerblue', height: 14, width: 14 }} />
        </div>
        <FlexDiv>
          <Typography variant="subtitle2" style={{ paddingRight: 6}}>
            {team.location__name}
          </Typography>
          <TeamTextSmaller noWrap>
            {team.storeAddressLine1}
          </TeamTextSmaller>
          <TeamTextSmaller noWrap>
            {`${team.storeAddressCity}, ${team.storeAddressState}, ${team.storeAddressCountry}`}
          </TeamTextSmaller>
        </FlexDiv>
        
      </HoldInfo>
      <HoldButtons>
        <Menu
          anchorEl={anchor}
          keepMounted
          open={Boolean(anchor)}
          onClose={() => setAnchor(null)}
        >
          <StyledMenuItem
            onClick={() => {
              setAnchor(null)
              return setModifyRolesInfo({
                companyName: team.company__name,
                companyId: team.company__id,
                locationId: team.location__id,
              });
            }}
          >
            <Tag />
            Edit Roles
          </StyledMenuItem>
          <StyledMenuItem
              onClick={() => {
              setAnchor(null)
              setOpenModifyLocation(true)
            }}
          >
            <MapPin />
            Edit Location
          </StyledMenuItem>
        </Menu>
          <Button
            size="small"
            color="primary"
            variant="text"
            fullWidth
            onClick={e => setAnchor(e.currentTarget)}
          >
            Edit
          </Button>
          <Button
            size="small"
            variant="text"
            color="error"
            fullWidth

            onClick={() => setOpenLeaveTeam(true)}
          >
            Leave
          </Button>
      </HoldButtons>
      <Modal
        open={Boolean(modifyRolesInfo)}
        onClose={() => setModifyRolesInfo(null)}
        aria-labelledby="modal-to-add-team"
        aria-describedby="modal-to-add-team"
      >
        <div>
          <ModifyRolesModal
            modifyRolesInfo={modifyRolesInfo}
            team={team}
            refetch={refetch}
            setSnack={setSnack}
            SNACK_TYPES={SNACK_TYPES}
            setModifyRolesInfo={setModifyRolesInfo}
          />
        </div>
      </Modal>
      <Modal
        open={openModifyLocation}
        onClose={() => setOpenModifyLocation(null)}
        aria-labelledby="modal-to-change-team-location"
        aria-describedby="modal-to-change-team-location"
      >
        <div>
          <ModifyTeamLocationModal
            teamId={team.id}
            teamName={team.company__name}
            setSnack={setSnack}
            SNACK_TYPES={SNACK_TYPES}
            setOpenModifyLocation={setOpenModifyLocation}
          />
        </div>
      </Modal>
      <Modal
        open={openLeaveTeam}
        onClose={() => setOpenLeaveTeam(false)}
        aria-labelledby="modal-to-leave-team"
        aria-describedby="modal-to-leave-team"
      >
        <div>
          <ConfirmLeaveTeamModal
            id={team.id}
            name={team.company__name}
            setOpenLeaveTeam={setOpenLeaveTeam}
            refetch={refetch}
          />
        </div>
      </Modal>
    </Wrapper>
  );
};

export default ShowTeam;

ShowTeam.propTypes = {
  team: PropTypes.object.isRequired,
  refetch: PropTypes.func.isRequired,
};
