const overrides = {
  MuiModal: {
    root: {
      outline: 'none',
    },
  },
  MuiButtonBase: {
    defaultProps: {
      disableRipple: true,
    },
  },
  MuiCard: {
    root: {
      borderRadius: '6px',
      boxShadow:
        'rgba(50, 50, 93, 0.025) 0px 2px 5px -1px, rgba(0, 0, 0, 0.05) 0px 1px 3px -1px',
    },
  },
  MuiCardHeader: {
    action: {
      marginTop: '-4px',
      marginRight: '-4px',
    },
  },
  MUIDataTableToolbar: {
    root: {
      borderBottom: '2px solid rgba(0,0,0,0.12)',
    },
    titleText: {
      fontSize: '22px',
      fontWeight: 300,
      marginLeft: '30px',
      textAlign: 'left',
    },
    icon: {
      color: 'black',
    },
  },
  MuiCircularProgress: {
    root: {
      color: 'white',
    },
  },
  MuiBackdrop: {
    root: {
      backgroundColor: 'rgba(0, 0, 0, .75)',
    },
  },
  MuiTabs: {
    indicator: {
      backgroundColor: 'transparent',
    },
  },
  MuiTab: {
    root: {
      '&$selected': {
        backgroundColor: '#FFF',
      },
    },
  },
  MuiPickersDay: {
    day: {
      fontWeight: '300',
    },
  },
  MuiPickersYear: {
    root: {
      height: '64px',
    },
  },
  MuiPickersCalendar: {
    transitionContainer: {
      marginTop: '6px',
    },
  },
  MuiPickersCalendarHeader: {
    iconButton: {
      backgroundColor: 'transparent',
      '& > *': {
        backgroundColor: 'transparent',
      },
    },
    switchHeader: {
      marginTop: '2px',
      marginBottom: '4px',
    },
  },
  MuiPickersClock: {
    container: {
      margin: '32px 0 4px',
    },
  },
  MuiPickersClockNumber: {
    clockNumber: {
      left: 'calc(50% - 16px)',
      width: '32px',
      height: '32px',
    },
  },
  MuiPickerDTHeader: {
    dateHeader: {
      '& h4': {
        fontSize: '2.125rem',
        fontWeight: 400,
      },
    },
    timeHeader: {
      '& h3': {
        fontSize: '3rem',
        fontWeight: 400,
      },
    },
  },
  MuiPickersTimePicker: {
    hourMinuteLabel: {
      '& h2': {
        fontSize: '3.75rem',
        fontWeight: 300,
      },
    },
  },
  MuiChip: {
    root: {
      borderRadius: '6px',
    },
  },
};

export default overrides;
