import { useQuery } from 'react-query';
import API from '../axios/API';

const getBadges = async () => {
  const { data } = await API.get('achievements/');
  return data;
};

const useBadges = () => useQuery('badges', getBadges);

export default useBadges;
