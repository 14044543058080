/* eslint-disable */
import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { Typography, Paper, Modal } from '@mui/material';
import useBadges from '../../../../reactQueryHooks/useBadges';
import BadgeDetails from './BadgeDetails';
import ShowBadge from './ShowBadge';
import { Award } from 'react-feather';
import { HeaderDiv } from '../../../../styles/wrappers';

const Wrapper = styled.div`
  padding: 10px;
  display: flex;
  background: transparent;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  min-height: 180px;
  margin-bottom: 16px;
  border-radius: 0px;
`;
const BadgeContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 18px;
  margin-top: 12px;
  padding: 0px 4px;
`;


const Badges = () => {
  const { data, isLoading } = useBadges();
  const [badgeDetailCode, setBadgeDetailCode] = useState(null);

  if (isLoading) {
    return <span>loading</span>;
  }

  return (
    <Wrapper>
      <HeaderDiv title="Your Badges" icon={<Award height={14} width={14} color="rgb(51, 122, 183)" />} />
      <BadgeContainer>
        {
          data.achievements.map(badge => (
            <ShowBadge key={badge.name} badge={badge} setBadgeDetailCode={setBadgeDetailCode} />
          ))
        }
      </BadgeContainer>
      <Modal
        open={Boolean(badgeDetailCode)}
        onClose={() => setBadgeDetailCode(null)}
      >
        <div>
          <BadgeDetails
            badgeDetailCode={badgeDetailCode}
            setBadgeDetailCode={setBadgeDetailCode}
          />
        </div>
      </Modal>
    </Wrapper>
  );
};

export default Badges;
