import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Button } from '@mui/material';
import { ChevronLeft } from 'react-feather';
import {
  AnswerContainer,
  Option,
  FlexDiv,
  Center,
  Indicator,
  ButtonHolder,
} from '../styles';

const MultipleChoice = ({
  question,
  dispatch,
  ACTIONS,
  state,
}) => {
  const { savedAnswer } = question;

  const isLastQuestion = question.order === state.numQuestions - 1;

  return (
    <>
      <FlexDiv>
        <Typography variant="subtitle2" sx={{ ml: 2 }}>
          Multiple Choice:
        </Typography>
        <Typography variant="subtitleSmall" sx={{ ml: 2 }}>
          Select the most relevant answer
        </Typography>
      </FlexDiv>
      <AnswerContainer>
        <Center>
          {
            Object.entries(question.options).map(option => (
              <Option
                key={option[0]}
                selected={savedAnswer === option[0].toUpperCase() ? 'yes' : 'no'}
                onClick={() => dispatch({
                  type: ACTIONS.SET_SAVED_ANSWER,
                  payload: { questionId: question.id, answer: option[0].toUpperCase() },
                })}
              >
                <span>
                  {`${option[0].toUpperCase()})  ${option[1]}`}
                </span>
                {
                  savedAnswer === option[0].toUpperCase()
                    ? <Indicator><div /></Indicator>
                    : <div />
                }
              </Option>
            ))
          }
        </Center>
      </AnswerContainer>
      <ButtonHolder>
        <Button
          size="small"
          variant="text"
          color="secondary"
          sx={{
            mr: 2,
          }}
          disabled={question.order === 0}
          startIcon={<ChevronLeft style={{ height: 16, width: 16 }} />}
          onClick={() => dispatch({
            type: ACTIONS.DECREASE_ACTIVE_QUESTION,
          })}
        >
          Previous
        </Button>
        <Button
          size="small"
          variant="contained"
          color="primary"
          disabled={isLastQuestion}
          onClick={() => dispatch({
            type: ACTIONS.INCREASE_ACTIVE_QUESTION,
          })}
        >
          Next Question
        </Button>
      </ButtonHolder>
    </>
  );
};

export default MultipleChoice;

MultipleChoice.propTypes = {
  question: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  ACTIONS: PropTypes.object.isRequired,
  state: PropTypes.object.isRequired,
};
