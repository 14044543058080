/* eslint-disable */
import React from 'react';
import { useParams } from 'react-router-dom';
import PageHeader from '../../../../components/Reuseable/PageHeader';
import PageLoader from '../../../../components/Loaders/PageLoader';
import PageError from '../../../../components/Reuseable/PageError';
import useBrandOverview from '../../../../reactQueryHooks/useBrandOverview';
import BrandHero from './BrandHero';
import styled from 'styled-components/macro';
import BrandDescription from './BrandDescription';
import MaxWidthWrapper from '../../../../components/Reuseable/MaxWidthWrapper';
import BrandContent from './BrandContent';
import { motion } from 'framer-motion';

const LeftSide = styled.div`

  flex-grow: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

`;

const RightSide = styled.div`
  height: 100%;
  min-height: 600px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`

const MainContainer = styled.div`
  width: 100%;
  min-height: 600px;
  display: flex; 
  align-items: flex-start; 
  justify-content: flex-start; 
  margin-top: 12px;
  gap: 12px;
  padding-bottom: 60px;
`;


const BrandOverview = () => {

  const params = useParams();
  const { id } = params || {};

  const { 
    data, 
    error, 
    isLoading, 
    refetch, 
  } = useBrandOverview(id)

  if (isLoading) {
    return (
      <>
        <PageHeader text="Brand Overview" isNested />
        <PageLoader />
      </>
    )
  }

  if (error) {
    return (
      <PageError />
    )
  }

  return (
    <>
      <PageHeader text="Brand Overview" isNested />
      <MaxWidthWrapper maxWidth={1200}>
        <motion.div style={{ width: '100%'}}
          initial={{ x: 40}}
          animate={{ x: 0 }}
          transition={{ delay: 0}}
        >

          <BrandHero data={data} refetch={refetch} />
        </motion.div>
        <MainContainer
          as={motion.div}
          initial={{ x: 40}}
          animate={{ x: 0 }}
        >
          <RightSide>
            <BrandContent data={data} />
          </RightSide>
          <LeftSide>
            <BrandDescription description={data.company.description} />
          </LeftSide>
        </MainContainer>
      </MaxWidthWrapper>
    </>
  )
}

export default BrandOverview;
