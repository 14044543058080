/* eslint-disable */
import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { 
  Paper, 
  Typography, 
  Radio, 
  Button, 
  CircularProgress, 
} from '@mui/material';
import useAllTeamLocations from '../../../../reactQueryHooks/ useAllTeamLocations';
import API from '../../../../axios/API';
import { useQueryClient } from 'react-query';



const ModalWrapper = styled(Paper)`
  position: absolute;
  top: 50%;
  left: 50%;
  min-height: 240px;
  transform: translate(-50%, -50%);
  width: 500px;
  padding: 20px;
  display: flex;
  flex-direction: column;
`;

const LocationDiv = styled.div`
  width: 100%;
  padding: 2px 4px; 
  margin-bottom: 4px;
  border: 1px solid #EEEEEE; 
  display: flex; 
  align-items: center; 
  justify-content: space-between;
  border-radius: 2px;

  &:hover {
    background-color: #EEEEEE34;
    cursor: pointer;
  }
`;

const SPIFFY_BLUE = 'rgb(51, 122, 183)';

const ModifyTeamLocationModal = ({ 
  teamId,
  teamName,
  setSnack, 
  SNACK_TYPES,
  setOpenModifyLocation,
 }) => {

  const [selectedLocationId, setSelectedLocationId] = useState(null);
  const [isSaving, setIsSaving] = useState(false)
  const queryClient = useQueryClient();

  const { 
    isLoading,
    data, 
  } = useAllTeamLocations(teamId);

  const handleSaveLocation = async () => {
    setIsSaving(true);
    try {
      await API.put('/teams/location/', {
        tm_id: teamId, 
        location_id: selectedLocationId,
      })
      await queryClient.invalidateQueries('profile');
      await queryClient.invalidateQueries('users')
      setSnack('Location updated', SNACK_TYPES.SUCCESS)
      setIsSaving(false);
      return setOpenModifyLocation(false);
    } catch (error) {
      setIsSaving(false)
      setSnack('Failed to switch location. Please try again', SNACK_TYPES.ERROR)
    }
  }

  return (
    <ModalWrapper>
      <Typography variant="h6" style={{ fontWeight: 500}}>
        Select New Location for {teamName}
      </Typography>
      <Typography
        variant="subtitleSmall"
        sx={{ mb: 4 }}
      >
        Select the location you work at
      </Typography>
      {
        !isLoading && data.length === 0 && (
          <div style={{ 
            width: '100%', 
            height: 130, 
            background: '#EEEEEE', 
            display: 'flex', 
            alignItems: 'center', 
            justifyContent: 'center',
          }}
          >
            <span style={{ fontSize: 14, color: 'darkgrey', fontWeight: 300}}>
              No locations available
            </span>
          </div>
        )
      }
      {
        (!isLoading && data.length > 0) && (
          data.map(location => (
            <LocationDiv
              onClick={() => {
                setSelectedLocationId(p => p === location.id  ? null : location.id )}
            }
            >
              <div style={{ 
                display: 'flex', 
                alignItems: 'flex-start', 
                justifyContent: 'center', 
                width: '100%', 
                flexDirection: 'column',
              }}
              >
                <span 
                  style={{ 
                    color: location.id === selectedLocationId ? SPIFFY_BLUE : 'black',
                    fontWeight: location.id === selectedLocationId ? '500' : '400',
                  }}
                >
                  {location.name}
                </span>
                <span style={{ 
                  fontWeight: '300', 
                  color: location.id === selectedLocationId ? 'black' : 'darkgray', 
                  fontSize: 11, 
                  lineHeight: 1.1,
                }}
                >
                  {location.line1}, {location.city__name}, {location.state__name}
                  
                </span>
              </div>
              <Radio 
                checked={selectedLocationId === location.id}
              />
            </LocationDiv>
          ))
        )
      }
      <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'flex-end', marginTop: 'auto', paddingTop: 4}}>
        <Button
          variant="contained"
          color="primary"
          size="small"
          disabled={!selectedLocationId || isSaving}
          onClick={handleSaveLocation}
          endIcon={isSaving ? <CircularProgress size={16} style={{ color: 'white'}} /> : null}
        >
          {
            isSaving ? 'Saving' : 'Save'
          }
        </Button>
      </div>
    </ModalWrapper>
  )
}

export default ModifyTeamLocationModal;
