import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import ShowPdf from '../../../components/Reuseable/ShowPdf';
import ShowVideo from '../../../components/Reuseable/ShowVideo';
import useWindowSize from '../../../hooks/useWindowSize';

const Center = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  gap: 12px;
`;

const BigSpan = styled.span`
  font-size: 16px;
  display: block;
  margin: 12px 0px 8px 0px;;
  text-align: center;
  font-weight: 700;
`;

const Content = ({
  module,
}) => {
  // Content can be pdf, video, or pdf && video

  const hasPdf = Boolean(module.media);
  const hasVideo = Boolean(module.video);
  const hasBoth = hasPdf && hasVideo;

  const { width } = useWindowSize();

  const responsiveWidthPdf = Math.min(width - 10, 300);
  const responsiveWidthVideo = Math.min(width - 10, 500);
  const responsiveHeightVideo = (responsiveWidthVideo * 0.6);

  if (hasBoth) {
    return (
      <>
        <BigSpan style={{ marginBottom: 12 }}>
          Module has video 🎥 and pdf content 📄
        </BigSpan>
        <Center>
          <ShowVideo
            videoId={module.video}
            width={responsiveWidthVideo}
            height={responsiveHeightVideo}
              // width should be ~ 1.6x the height
          />
          <ShowPdf file={module.media} width={240} />
        </Center>
      </>
    );
  }

  if (hasVideo) {
    return (
      <>
        <BigSpan>
          Module has video content 🎥
        </BigSpan>
        <Center>
          <ShowVideo
            videoId={module.video}
            width={responsiveWidthVideo}
            height={responsiveHeightVideo}
            // width should be ~ 1.6x the height
          />
        </Center>
      </>
    );
  }

  return (
    <>
      <BigSpan>
        Module has pdf content 📄
      </BigSpan>
      <Center>
        <ShowPdf file={module.media} width={responsiveWidthPdf} />
      </Center>
    </>
  );
};

export default Content;

Content.propTypes = {
  module: PropTypes.object.isRequired,
};
