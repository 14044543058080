import { useQuery } from 'react-query';
import API from '../axios/API';

const getCompanies = async () => {
  const { data } = await API.get('following/');
  return data;
};

const useFollowingCompanies = () => useQuery('followingCompanies', getCompanies);

export default useFollowingCompanies;
