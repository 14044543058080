/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import styled from 'styled-components/macro';
import { Typography, Paper, Button } from '@mui/material';
import { addLogoString } from '../../../helpers';
import { CornerDownRight } from 'react-feather';
import { motion } from 'framer-motion';

const CardWrapper = styled.div`
  background-color: ${props => props.theme.palette.background.paper};
  text-align: center;
  width: 300px;
  margin-right: 16px;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  cursor: pointer;
  position: relative;
  height: 350px;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
    0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  &:hover {
    box-shadow: 2px 1px 6px 4px rgba(0, 0, 0, 0.15);
    img {
      filter: grayscale(80%);
    }
  }

  @media (max-width: 500px) {
    width: 200px;
    height: 240px;
  }
`;

const ModTitleContainer = styled.div`
  width: 300px;
  max-width: 300px;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  padding: 2px 4px;
  height: 50px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  background-color: ${props => props.theme.palette.nav.background};

  @media (max-width: 500px) {
    width: 200px;
  }
`;

const ModuleImage = styled(LazyLoadImage)`
  width: auto;
  object-fit: contain;
  height: 300px;
  width: 300px;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  background-color: white;

  @media (max-width: 500px) {
    width: 200px;
    height: 200px;
  }
`;

const Hidden = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  z-index: 19;
  opacity: 0;
  transition: opacity 150ms ease-in;

  &:hover {
    opacity: 1;
  }
`;

const CompanyImage = styled.img`
  object-fit: contain;
  width: 20px;
  height: 20px;
  border-radius: 4px;
  background-color: white;
  border: 1px solid lightgray;
`;

const LearnButton = styled(Button)`
  border: 1px solid ${props => props.theme.palette.primary.main};
  border-radius: 4px;
  padding: 4px 14px;
  color: white;
  z-index: 20;
  font-size: 16px;
  background-color: ${props => props.theme.palette.primary.main};

  &:hover {
    border: 1px solid white;
    background-color: ${props => props.theme.palette.primary.main};
  }
`;

const ModuleCard = ({ mod, navigate }) => {
  if (mod?.hidden) return null;

  const fromMarketplace = Boolean(mod?.marketplace_parent_company_id);
  return (
    <CardWrapper
      as={motion.div}
      initial={{ scale: 0.96 }}
      animate={{ scale: 1 }}
    >
      <Hidden>
        <LearnButton onClick={() => navigate(`/module/${mod.id}`)}>Start Module</LearnButton>
      </Hidden>
      <ModuleImage
        src={addLogoString(mod.image)}
        alt="module"
      />
      <ModTitleContainer>
        <div
          style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 2 }}
        >
          <CompanyImage
            src={addLogoString(
              fromMarketplace ? mod.marketplace_parent_company_logo : mod.company__logo
            )}
          />
          <Typography
            style={{
              marginLeft: 4,
              fontSize: 10,
              textTransform: 'uppercase',
              color: 'darkgrey',
              fontWeight: '400',
            }}
          >
            {fromMarketplace ? mod.marketplace_parent_company_name : mod.company__name}
          </Typography>
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'center',
            marginLeft: 2,
            // transform: 'translate(7px, -2px)',
          }}
        >
          {/* <CornerDownRight style={{ height: 12, width: 12, color: 'darkgrey', marginRight: 6 }} /> */}
          <Typography
            style={{
              fontWeight: '500',
              fontSize: 13,
              lineHeight: 1.4,
              color: 'black',
              maxWidth: 260,
              overflowY: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {mod.name}
          </Typography>
        </div>
      </ModTitleContainer>
    </CardWrapper>
  );
};

export default ModuleCard;

ModuleCard.propTypes = {
  mod: PropTypes.object.isRequired,
  navigate: PropTypes.func.isRequired,
};
