/* eslint-disable */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useQueryClient } from 'react-query';
import {
  CircularProgress,
  Button,
  Typography,
} from '@mui/material';
import styled from 'styled-components/macro';
import {
  BrandCard,
  BrandImage,
} from '../../../styles/cards';
import API from '../../../axios/API';
import useSnack from '../../../store/snack';
import { useNavigate } from 'react-router-dom';

const BrandName = styled.div`
  margin: auto;
  padding: 4px 8px;
  border-radius: 10px;
  width: 100%;
  overflow: hidden;

  p {
    font-size: 16px;
    font-weight: 500;
    color: black;

    @media(max-width: 500px) {
      font-size: 12px;
      font-weight: 500;
    }
  }
`;

const Hidden = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  gap: 4px;
  flex-direction: column;
  width: 100%;
  background-color: rgba(0, 0, 0, .6);
  border-radius: 10px;
  z-index: 19;
  opacity: 0;
  transition: opacity 300ms ease-in;

  &:hover {
    opacity: 1;
  }
`;

const BrandButton = styled(Button)`
  border: 2px solid tomato;
  border-radius: 4px;
  padding: 4px 14px;
  color: tomato;
  background-color: rgba(0, 0, 0, .2);
  z-index: 20;
  font-size: 14px;
  width: 140px;

  &:hover{
    background-color: tomato;
    border: 2px solid transparent;
    color: black;
  }
`;

const ViewButton = styled(Button)`
  border: 2px solid white;
  border-radius: 4px;
  padding: 4px 14px;
  color: white;
  background-color: rgba(0, 0, 0, .2);
  z-index: 20;
  font-size: 14px;
  width: 140px;


  &:hover{
    background-color: white;
    border: 2px solid transparent;
    color: black;
  }
`;

const FollowingBrand = ({
  brand,
  refetch,
}) => {
  const queryClient = useQueryClient();
  const { snack } = useSnack();
  const { setSnack, SNACK_TYPES } = snack;
  const [updating, setUpdating] = useState(false);
  const navigate = useNavigate();
  
  const handleUnfollow = async (id) => {
    setUpdating(true);
    try {
      await API.delete('/following/', {
        data: {
          company_id: id,
        },
      });
      await refetch();
      await queryClient.invalidateQueries('availToFollow');
      queryClient.invalidateQueries('availQuizzes', { refetchInactive: true });
      setUpdating(false);
      setSnack('Removed follower company', SNACK_TYPES.WARNING);
      return 
    } catch (error) {
      setUpdating(false);
      setSnack('Failed to remove follower company', SNACK_TYPES.ERROR);
      return
    }
  };

  return (
    <BrandCard>
      <Hidden>
        <ViewButton
          variant='text'
          onClick={() => navigate(`/explore/${brand.company.id}`)}
        >
          View
        </ViewButton>
        <BrandButton
          variant="text"
          onClick={() => handleUnfollow(brand.company.id)}
          disabled={updating}
          startIcon={updating ? <CircularProgress size={15} color="primary" /> : null}
        >
          {updating ? 'Unfollowing...' : 'Unfollow'}
        </BrandButton>
      </Hidden>
      <BrandImage src={brand.company.logo} />
      <BrandName>
        <Typography noWrap>
          {brand.company.name}
        </Typography>
      </BrandName>
    </BrandCard>
  );
};

export default FollowingBrand;

FollowingBrand.propTypes = {
  brand: PropTypes.object.isRequired,
  refetch: PropTypes.func.isRequired,
};
