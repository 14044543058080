import styled from 'styled-components/macro';
import { CheckCircle } from 'react-feather';

export const SelectedIcon = styled(CheckCircle)`
  color: ${props => props.theme.palette.success.light};
  height: 14px;
  width: 14px;
  margin-right: 8px;
`;

export const ButtonHolder = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: auto;
`;

export const ShowSearchResults = styled.div`
  width: 100%;
  max-height: 320px;
  min-height: 220px;
  margin-top: 12px;
  border-radius: 4px;
  border: 2px solid #EEEEEE67;
  padding 10px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  overflow-y: scroll;
  flex-direction: column;
  margin-bottom: 8px;
  cursor: pointer;
`;

export const Option = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${props => props.theme.palette.secondary.main};
  padding: 12px 8px;
  border-radius: 2px;

  span {
    color: ${props => props.theme.palette.background.bg};
  }

  &:hover{
    background-color: ${props => props.theme.palette.background.contrast};
  }
`;

export const Centered = styled.div`
  width: 100%;
  height: 100%;
  min-height: 170px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
