/* eslint-disable */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { ArrowRightCircle, BarChart, List, Zap } from 'react-feather';
import PageLoader from '../../../components/Loaders/PageLoader';
import PageHeader from '../../../components/Reuseable/PageHeader';
import useUserProfile from '../../../reactQueryHooks/useUserProfile';
import useUserData from '../../../reactQueryHooks/useUserData';
import ProfileHero from './ProfileHero';
import PageError from '../../../components/Reuseable/PageError';
import MaxWidthWrapper from '../../../components/Reuseable/MaxWidthWrapper';
import Teams from './Teams';
import { getNumberWithOrdinal } from '../../../helpers';
import { LEADERBOARD_TYPES } from '../../../constants/common';
import useAllSubmissions from '../../../reactQueryHooks/useAllSubmissions';
import SubmissionsHeatmap from './SubmissionsHeatmap';
import Badges from './Badges';
import { HeaderDiv } from '../../../styles/wrappers';
import { motion } from "framer-motion"

const Wrapper = styled.div`
  padding: 0 10px 10px 10px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  font-size :10px;
  color: dodgerblue;
  custor: pointer;

  &:hover{
    text-decoration: underline;
  }

  &:active {
    color: inherit;
  }

  &:visitied {
    color: inherit;
  }
`;

const StatsWrapper = styled.div`
  padding: 0px;
  display: flex;
  margin-bottom: 8px;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 12px;
`;

const ThirdContainer = styled.div`
  flex: .32;
  padding: 10px;
  border-radius: 0px;
  min-width: 310px;
  background-color: transparent;
  flex-grow: 1;
`;

const LastRowWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  width: 100%;
  align-items: flex-start;
  justify-content: flex-start;
`;

const InnerDiv = ({
  title,
  description,
  stat,
  isRank = false,
  leaderboardState = null,
}) => (
  <div style={{
    height: 60,
    width: '100%',
    display: 'flex',
    marginTop: 8,
    border: '1px solid #EEEEEE',
    borderRadius: 4,
  }}
  >
    <div style={{
      flex: 0.7,
      backgroundColor: 'white',
      marginRight: 0,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'center',
      padding: 10,
      borderRadius: 4,
    }}
    >
      <span style={{ fontSize: 16 }}>{title}</span>
      {
        isRank ? (
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <StyledLink
              to="/leaderboards"
              state={leaderboardState}
            >
              View Leaderboard
            </StyledLink>
            <ArrowRightCircle color="dodgerblue" height={10} width={10} style={{ marginLeft: 4 }} />
          </div>
        ) : (
          <span style={{ fontSize: 11, fontWeight: '300' }}>
            {description}
          </span>
        )
      }
    </div>
    <div style={{
      flex: 0.3,
      backgroundColor: '#EEEEEE',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}
    >
      <span style={{ fontSize: 18, fontWeight: '600', color: 'black'}}>
        {stat}
      </span>
    </div>
  </div>
);

InnerDiv.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  stat: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  isRank: PropTypes.bool,
  leaderboardState: PropTypes.object,
};

InnerDiv.defaultProps = {
  leaderboardState: undefined,
  isRank: undefined,
};



const Profile = () => {
  const { isLoading, error, data } = useUserProfile();
  const {
    isLoading: teamsLoading,
    error: teamsError,
    data: teamsData,
    refetch: teamsRefetch,
  } = useUserData();

  const {
    data: submissionsData,
    isLoading: loadingSubmissions,
  } = useAllSubmissions();

  const location = useLocation();

  const [addTeamModalOpen, setAddTeamModalOpen] = useState(false);

  useEffect(() => {
    if (!location.state) return undefined;

    if (location.state && location.state.openAddTeamModal) {
      return setAddTeamModalOpen(true);
    }

  }, []);

  if (isLoading || teamsLoading || loadingSubmissions) {
    return (
      <>
        <PageHeader text="Profile" />
        <PageLoader />
      </>
    );
  }

  if (error || teamsError) return <PageError />;

  const { ranks } = data;
  const hasNoTeams = Object.values(ranks).length === 0;


  return (
    <>
      <PageHeader text="Profile" />
      <MaxWidthWrapper>
        <Wrapper>
          <motion.div s
            style={{ width: '100%'}}
            initial={{ opacity: 0, x: 20 }}
            animate={{ opacity: 1, x: 0}}
          >
            <ProfileHero
              data={teamsData}
              refetch={teamsRefetch}
              dateJoined={data.date_joined}
              spiffyScore={data.spiffy_score}
            />

          </motion.div>
          <StatsWrapper
            as={motion.div}
            initial={{ opacity: 0, x: 20 }}
            animate={{ opacity: 1, x: 0}}
            transition={{ delay: 0.1 }}
          >
            <ThirdContainer>
              <HeaderDiv title="Stats" icon={<BarChart />} />
              <InnerDiv
                title="Completions"
                stat={data.completions}
                description="Successfully passed Modules"
              />
              <InnerDiv
                title="Submissions"
                stat={data.submissions}
                description="Number Of Modules Submitted"
              />
              <InnerDiv
                title="Pass Rate"
                stat={data.pass_rate ? `${Math.floor(data.pass_rate)}%` : `${data.pass_rate}%`}
                description="(Completions / Submissions) * 100"
              />
            </ThirdContainer>
            <ThirdContainer>
              <HeaderDiv title="Ranks" icon={<List />}/>
              {
                hasNoTeams ? (
                  [1, 2, 3].map(val => (
                    <InnerDiv
                      title="No Rank"
                      stat="None"
                      description="Must be on team to have rank"
                    />
                  ))
                ) : (
                  <>
                    <InnerDiv
                      title="Location Rank"
                      stat={getNumberWithOrdinal(ranks?.location_leaderboard?.rank)}
                      description="Successfully passed Modules"
                      isRank
                      leaderboardState={{
                        type: LEADERBOARD_TYPES.LOCATION,
                        teamId: data.tm_id_used_for_ranks,
                      }}

                    />
                    <InnerDiv
                      title="Company Rank"
                      stat={getNumberWithOrdinal(ranks?.company_leaderboard?.rank)}
                      description="Number Of Modules Submitted"
                      isRank
                      leaderboardState={{
                        type: LEADERBOARD_TYPES.COMPANY,
                        teamId: data.tm_id_used_for_ranks,
                      }}
                    />
                    <InnerDiv
                      title="Industry Rank"
                      stat={getNumberWithOrdinal(ranks?.vertical_leaderboard?.rank)}
                      description="(Completions / Submissions) * 100"
                      isRank
                      leaderboardState={{
                        type: LEADERBOARD_TYPES.INDUSTRY,
                        teamId: data.tm_id_used_for_ranks,
                      }}
                    />
                  </>
                )
              }
            </ThirdContainer>
            <ThirdContainer>
              <HeaderDiv title="Streaks" icon={<Zap />}/>
              <InnerDiv
                title="Completion Streak"
                stat={data.completions_streak}
                description="Successfully passed modules in a row"
              />
              <InnerDiv
                title="Consecutive Days"
                stat={data.daily_streak}
                description="with at least 1 completion"
              />
              <InnerDiv
                title="Consecutive Weeks"
                stat={data.weekly_streak}
                description="with at least 1 completion"
              />
            </ThirdContainer>
          </StatsWrapper>
          <motion.div 
            style={{ width: '100%' }}
            as={motion.div}
            initial={{ opacity: 0, x: 20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ delay: 0.2 }}
          >

            <Badges />
          </motion.div>
           <LastRowWrapper
            as={motion.div}
            initial={{ opacity: 0, x: 20 }}
            animate={{ opacity: 1, x: 0}}
            transition={{ delay: 0.3 }}
           >
            <Teams
              teams={teamsData.teams}
              refetch={teamsRefetch}
              addTeamModalOpen={addTeamModalOpen}
              setAddTeamModalOpen={setAddTeamModalOpen}
            />
            <SubmissionsHeatmap submissions={submissionsData} /> 
          </LastRowWrapper> 
        </Wrapper>
      </MaxWidthWrapper>
    </>
  );
};

export default Profile;
