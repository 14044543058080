/* eslint-disable */
/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { Check } from 'react-feather';
import ShowBadgeProgress from './ShowBadgeProgress';
import { COMPLETIONS_URL } from '../../../../constants/secret';

const SPIFFY_BLUE = 'rgb(51, 122, 183)';

const SingleBadgeContainer = styled.div`
  height: 120px;
  width: 120px;
  border: 1px solid #EEEEEE;
  display: flex;
  background: white;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  border-radius: 18px;
  box-shadow: 0px 0px 4px 2px #EEEEEE;
  position: relative;

  @media (max-width: 460px) {
    height: 120px;
    width: 120px;

    img {
      height: 70px;
      width: 70px;
    }
  }

  &:hover {
    cursor: pointer;
    box-shadow: 0px 0px 5px 4px #EEEEEE;

    img {
      transition: transform 140ms ease-in;
      transform: scale(1.06);
    }
  }
`;

const BadgeName = styled.span`
  font-weight: 500; 
  font-size: 10px; 
  margin-top: 4px;
  text-align: center;

  @media (max-width: 460px) {
    font-size: 10px;
  }
`;

const BadgeImage = styled.img`
  height: 70px;
  width: 70px;
  border-radius: 35px;
  filter: grayscale(${props => props.achieved === 'y' ? 0 : 100}%);
`;

const ShowBadgeLevel = styled.div`
  height: 18px;
  width: 18px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 26px;
  right: 26px;
  z-index: 1000;

  span {
    color: white;
    font-size: 11px;
    font-weight: 700;
  }

  @media (max-width: 460px) {
    top: 30px;
    right: 30px;
    height: 16px;
    width: 16px;
    
    span {
      font-size: 10px;
    }
    
  }
`;

const ShowBadge = ({
  badge,
  setBadgeDetailCode,
}) => {
  const isBadgeFullyCompleted = (badge.achieved && (badge.level === badge.max_level))

  return (
    <SingleBadgeContainer
      onClick={() => setBadgeDetailCode(badge.code)}
    >
      <BadgeName>
        {badge.name}
      </BadgeName>
      <BadgeImage 
        src={`${COMPLETIONS_URL}${badge.image}`} 
        achieved={badge.achieved ? 'y' : 'n'}
      />
      {
        badge.achieved && (
        <ShowBadgeLevel
          style={{ backgroundColor: isBadgeFullyCompleted ? 'lightseagreen' : SPIFFY_BLUE }}
        >
          {
            isBadgeFullyCompleted
              ? <Check height={16} width={16} color="white" />
              : <span>{badge.level}</span>
          }
        </ShowBadgeLevel>
        )
      }
      {
        badge.achieved && (
          <ShowBadgeProgress 
            currentLevel={badge.level} 
            maxLevel={badge.max_level} 
            isBadgeFullyCompleted={isBadgeFullyCompleted} 
          />
        )
      }
    </SingleBadgeContainer>
  )
}


export default ShowBadge;
 
ShowBadge.propTypes = {
  badge: PropTypes.object.isRequired,
  setBadgeDetailCode: PropTypes.func.isRequired,
};
