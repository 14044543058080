/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Button, Checkbox } from '@mui/material';
import { ChevronLeft } from 'react-feather';
import styled from 'styled-components/macro';
import {
  AnswerContainer,
  Option,
  FlexDiv,
  Center,
  ButtonHolder,
} from '../styles';

const Indicator = styled.div`
  height: 13px;
  width: 13px; 
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px; 
  background-color: rgba(51,122,183, .2);
  border: 1px solid rgba(51,122,183, .4);
  
  div {
    height: 4px;
    width: 4px;
    margin: 0px;

    border-radius: 50%;
    background-color: rgb(51, 122, 183);
  }
`;

const StyledCheckbox = styled(Checkbox)`
  svg {
    height: 15px;
    width: 15px;
  }
`;

const SelectAll = ({
  question,
  dispatch,
  ACTIONS,
  state,
}) => {
  const { savedAnswer } = question;

  const isLastQuestion = question.order === state.numQuestions - 1;

  const handleOptionClick = letter => {
    if (savedAnswer && savedAnswer.includes(letter)) {
      const aa = savedAnswer.filter(p => p !== letter);

      return dispatch({
        type: ACTIONS.SET_SAVED_ANSWER,
        payload: { questionId: question.id, answer: aa },
      });
    }

    const bb = savedAnswer ? [...savedAnswer, letter] : [letter];
    return dispatch({
      type: ACTIONS.SET_SAVED_ANSWER,
      payload: { questionId: question.id, answer: bb },
    });
  };

  return (
    <>
      <FlexDiv>
        <Typography variant="subtitle2" sx={{ ml: 2 }}>
          Select All:
        </Typography>
        <Typography variant="subtitleSmall" sx={{ ml: 2 }}>
          Select all answers that apply.
        </Typography>
      </FlexDiv>
      <AnswerContainer>
        <Center>
          {
            Object.entries(question.options).map(option => (
              <Option
                key={option[0]}
                onClick={() => handleOptionClick(option[0].toUpperCase())}
                selected={savedAnswer && savedAnswer.includes(option[0].toUpperCase()) ? 'yes' : 'no'}
              >
                <span>
                  {`${option[0].toUpperCase()})  ${option[1]}`}
                </span>
                {
                  (savedAnswer && savedAnswer.includes(option[0].toUpperCase()))
                    // ? <CheckImg src={CHECK} />
                    ? <Indicator><div /></Indicator>
                    : (
                      <StyledCheckbox
                        checked={false}
                        size="small"
                        disableRipple
                        style={{ padding: 0, color: 'darkgrey' }}
                      />
                    )
                }
              </Option>
            ))
          }
        </Center>
      </AnswerContainer>
      <ButtonHolder>
        <Button
          size="small"
          variant="text"
          color="secondary"
          sx={{
            mr: 2,
          }}
          disabled={question.order === 0}
          startIcon={<ChevronLeft style={{ height: 16, width: 16 }} />}
          onClick={() => dispatch({
            type: ACTIONS.DECREASE_ACTIVE_QUESTION,
          })}
        >
          Previous
        </Button>
        <Button
          size="small"
          variant="contained"
          color="primary"
          disabled={isLastQuestion}
          onClick={() => dispatch({
            type: ACTIONS.INCREASE_ACTIVE_QUESTION,
          })}
        >
          Next Question
        </Button>
      </ButtonHolder>
    </>
  );
};

export default SelectAll;

SelectAll.propTypes = {
  question: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  ACTIONS: PropTypes.object.isRequired,
  state: PropTypes.object.isRequired,
};
