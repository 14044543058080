/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { Divider, Paper } from '@mui/material';
import styled from 'styled-components/macro';
import { getTimezoneName } from '../../../../../helpers';
import useBadgeDetails from '../../../../../reactQueryHooks/useBadgeDetails';
import ShowLevel from './ShowLevel';
import { COMPLETIONS_URL } from '../../../../../constants/secret';

const Wrapper = styled(Paper)`
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  min-height: 240px;
  max-height: 90vh;
  transform: translate(-50%, -50%);
  width: 520px;
  display: flex;
  flex-direction: column;
  border: 2px solid black;
`;

const BadgeImage = styled.img`
  height: 120px;
  width: 120px;
  border-radius: 60px;
  background-color: white;
  filter: grayscale(${props => props.achieved === 'y' ? 0 : 100}%);

`;

const AbsoluteLevelDisplay = styled.div`
  position: absolute;
  bottom: -16px;
  left: 50%;
  transform: translateX(-50%);
  padding: 2px 12px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  border: 1px solid #EEEEEE;
  min-height: 24px;
  min-width: 42px;
`;

const AllCompleteDiv = styled.div`
  position: absolute;
  bottom: -12px;
  left: 50%;
  transform: translateX(-50%);
  padding: 2px 12px;
  background-color: lightseagreen;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  border: 1px solid #EEEEEE;
  min-height: 24px;
  min-width: 42px;

  span {
    color: white;
    font-weight: 500;
    white-space: no-wrap;
  }
`;

const BottomHalfDiv = styled.div`
  padding: 10px;
  width: 100%;
  min-height: 200px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  background-color: #EEEEEE;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;

  overflow-y: scroll;
`;

const TopHalf = styled.div`
  padding: 20px; 
  display: flex;
  flex-direction: column; 
  align-items: center;
  justify-content: center;
`;

const BadgeDetails = ({
  badgeDetailCode,
}) => {
  const timezone = getTimezoneName();

  const { isLoading, data } = useBadgeDetails(badgeDetailCode, timezone);
  
  if (isLoading) return null;

  const imgSrc = `${COMPLETIONS_URL}${data.achievement_levels[0].image}`;

  const firstLevelNotAchieved = data.achievement_levels.find(level => !level.achieved);

  const levelInProgress = firstLevelNotAchieved ? firstLevelNotAchieved.level : null;

  const allLevelsComplete = data.achievement_levels.every(level => level.achieved);

  const noLevelsAchieved = !data.achievement_levels[0].achieved

  return (
    <Wrapper>
      <TopHalf>
        <div
          style={{
            padding: 10,
            borderRadius: 14,
            boxShadow: '1px 1px 4px 3px #EEEEEE',
            border: '1px solid #EEEEEE',
            backgroundColor: 'white',
            marginBottom: 12,
            position: 'relative',
          }}
        >
          <BadgeImage 
            src={imgSrc} 
            achieved={!noLevelsAchieved ? 'y' : 'n' }
          />
          {
            !noLevelsAchieved && (
              allLevelsComplete ? (
                <AllCompleteDiv>
                  <span>Completed</span>
                </AllCompleteDiv>
              ) : (
                <AbsoluteLevelDisplay>
                  <span style={{ fontSize: 14 }}>🏆</span>
                  <span
                    style={{
                      fontWeight: 800,
                      color: 'mediumseagreen',
                      marginLeft: 2,
                      fontSize: 14,
                    }}
                  >
                    {levelInProgress - 1}
                  </span>
                </AbsoluteLevelDisplay>
              )
            )
          }
        </div>
        <span
          style={{
            fontSize: 20,
            color: 'black',
            fontWeight: 600,
            marginBottom: 2,
            marginTop: 8,
          }}
        >
          {data.achievement.name}
        </span>
        <span style={{ fontSize: 12, color: 'darkgrey', textAlign: 'center' }}>
          {data.achievement.descriptions.english}
        </span>

      </TopHalf>
      <Divider style={{ width: '100%', marginTop: 2 }} />
      <BottomHalfDiv>
        {
          data.achievement_levels.map(level => (
            <ShowLevel
              level={level}
              key={level.level}
              isInProgress={levelInProgress === level.level}
              progressData={data.progress_data}
              allLevelsComplete={allLevelsComplete}
            />
          ))
        }
      </BottomHalfDiv>
    </Wrapper>
  );
};

export default BadgeDetails;

BadgeDetails.propTypes = {
  badgeDetailCode: PropTypes.string,
};

BadgeDetails.defaultProps = {
  badgeDetailCode: PropTypes.null,
};
