import { useQuery } from 'react-query';
import API from '../axios/API';

const getCompanyRoles = async companyId => {
  const { data } = await API.get(`companies/roles/?company_id=${companyId}`);
  return data;
};

const useCompanyRoles = (companyId) => useQuery(['companyRoles', companyId], () => getCompanyRoles(companyId));

export default useCompanyRoles;
