import React from 'react';
import styled from 'styled-components/macro';
import Empty from '../../assets/svg/empty.svg';
import PublicFormLayout from '../../layouts/PublicFormLayout';

const Wrapper = styled.div`
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const EmptyImg = styled.img`
  height: 30vh;
  widht: auto;
`;

const FourOhFor = styled.p`
  font-size: 22px;
  font-weight: 700;
  backgrund-color: white;
`;

const NoMatch = () => (
  <PublicFormLayout>
    <Wrapper>
      <FourOhFor>404 Page Not Found</FourOhFor>
      <EmptyImg
        src={Empty}
        height={200}
        width={200}
        alt="404 page"
      />
    </Wrapper>
  </PublicFormLayout>
);

export default NoMatch;
