import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '@mui/material';
import { HelpCircle } from 'react-feather';
import styled from 'styled-components/macro';

const StyledCircle = styled(HelpCircle)`
  color: dodgerblue;
`;

const ExtraInfo = ({
  children,
  isDarkBg = false,
}) => (
  <Tooltip
    arrow
    PopperProps={{
      sx: {
        '& .MuiTooltip-tooltip': {
          backgroundColor: '#f5f5f5',
          border: '1px solid #bdbdbd',
        },
      },
    }}
    title={children}
  >
    <StyledCircle height={16} width={16} color={isDarkBg ? 'lightgray' : 'dodgerblue'} style={{ cursor: 'pointer' }} />
  </Tooltip>
);

export default ExtraInfo;

ExtraInfo.propTypes = {
  children: PropTypes.node.isRequired,
  isDarkBg: PropTypes.bool,
};

ExtraInfo.defaultProps = {
  isDarkBg: false,
};
