import { useQuery } from 'react-query';
import API from '../axios/API';

const getLast10Submissions = async () => {
  const { data } = await API.get('/submissions/last_submissions/?submissions_number=10');
  return data;
};

const useLast10Submissions = () => useQuery('last10Submissions', getLast10Submissions, {
  refetchOnMount: false,
  refetchOnWindowFocus: false,
  refetchOnReconnect: false,
});

export default useLast10Submissions;
