/* eslint-disable */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-prototype-builtins */
import React from 'react';
import PropTypes from 'prop-types';
import { Paper, Tooltip, Typography } from '@mui/material';
import styled from 'styled-components/macro';
import HeatMap from '@uiw/react-heat-map';
import { startOfDay, subMonths } from 'date-fns';
import { HeaderDiv } from '../../../styles/wrappers';
import { TrendingUp } from 'react-feather';

const Wrapper = styled.div`
  padding: 10px;
  border-radius: 0px;
  height: 268px;
  flex-grow: 1;

  @media(max-width: 580px) {
    display: none;
  }
`;


const TODAY = new Date();

const SIX_MONTHS_AGO = subMonths(TODAY, 6);

const formatSubmissions = submissions => {
  const ret = {};

  submissions.forEach(sub => {
    const submissionDate = startOfDay(new Date(sub.submissionDate));
    if (ret.hasOwnProperty(submissionDate)) {
      ret[submissionDate].count += 1;
    } else {
      ret[submissionDate] = {
        date: submissionDate,
        count: 1,
      };
    }
  });

  return Object.values(ret);
  // s
};

const SubmissionsHeatmap = ({
  submissions,
}) => {
  
  if (!submissions) return null;

  const some = formatSubmissions(submissions);

  return (
    <Wrapper>
      <HeaderDiv title="Engagement Last 6 Months" icon={<TrendingUp />}/>
      <div
        style={{
          width: '100%',
          height: '90%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
        }}
      >
        <HeatMap
          style={{ color: 'darkgrey', width: '100%', height: 200 }}
          rectSize={16}
          value={some}
          startDate={SIX_MONTHS_AGO}
          endDate={TODAY}
          rectProps={{
            rx: 3,
          }}
          panelColors={{
            0: '#EEEEEE',
            4: 'rgba(80, 200, 120, .1)',
            8: 'rgba(80, 200, 120, .4)',
            12: 'rgba(80, 200, 120, .6)',
            32: 'rgba(80, 200, 120, .8)',
          }}
          legendRender={props => <rect {...props} y={props.y} rx={3} />}
          rectRender={(props, data) => {
            if (!data?.count) return null;
            return (
              <Tooltip
                key={data.index}
                title={`${data.count} ${data.count > 1 ? 'Submissions' : 'Submission'} on ${new Date(data.date).toDateString()}`}
              >
                <rect {...props} />
              </Tooltip>
            );
          }}
        />
      </div>
    </Wrapper>
  );
};

export default SubmissionsHeatmap;

SubmissionsHeatmap.propTypes = {
  submissions: PropTypes.array.isRequired,
};
