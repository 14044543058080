/* eslint-disable */
/* eslint-disable react/no-array-index-key */
import React, { useState, useContext } from 'react';
import styled from 'styled-components/macro';
import { Typography, Modal, IconButton } from '@mui/material';
import { Link } from 'react-router-dom';
import { GlobalState } from '../../../store/global';
import ScrollableRow from './ScrollableRow';
import PageHeader from '../../../components/Reuseable/PageHeader';
import StreakContainer from '../../../components/Reuseable/StreakContainer';
import useAllModules from '../../../reactQueryHooks/useAllModules';
import PageError from '../../../components/Reuseable/PageError';
import PageLoader from '../../../components/Loaders/PageLoader';
import NewRegisterModal from '../../../components/Modals/NewRegisterModal';
import { handleModuleResponseV3 } from '../../../helpers';
import { ROW_TYPES } from '../../../constants/common';
import { ChevronDown } from 'react-feather';
import useUserData from '../../../reactQueryHooks/useUserData';
import ShowTeamsAwaitingApproval from '../../../components/ShowTeamsAwaitingApproval';

const MODULE_TYPES = {
  ALL: 'all',
  ASSIGNED: 'assigned',
  EXTRA_CREDIT: 'extraCredit',
};

const Centered = styled.div`
  height: 60vh;
  min-height: 300px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const NoneAvailDiv = styled.div`
  padding: 80px;
  display: flex;
  align-items: center: 
  justify-content: center;
  border: 2px solid ${props => props.theme.palette.background.contrast};
  border-radius: 4px;
  background-color: ${props => props.theme.palette.background.paper};
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  font-weight: 700;
  color: ${props => props.theme.palette.primary.main};

  &:hover {
    text-decoration: underline;
  }

  &:active {
    color: inherit;
  }

  &:visitied {
    color: inherit;
  }
`;

const Wrapper = styled.div`
  padding: 20px 20px 60px 20px;
  max-width: 100vw;
  position: relative;
`;

const FlexDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;

  @media (max-width: 580px) {
    display: none;
  }
`;

const CustomChip = styled.div`
  min-width: 100px;
  padding: 4px 8px;
  border-radius: 4px;
  background-color: ${props =>
    props.active === 'yes'
      ? props.theme.palette.background.paper
      : props.theme.palette.background.contrast};
  display: flex;
  align-items: center;
  border: 1px solid ${props => (props.active === 'yes' ? 'lightgray' : 'transparent')};
  justify-content: center;
  margin-right: 10px;
  font-size: 14px;
  cursor: pointer;
  margin-left: 2px;

  p {
    font-size: 14px;
    font-weight: ${props => (props.active === 'yes' ? 500 : 400)};
    color: ${props => (props.active === 'yes' ? 'black' : 'slategray')};
    margin-right: 4px;
  }

  span {
    font-size: ${props => (props.active === 'yes' ? 12 : 12)}px;
    font-weight: ${props => (props.active === 'yes' ? 500 : 300)};
  }
`;

const ASSIGNED_ROW_TYPES = [
  ROW_TYPES.ASSIGNED_CUSTOM_MODULE,
  ROW_TYPES.ASSIGNED_MP_MODULE,
  ROW_TYPES.ASSIGNED_STREAM,
  ROW_TYPES.ASSIGNED_FOLLOWER_STREAM,
];

const getFiltered = (feedRows, showModules) => {
  if (showModules === MODULE_TYPES.ALL) return feedRows;

  if (showModules === MODULE_TYPES.ASSIGNED) {
    return feedRows.filter(row => ASSIGNED_ROW_TYPES.includes(row.rowType));
  }
  if (showModules === MODULE_TYPES.EXTRA_CREDIT) {
    return feedRows.filter(row => !ASSIGNED_ROW_TYPES.includes(row.rowType));
  }

  return [];
};

const getNoModuleText = showModules => {
  if (showModules === MODULE_TYPES.ALL) {
    return (
      <Typography variant="h6">Congrats, you are all caught up on your training! 🎉🎉</Typography>
    );
  }
  if (showModules === MODULE_TYPES.EXTRA_CREDIT) {
    return (
      <Typography variant="h6">
        No Extra credit modules available. <StyledLink to="/explore">Explore brands</StyledLink>
      </Typography>
    );
  }
  if (showModules === MODULE_TYPES.ASSIGNED) {
    return (
      <Typography variant="h6">
        No Assigned Modules Available.{' '}
        <StyledLink
          to="/profile"
          state={{ openAddTeamModal: true }}
        >
          Add Teams
        </StyledLink>
      </Typography>
    );
  }
  return null;
};

const getNumOfModuleTypes = feedRows => {
  let numAssigned = 0;
  let numExtraCredit = 0;

  feedRows.forEach(row => {
    if (ASSIGNED_ROW_TYPES.includes(row.rowType)) {
      numAssigned += row.quizzes.filter(r => !r.hidden).length;
    } else {
      numExtraCredit += row.quizzes.filter(r => !r.hidden).length;
    }
  });

  return {
    numAssigned,
    numExtraCredit,
  };
};

const Learn = () => {
  const { showRegisterModal, setShowRegisterModal } = useContext(GlobalState);
  const { data, error, isLoading } = useAllModules();
  const [showModules, setShowModules] = useState(MODULE_TYPES.ALL);

  if (isLoading) {
    return (
      <>
        <PageHeader text="Learn Feed" />
        <PageLoader />
      </>
    );
  }

  if (error) {
    return <PageError />;
  }

  const { feedRows } = handleModuleResponseV3(data);

  const { numAssigned, numExtraCredit } = getNumOfModuleTypes(feedRows);

  const feedRowsFiltered = getFiltered(feedRows, showModules);

  return (
    <>
      <ShowTeamsAwaitingApproval />
      <PageHeader
        text="Learn Feed"
     
      >
        <FlexDiv>
          <CustomChip
            active={showModules === MODULE_TYPES.ALL ? 'yes' : 'no'}
            onClick={() => setShowModules(MODULE_TYPES.ALL)}
          >
            <p>All Modules</p>
            <span>({numAssigned + numExtraCredit})</span>
          </CustomChip>
          <CustomChip
            active={showModules === MODULE_TYPES.ASSIGNED ? 'yes' : 'no'}
            onClick={() => setShowModules(MODULE_TYPES.ASSIGNED)}
          >
            <p>Assigned</p>
            <span>({numAssigned})</span>
          </CustomChip>
          <CustomChip
            active={showModules === MODULE_TYPES.EXTRA_CREDIT ? 'yes' : 'no'}
            onClick={() => setShowModules(MODULE_TYPES.EXTRA_CREDIT)}
          >
            <p>Extra Credit</p>
            <span>({numExtraCredit})</span>
          </CustomChip>
        </FlexDiv>
      </PageHeader>
      <Wrapper>
        {feedRowsFiltered.length > 0 ? (
          feedRowsFiltered.map((list, i) => (
            <ScrollableRow
              list={list}
              key={i}
            />
          ))
        ) : (
          <Centered>
            <NoneAvailDiv>{getNoModuleText(showModules)}</NoneAvailDiv>
          </Centered>
        )}
        <StreakContainer />
      </Wrapper>
      <Modal
        open={showRegisterModal}
        onClose={() => setShowRegisterModal(false)}
        aria-labelledby="modal-new-register-welcome"
        aria-describedby="modal-to-welcome-new-register"
      >
        <div>
          <NewRegisterModal setShowRegisterModal={setShowRegisterModal} />
        </div>
      </Modal>
    </>
  );
};

export default Learn;
