/* eslint-disable */
import React, { useState } from 'react';
import { CircularProgress, Paper } from '@mui/material';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components/macro';
import PageHeader from '../../../components/Reuseable/PageHeader';
import useLeaderboard from '../../../reactQueryHooks/useLeaderboard';
import LeaderboardItem from './LeaderboardItem';
import LeaderboardInfoText from './LeaderboardInfoText';
import PageError from '../../../components/Reuseable/PageError';
import { LEADERBOARD_TYPES } from '../../../constants/common';

const Wrapper = styled.div`
  padding: 20px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
`;

const Board = styled(Paper)`
  width: 700px;
  height: 900px;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

const Top = styled.div`
  flex: .2;
  background-color: rgb(35, 48, 68);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
`;

const TabBar = styled.div`
  border-radius: 40px;
  height: 60px;
  width: 600px;
  background-color: #EEEEEE;
  display: flex;
  align-items: center;
  justify-content: space-around;
  position: absolute;
  bottom: -30px;
  right: 50%;
  left: 50%;
  transform: translateX(-50%);
`;

const TabItem = styled.div`
  border-radius: 40px;
  cursor: pointer;
  width: 198px;
  height: 58px;
  background-color: ${props => {
    if (props.isActive) {
      return 'white';
    }
    return 'transparent';
  }};
  display: flex;
  align-items: center;
  justify-content: center;


  span {
    color: ${props => {
    if (props.isActive) {
      return 'rgb(51,122,183)';
    }
    return 'black';
  }};
    font-size: 16px;
    font-weight: 600;
  }
`;

const Bottom = styled.div`
  flex: .8;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-top: 46px;
  width: 100%;
  padding-left: 4px; 
  padding-right: 4px;
  background-color: gainsboro;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 6px;

  div {
    display: flex;
    padding-left: 4px;
    align-items: center;
  }
`;

const LeaderboardLoader = styled.div`
  width: 100%;
  height: 460px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const RankDiv = styled.div`
  flex: 0.09;
  margin-left: 2px;
  color: black;
`;

const NameDiv = styled.div`
  flex: 0.72;
  color: black;
`;

const SpiffyDiv = styled.div`
  flex: 0.2;
  margin-right: 6px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;

`;

const SpaceUser = styled.div`
  height: 30px; 
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    font-weight: 800;
  }
`;

const Leaderboards = () => {
  const location = useLocation();
  const initialState = location?.state?.type ? location.state.type : LEADERBOARD_TYPES.LOCATION;
  const intialTeamId = location?.state?.teamId ? location.state.teamId : null;
  const [leaderboardType, setLeaderboardType] = useState(initialState);
  const [teamId, setTeamId] = useState(intialTeamId);

  
  const {
    data,
    isLoading,
    error,
  } = useLeaderboard(leaderboardType, teamId);
  
  if (error) {
    return <PageError />;
  }


  const hasUserData = Boolean(data?.leaderboard?.users_data)



  
  const userInTop10 = isLoading || !hasUserData
    ? false :
     data?.leaderboard?.leaderboard
      .map(p => p.user_id).includes(data.leaderboard?.users_data?.user_id);


  return (
    <>
      <PageHeader text="Leaderboards" />
      <Wrapper>
        <Board>
          <Top>
            <span style={{ fontSize: 26, fontWeight: 600, color: 'white', letterSpacing: -1, }}>
              Spiffy Leaderboard
            </span>
            <TabBar>
              <TabItem
                onClick={() => setLeaderboardType(LEADERBOARD_TYPES.LOCATION)}
                isActive={leaderboardType === LEADERBOARD_TYPES.LOCATION}
              >
                <span>Location</span>
              </TabItem>
              <TabItem
                onClick={() => setLeaderboardType(LEADERBOARD_TYPES.COMPANY)}
                isActive={leaderboardType === LEADERBOARD_TYPES.COMPANY}
              >
                <span>Company</span>
              </TabItem>
              <TabItem
                isActive={leaderboardType === LEADERBOARD_TYPES.INDUSTRY}
                onClick={() => setLeaderboardType(LEADERBOARD_TYPES.INDUSTRY)}
              >
                <span>Industry</span>
              </TabItem>
            </TabBar>
          </Top>
          <Bottom>
            <LeaderboardInfoText
              data={data}
              isLoading={isLoading}
              leaderboardType={leaderboardType}
              setTeamId={setTeamId}
            />
            {
              isLoading ? (
                <LeaderboardLoader>
                  <CircularProgress size={30} style={{ color: 'rgb(51,122,183)' }} />
                </LeaderboardLoader>
              ) : (
                <>
                  <Container>
                    <RankDiv>
                      <span>Rank</span>
                    </RankDiv>
                    <NameDiv>
                      <span>Name</span>
                    </NameDiv>
                    <SpiffyDiv>
                      <span style={{ color: 'black', textAlign: 'right' }}>
                        Spiffy Score
                      </span>
                    </SpiffyDiv>
                  </Container>
                  <div style={{ width: '100%', padding: 2, overflowX: 'hidden' }}>
                    {
                      data?.leaderboard?.leaderboard.map(d => (
                        <LeaderboardItem
                          key={d.name}
                          info={d}
                          usersID={data?.leaderboard?.users_data?.user_id}
                        />
                      ))
                    }
                    {
                      !userInTop10 && data?.leaderboard?.users_data?.user_id && (
                        <>
                          <SpaceUser>
                            <span style={{ fontSize: 24, color: 'rgb(51,122,183)' }}>
                              * * *
                            </span>
                          </SpaceUser>
                          <LeaderboardItem
                            info={data?.leaderboard?.users_data}
                            usersID={data?.leaderboard?.users_data?.user_id}
                            index={11}
                          />
                        </>
                      )
                    }
                  </div>
                </>
              )}
          </Bottom>
        </Board>
      </Wrapper>
    </>
  );
};

export default Leaderboards;
