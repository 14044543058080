import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import {
  ReviewIndicator,
  ReviewOption,
} from '../styles';

const Wrapper = styled.div`
  padding: 4px 8px;
`;

const ReviewMultipleChoice = ({
  question,
}) => (
  <Wrapper>
    {Object.entries(question.options).map(option => (
      <ReviewOption
        key={option[0]}
        selected={question.savedAnswer === option[0].toUpperCase() ? 'yes' : 'no'}
      >
        <span style={{ fontSize: 12 }}>
          {`${option[0].toUpperCase()})  ${option[1]}`}
        </span>
        {
          question.savedAnswer === option[0].toUpperCase()
            ? <ReviewIndicator><div /></ReviewIndicator> : <div />
        }
      </ReviewOption>
    ))}
  </Wrapper>
);

export default ReviewMultipleChoice;

ReviewMultipleChoice.propTypes = {
  question: PropTypes.object.isRequired,
};
