/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { formatDistanceToNow } from 'date-fns';
import { Tooltip, Typography, useTheme } from '@mui/material';
import { XCircle, CheckCircle } from 'react-feather';
import useLast10Submissions from '../../../reactQueryHooks/useLast10Submissions';
import LinearProgLoader from '../../Loaders/LinearProgLoader';
import { addLogoString } from '../../../helpers';

const HoldSubs = styled.div`
  padding: 5px 0px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const FlexDiv = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  span{
    font-weight: 900;
    font-size: 12px;
    color: ${props => props.theme.palette.background.bw};
  }
`;

const LightSpan = styled.span`
  color: ${props => props.theme.palette.background.bw};
  font-size: 11px;
  font-weight: 300;
  letter-spacing: 1px;
  margin-top: -2px;
`;

const Node = styled.div`
  height: 14px;
  width: 14px;
  margin-right: 5px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.theme.palette.background.contrast};

  svg{
    height: 12px;
    width: 12px;
    color: ${(props) => {
    if (props.passed === 'yes') {
      return props.theme.palette.highlight.success;
    }
    return 'tomato';
  }};
  }
`;

const EmojiDict = {
  0: <LightSpan aria-label="emoji" role="img">Struggling.. 😩</LightSpan>,
  1: <LightSpan aria-label="emoji" role="img">You're ice cold 🥶🥶</LightSpan>,
  2: <LightSpan aria-label="emoji" role="img">Not great..😡😡</LightSpan>,
  3: <LightSpan aria-label="emoji" role="img">Lil disappointing.. 😒</LightSpan>,
  4: <LightSpan aria-label="emoji" role="img">Could be better..😐 </LightSpan>,
  5: <LightSpan aria-label="emoji" role="img">Pretty good.. 🙃</LightSpan>,
  6: <LightSpan aria-label="emoji" role="img">Looking good.. 🚀🚀</LightSpan>,
  7: <LightSpan aria-label="emoji" role="img">Brag-worthy 🔥🚀</LightSpan>,
  8: <LightSpan aria-label="emoji" role="img">Unstoppable 🔥🔥</LightSpan>,
  9: <LightSpan aria-label="emoji" role="img">Oh we hot 🔥🔥🔥</LightSpan>,
  10: <LightSpan aria-label="emoji" role="img">You're perfect 🚨💯🚨</LightSpan>,
};

const CustomTooltipInner = styled.div`
  width: 160px;
  min-height: 40px;
  background-color: ${props => props.theme.palette.background.light};
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

  span {
    font-size: 10px;
  }
`;

const ShowName = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const LeftDiv = styled.div`
  width: 20px;
  margin-rigth: 2px;

  img {
    height: 20px;
    margin-top: 4px;
    margin-left: -4px;
    aspect-ratio: 1/1;
    border-radius: 50%;
    object-fit: contain;
    background-color: white; 
  }
`;

const RightDiv = styled.div`
  width: 140px;
`;

const ResultText = styled.p`
  font-size: 10px;
  font-weight: 600;
  margin-bottom: -4px;
  margin-top: -4px;
  color: ${props => props.passed === 'yes' ? props.theme.palette.highlight.success : 'tomato'}
`;

const Streak = ({
  setHidden,
}) => {
  const {
    data,
    error,
    isLoading,
    isFetching,
  } = useLast10Submissions();
  const theme = useTheme();

  if (error) return null;

  if (isLoading) {
    return (
      <>
        <Typography variant="subtitle2">
          Getting data..
        </Typography>
        <LinearProgLoader />
      </>
    );
  }

  const numCorrect = data.submissions.filter(sub => sub.passed).length;
  const currentModeIsLight = theme.palette.mode === 'light';
  const color = currentModeIsLight ? '#f5f5f5' : '#103a5c';
  const lastTen = data.submissions;

  if (lastTen.length < 6) {
    setHidden(true);

    return null;
  }

  return (
    <>
      <FlexDiv>
        <Typography variant="subtitle2">
          Last {lastTen.length === 10 ? '10' : lastTen.length} Submissions
        </Typography>
        <span>{numCorrect}/{lastTen.length}</span>
      </FlexDiv>
      <HoldSubs>
        {
          lastTen.map(sub => (
            <Tooltip
              arrow
              key={sub.created}
              PopperProps={{
                sx: {
                  '& .MuiTooltip-tooltip': {
                    backgroundColor: color,
                    border: '1px solid #bdbdbd',
                  },
                },
              }}
              title={
                (
                  <CustomTooltipInner>
                    <LeftDiv>
                      <img src={addLogoString(sub.quiz_image)} alt="" />
                    </LeftDiv>
                    <RightDiv>
                      <ShowName>
                        <div style={{ maxWidth: 146 }}>
                          <Typography color="secondary" variant="body1" noWrap sx={{ mb: -1 }}>{sub.quiz_name}</Typography>
                          <Typography
                            style={{ fontSize: 9, fontWeight: 300, color: 'black' }}
                          >
                            By: {sub.quiz_company_name}
                          </Typography>
                        </div>
                      </ShowName>
                      <ResultText passed={sub.passed ? 'yes' : 'no'}>
                        {`${sub.passed ? 'Passed' : 'Failed'} with score ${sub.score}/${sub.num_questions}`}
                      </ResultText>
                      <Typography style={{ fontWeight: 400, fontSize: 10 }} color="secondary">
                        {formatDistanceToNow(new Date(sub.created), { addSuffix: true })}
                      </Typography>
                    </RightDiv>
                  </CustomTooltipInner>
                )
              }
            >
              <Node key={sub.created} passed={sub.passed ? 'yes' : 'no'}>
                {sub.passed ? <CheckCircle /> : <XCircle />}
              </Node>
            </Tooltip>
          ))
        }
      </HoldSubs>
      {EmojiDict[numCorrect]}
      {
        isFetching && <LinearProgLoader />
      }
    </>
  );
};

export default Streak;

Streak.propTypes = {
  setHidden: PropTypes.func.isRequired,
};
