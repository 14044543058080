import React from 'react';
import styled from 'styled-components/macro';
import PageHeader from '../../../components/Reuseable/PageHeader';
import AvailableBrands from './AvailableBrands';
import FollowingBrands from './FollowingBrands';
import useAvailToFollowCompanies from '../../../reactQueryHooks/useAvailToFollowCompanies';
import useFollowingCompanies from '../../../reactQueryHooks/useFollowingCompanies';
import PageLoader from '../../../components/Loaders/PageLoader';
import PageError from '../../../components/Reuseable/PageError';

const Wrapper = styled.div`
  padding: 20px;
  width: 100%;
  max-width: 100vw;
`;

const Spacer = styled.div`
  height: 80px;
`;

const Explore = () => {
  const {
    data,
    isLoading,
    error,
    refetch,
  } = useFollowingCompanies();
  const {
    data: availData,
    error: availError,
    isLoading: availLoading,
    refetch: availRefetch,
  } = useAvailToFollowCompanies();

  if (isLoading || availLoading) {
    return (
      <>
        <PageHeader text="Explore" />
        <Wrapper>
          <PageLoader />
        </Wrapper>
      </>
    );
  }

  if (error || availError) {
    return <PageError />;
  }

  return (
    <>
      <PageHeader text="Explore" />
      <Wrapper>
        <FollowingBrands
          data={data}
          error={error}
          refetch={refetch}
          isLoading={isLoading}
        />
        <AvailableBrands
          data={availData}
          error={availError}
          refetch={availRefetch}
          isLoading={availLoading}
        />
        <Spacer />
      </Wrapper>
    </>
  );
};

export default Explore;
