import {
  BookOpen,
  AtSign,
  Mail,
  Archive,
  User,
  Compass,
} from 'react-feather';

const sidebarRoutes = [
  {
    name: 'Learn',
    icon: BookOpen,
    url: '/',
    children: false,
  },
  {
    name: 'Profile',
    icon: User,
    url: '/profile',
    children: false,
  },
  {
    name: 'Explore',
    icon: Compass,
    url: 'explore',
    children: false,
  },
  {
    name: 'Review',
    children: false,
    url: 'review',
    icon: Archive,
  },
  {
    name: 'Invite a friend',
    children: false,
    url: 'invite',
    icon: AtSign,
  },
  {
    name: 'Contact us',
    children: false,
    url: 'contact',
    icon: Mail,
  },
];

export default sidebarRoutes;
