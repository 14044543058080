/* eslint-disable */
import React from 'react';
import styled from 'styled-components/macro'
import { Divider, Paper } from '@mui/material';

const Wrapper = styled(Paper)`
  width: 100%;
  min-height: 100px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  @media(max-width: 1100px) {
    display: none;
  }
`

const SubtitleSpan = styled.span`
  font-size: 13px;
  color: darkgrey;
  font-weight: 300;
  text-transform: uppercase;
  letter-spacing: 1px;
  line-height: 1;
`;

const Top = styled.div`
  width: 100%;
  padding: 10px;
  border-bottom: 1px solid lightgray;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
`;

const Bottom = styled.div`
  padding: 10px;
`

const BrandDescription = ({
  description
}) => {
  return (
    <Wrapper>
      <Top>
        <SubtitleSpan>
          Brand Description
        </SubtitleSpan>
      </Top>
      <Bottom>
        <span style={{ lineHeight: 1.1 }}>
          {description}
        </span>
      </Bottom>
    </Wrapper>
  )
}

export default BrandDescription