import { useQuery } from 'react-query';
import API from '../axios/API';

const getUserSubmissions = async () => {
  const { data } = await API.get('/submissions/');
  return data;
};

const useUserCompletions = () => useQuery('submissions', getUserSubmissions);

export default useUserCompletions;
