/* eslint-disable */
import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { Paper, Button, Typography, CircularProgress } from '@mui/material';
import BLUE_BG from '../../../../assets/bluebg.png'
import API from '../../../../axios/API';
import useSnack from '../../../../store/snack'

const Wrapper = styled(Paper)`
  width: 100%;
  height: 320px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const Top = styled.div`
  width: 100%;
  background-color: darkblue;
  border-top-right-radius: 12px;
  border-top-left-radius: 12px;
  object-fit: contain;
  height: 125px;
`;

const TopImg = styled.img`
  height: 125px;
  width: 100%;
  object-fit: cover;
  border-top-right-radius: 12px;
  border-top-left-radius: 12px;
`

const Bottom = styled.div`
  flex: .5;
  height: 195px;
  width: 100%;
  background-color: white;
  border-bottom-right-radius: 12px;
  border-bottom-left-radius: 12px;
  padding: 10px 10px 10px 20px;
  display: flex;
`;

const BrandImage = styled.img`
  object-fit: contain;
  height: 240px;
  width: 240px;
  border-radius: 120px;
  border: 1px solid #EEEEEE;
  position: relative;
  top: -100px;
  background-color: white;
  box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, .1);
`;

const BrandName = styled(Typography)`
  font-size: 36px;
  letter-spacing: -1px;
  font-weight: 500;
  line-height: 1;

  @media(max-width: 1200px) {
    font-size: 24px;
  }
`

const BottomFlexContainer = styled.div`
  height: 100%;
  flex: .25;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding-top: 10px;
  border-radius: 12px;

  @media(max-width: 900px) {
    width: 100%;
    flex-direction: row;
    flex: 1;
  }
`;

const BottomFlexContainerHideAt900 = styled(BottomFlexContainer)`
  @media(max-width: 900px) {
    display: none;
  }

`

const SubtitleSpan = styled.span`
  font-size: 13px;
  color: darkgrey;
  font-weight: 300;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-top: 12px;

  @media(max-width: 1200px) {
    font-size: 10px;
  }
`;

const StatSpan = styled.span`
  font-size: 34px;
  font-weight: 700;
  color: black;

  @media(max-width: 1200px) {
    font-size: 24px;
  }
`


const ButtonContainer = styled.div`
  display: flex; 
  margin-top: 12px; 
  width: 100%; 
  align-items: center;
  justify-content: flex-start;
  gap: 4px;

  @media (max-width: 1200px) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
`

const BrandHero = ({ data, refetch }) => {

  const [followUpdating, setFollowUpdating] = useState(false);
  const { snack } = useSnack();
  const { setSnack, SNACK_TYPES } = snack;

  const handleFollowBrand = async () => {
    setFollowUpdating(true)
    try {
      await API.post('/following/', {
        company_id: data.company.id,
      })
      await refetch();
      setFollowUpdating(false)
      setSnack(`You followed ${data.company.name}`, SNACK_TYPES.SUCCESS)
      return
    } catch (error) {
      setFollowUpdating(false)
      setSnack(`Failed to follow ${data.company.name}`, SNACK_TYPES.ERROR)
      return
    }
  }

  const handleUnfollowBrand = async () => {
    setFollowUpdating(true)
    try {
      await API.delete('/following/', {
        data: {
          company_id: data.company.id,
        }
      })
      await refetch();
      setFollowUpdating(false)
      return setSnack(`You unfollowed ${data.company.name}`, SNACK_TYPES.WARNING)
    } catch (error) {
      setFollowUpdating(false)
      return setSnack(`Failed to unfollow ${data.company.name}`, SNACK_TYPES.ERROR)
    }
  }

  const handleRequestContent = async () => {
    try {
      const { data: responseData } = await API.get(`/following/request-content/?company_id=${data.company.id}`);
      if (responseData.recent_request_exists) {
        return setSnack('Content request has been sent', SNACK_TYPES.SUCCESS)
      }
      await API.post('/following/request-content/', {
        company_id: data.company.id
      })
      setSnack('Content request successfully sent', SNACK_TYPES.SUCCESS)
    } catch (error) {
      setSnack('Issue sending this request', SNACK_TYPES.ERROR)
    }
  }

  return (
    <Wrapper>
      <Top>
        <TopImg src={BLUE_BG} />
      </Top>
      <Bottom>
        <BrandImage src={data.company.logo}/>
        <div style={{  height: 155, flexGrow: 1, marginLeft: 24, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
          <BottomFlexContainer style={{ flexBasis: '25%'}}>
            <BrandName>
              {data.company.name}
            </BrandName>
            <ButtonContainer>
              { data.is_followed ? (
                <Button
                  size="small"
                  variant="contained"
                  color="error"
                  sx={{ mr: 1}}
                  style={{ color: 'white', whiteSpace: 'nowrap', minWidth: 108}}
                  onClick={handleUnfollowBrand}
                  endIcon={followUpdating ? <CircularProgress size={20} style={{ color: 'white'}} /> : null }
                >
                  Unfollow Brand
                </Button>
              ) : (
              <Button
                size="small"
                variant="contained"
                color="action"
                style={{ color: 'white', whiteSpace: 'nowrap', minWidth: 108}}
                onClick={handleFollowBrand}
                endIcon={followUpdating ? <CircularProgress size={20} style={{ color: 'white'}} /> : null }
              >
                Follow Brand
              </Button>
              )}
              <Button
                size="small"
                variant="contained"
                style={{ minWidth: 120}}
                // sx={{ ml: 1}}
                onClick={handleRequestContent}
              >
                Request Content
              </Button>
            </ButtonContainer>
          </BottomFlexContainer>
          <BottomFlexContainerHideAt900>
            <SubtitleSpan>
              Followers
            </SubtitleSpan>
            <StatSpan>{data.company.followers}</StatSpan>
          </BottomFlexContainerHideAt900>
          <BottomFlexContainerHideAt900>
            <SubtitleSpan>
              Live Modules
            </SubtitleSpan>
            <StatSpan>{data.available_quizzes.length}</StatSpan>
          </BottomFlexContainerHideAt900>
          <BottomFlexContainerHideAt900>
            <SubtitleSpan>
              Live Streams
            </SubtitleSpan>
            <StatSpan>{data.streams.length}</StatSpan>
          </BottomFlexContainerHideAt900>
        </div>
      </Bottom>
    </Wrapper>
  )
}

export default BrandHero