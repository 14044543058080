import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import {
  ReviewOption,
  ReviewIndicator,
} from '../styles';

const Wrapper = styled.div`
  padding: 4px 8px;
`;

const ReviewTrueFalse = ({
  question,
}) => (
  <Wrapper>
    <ReviewOption
      selected={question.savedAnswer === true ? 'yes' : 'no'}
    >
      <span style={{ fontSize: 12 }}>
        True
      </span>
      {
        question.savedAnswer === true ? <ReviewIndicator><div /></ReviewIndicator> : <div />
      }
    </ReviewOption>
    <ReviewOption
      selected={question.savedAnswer === false ? 'yes' : 'no'}
    >
      <span style={{ fontSize: 12 }}>
        False
      </span>
      {
        question.savedAnswer === false ? <ReviewIndicator><div /></ReviewIndicator> : <div />
      }
    </ReviewOption>
  </Wrapper>
);

export default ReviewTrueFalse;

ReviewTrueFalse.propTypes = {
  question: PropTypes.object.isRequired,
};
