import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import {
  InputAdornment,
  Typography,
} from '@mui/material';
import { Search, UserCheck } from 'react-feather';
import FollowingBrand from './FollowingBrand';
import ComponentLoader from '../../../components/Loaders/ComponentLoader';
import { CustomTextField } from '../../../styles/forms';
import NoBrandsFound from './NoBrandsFound';
import {
  BrandsContainer,
  CenterWrapper,
  FlexDiv,
  FlexBetween,
  ClearBtn,
  ScrollBrands,
  FakeTab,
} from './styles';

const NoneFollowedWrapper = styled.div`
  width: 100%;
  height: 212px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CustomTextFieldResponsive = styled(CustomTextField)`
  min-width: 200px;


  @media(max-width: 540px) {
    min-width: 100px;
    width: 100px;
  }

  @media (max-width: 400px){
    display: none;
  }

`;

const NoneFollowed = () => (
  <NoneFollowedWrapper>
    <Typography variant="h6">
      You don&apos;t follow any brands 🙄
    </Typography>
  </NoneFollowedWrapper>
);

const FollowingBrands = ({
  data,
  error,
  refetch,
  isLoading,
}) => {
  const [search, setSearch] = useState('');

  if (error) {
    return <div>errer</div>;
  }

  const brands = useMemo(() => {
    if (isLoading) return null;
    if (!data) return null;

    if (search.length > 2) {
      return data.following
        .filter(follow => follow.company.name.toLowerCase().includes(search.toLowerCase()));
    }

    return data.following;
  }, [data, search]);

  if (isLoading) return <CenterWrapper><ComponentLoader /></CenterWrapper>;
  if (error) return <CenterWrapper><div>error...</div></CenterWrapper>;

  return (
    <BrandsContainer>
      <FlexBetween>
        <FakeTab>
          <div style={{
            height: 32,
            width: 32,
            border: '1px solid lightgray',
            marginRight: 8,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            background: 'white',
            borderRadius: 6,
          }}
          >
            <UserCheck />
          </div>
          <span style={{ whiteSpace: 'nowrap' }}>
            Brands You Follow
          </span>
          <span
            style={{
              marginLeft: 4,
              fontSize: 12,
              color: 'slategrey',
            }}
          >
            {`(${data.following.length})`}
          </span>
        </FakeTab>
        <FlexDiv>
          <CustomTextFieldResponsive
            type="text"
            placeholder="Search brands..."
            size="small"
            variant="outlined"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search height={14} width={14} />
                </InputAdornment>
              ),
            }}
          />
          <ClearBtn
            variant="text"
            size="small"
            sx={{ ml: 1 }}
            disabled={search.length < 3}
            onClick={() => setSearch('')}
          >
            Clear
          </ClearBtn>
        </FlexDiv>
      </FlexBetween>
      <ScrollBrands>
        {
          (search.length < 3 && brands.length === 0) && <NoneFollowed />
        }
        {
          (search.length > 2 && brands.length === 0) && <NoBrandsFound />
        }
        {
          brands && brands.map(brand => (
            <FollowingBrand
              brand={brand}
              key={brand.company.id}
              refetch={refetch}
            />
          ))
        }
      </ScrollBrands>
    </BrandsContainer>
  );
};

export default FollowingBrands;

FollowingBrands.propTypes = {
  data: PropTypes.object.isRequired,
  error: PropTypes.bool,
  refetch: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

FollowingBrands.defaultProps = {
  error: null,
};
