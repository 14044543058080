/* eslint-disable */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import {
  Paper,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import { ChevronDown } from 'react-feather';
import { ExitModal } from '../../../styles/buttons';
import BasicsForm from './BasicsForm';
import LocationForm from './LocationForm';
import DateOfBirthForm from './DateOfBirthForm';
import useSnack from '../../../store/snack';

const ModalWrapper = styled(Paper)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  border: 2px solid black;
`;

const StyledAccordion = styled(Accordion)`
  box-shadow: none;
`;

const StyledAccordionSummary = styled(AccordionSummary)`
  padding: 0px;
  border: none;
`;

const Text = styled.span`
  font-size: 16px;
  color: ${props => {
    if (props.infoSaved === 'yes') {
      return props.theme.palette.secondary.main;
    }
    return 'tomato';
  }}
`;

const EditUserInfoModal = ({
  handleExitModal,
  refetch,
  profile,
}) => {

  const { snack } = useSnack();
  const { setSnack, SNACK_TYPES } = snack;
  const [expandedIndex, setExpandedIndex] = useState(0)

  const hasBirthDateSaved = Boolean(profile.birth_date);
  const hasLocationSaved = (Boolean(profile.country) && Boolean(profile.state));

  return (
    <ModalWrapper>
      <Typography
        variant="h6"
      >
        Edit Your Personal Information
      </Typography>
      <Typography
        variant="subtitleSmall"
        sx={{ mb: 4 }}
      >
        Save new personal information here
      </Typography>
      <StyledAccordion
        TransitionProps={{ unmountOnExit: true }}
        expanded={expandedIndex === 0}
        onChange={() => {
          if (expandedIndex === 0) {
            return setExpandedIndex(null);
          }
          return setExpandedIndex(0);
        }}
      >
        <StyledAccordionSummary
          expandIcon={<ChevronDown />}
        >
          <span style={{ fontSize: 16 }}>
            Basics
          </span>
        </StyledAccordionSummary>
        <AccordionDetails>
          <BasicsForm
            setSnack={setSnack}
            SNACK_TYPES={SNACK_TYPES}
            profile={profile}
            refetch={refetch}
            setExpandedIndex={setExpandedIndex}
          />
        </AccordionDetails>
      </StyledAccordion>
      <StyledAccordion
        TransitionProps={{ unmountOnExit: true }}
        expanded={expandedIndex === 1}
        onChange={() => {
          if (expandedIndex === 1) {
            return setExpandedIndex(null);
          }
          return setExpandedIndex(1);
        }}
      >
        <StyledAccordionSummary
          expandIcon={<ChevronDown />}
        >
          <Text
            infoSaved={hasBirthDateSaved ? 'yes' : 'no'}
          >
            Date of Birth
          </Text>
        </StyledAccordionSummary>
        <AccordionDetails>
          <DateOfBirthForm
            setSnack={setSnack}
            SNACK_TYPES={SNACK_TYPES}
            profile={profile}
            refetch={refetch}
            setExpandedIndex={setExpandedIndex}
          />
        </AccordionDetails>
      </StyledAccordion>
      <StyledAccordion
        TransitionProps={{ unmountOnExit: true }}
        expanded={expandedIndex === 2}
        onChange={() => {
          if (expandedIndex === 2) {
            return setExpandedIndex(null);
          }
          return setExpandedIndex(2);
        }}
      >
        <StyledAccordionSummary
          expandIcon={<ChevronDown />}
        >
          <Text
            infoSaved={hasLocationSaved ? 'yes' : 'no'}
          >
            Location
          </Text>
        </StyledAccordionSummary>
        <AccordionDetails>
          <LocationForm
            setSnack={setSnack}
            SNACK_TYPES={SNACK_TYPES}
            profile={profile}
            refetch={refetch}
            setExpandedIndex={setExpandedIndex}
          />
        </AccordionDetails>
      </StyledAccordion>
      <ExitModal onClick={handleExitModal} />
    </ModalWrapper>
  );
};

export default EditUserInfoModal;

EditUserInfoModal.propTypes = {
  refetch: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
  handleExitModal: PropTypes.func.isRequired,
};
