import { useQuery } from 'react-query';
import API from '../axios/API';

const getTeamLocations = async (teamId) => {
  const { data } = await API.get(`teams/location/?tm_id=${teamId}`);
  return data.locations;
};

const useAllTeamLocations = teamId => useQuery(['teamlocations', teamId], () => getTeamLocations(teamId));

export default useAllTeamLocations;
