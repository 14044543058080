/* eslint-disable */
import React from 'react';
import { Paper, Typography } from '@mui/material';
import styled from 'styled-components/macro';
import StreamMiniModule from './StreamMiniModule';

const Wrapper = styled(Paper)`
  width: 100%;
  padding: 10px;
  margin-bottom: 12px;
`;

const HoldMiniModules = styled.div`
  width: 100%;
  overflow-y: scroll;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 4px 0px;
`

const StreamCard = ({ stream }) => {

  const allModsInStreamCompleted = stream.quizzes.every(mod => mod.completed);

  return (
    <Wrapper
      style={{ borderLeft: allModsInStreamCompleted ? '4px solid #50c878' : '4px solid #FAA0A0 '}}
    >
      <span style={{ color: 'darkgrey', fontSize: 12, fontWeight: '300', letterSpacing: 1}}>
        Stream {stream.stream_order}
      </span>
      <Typography 
          style={{ 
            fontSize: 16, 
            fontWeight: 400, 
            letterSpacing: -1, 
            lineHeight: 1.2, 
            flex: .4,
            marginRight: 4,
            marginBottom: 6,
          }}          
        >
        {stream.name}
      </Typography>
      <HoldMiniModules>
        {
          stream.quizzes.map((mod, i) => <StreamMiniModule mod={mod} index={i} numModulesInStream={stream.quizzes.length} />)
        }
      </HoldMiniModules>

      <span></span>
    </Wrapper>
  )
}

export default StreamCard;
