/* eslint-disable */
import React from 'react';
import { Tooltip } from '@mui/material';
import styled from 'styled-components/macro';
import { addLogoString } from '../../../../helpers';


const Wrapper = styled.div`
  border: 1px solid #EEEEEE;
  border-radius: 2px;
  width: 60px;
  margin-right: 16px;
  height: 60px;
  position: relative;
  cursor: pointer;
  // box-shadow: 0px 0px 2px 2px gainsboro;

  &:hover {
    transform: scale(1.01);
  }
`;


const Connector = styled.div`
  width: 24px;
  height: 1px;
  background-color: lightgray;
  position: absolute;
  right: -24px;
  top: 28px;
  z-index: 0;
`


const ModuleImage = styled.img`
  object-fit: contain;
  border-radius: 2px;
  height: 100%;
  width: 100%;
`

const NumberDiv = styled.div`
  position: absolute;
  border-radius: 1px;
  top: 0px;
  right: 0px;
  width: 14px;
  height: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;

  span {
    color: white;
    font-size: 9px;
    font-weight: 500;
  }
`


const StreamMiniModule = ({
  mod,
  index,
  numModulesInStream,
}) => {
  return (
    <Tooltip
      arrow
      title={(
        <div style={{ maxWidth: 140, minHeight: 30}}>
          <p>{mod.name}</p>
          {
            mod.completed ? (
              <span style={{ color: 'mediumseagreen'}}>completed</span> 
            ) : <span style={{ color: 'tomato'}}>incomplete</span>
          }
        </div>
      )}
    >
      <Wrapper>
        {
          index + 1 !== numModulesInStream && (
            <Connector />
          )
        }
        <NumberDiv
          style={{ backgroundColor: mod.completed ? 'mediumseagreen' : 'tomato'}}
        >
          <span>{(index + 1)}</span>
        </NumberDiv>
          <ModuleImage
            src={addLogoString(mod.image)}
          >
          </ModuleImage>

      </Wrapper>
    </Tooltip>
  )
}

export default StreamMiniModule