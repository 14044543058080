/* eslint-disable */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { Typography, Button, CircularProgress } from '@mui/material';
import { useQueryClient } from 'react-query';
import {
  Briefcase,
  MapPin,
  Tag,
} from 'react-feather';
import {
  ButtonHolder,
} from './styles';
import API from '../../../../../axios/API';
import useSnack from '../../../../../store/snack';

const Wrapper = styled.div`
  width: 100%;
`;

const InnerWrapper = styled.div`
  padding: 20px;
  width: 100%;
  height: 100%;
`;

const MarginSpan = styled.span`
  margin-right: 8px;
`;

const FlexDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  
  svg{
    color: ${props => props.theme.palette.background.bw};
    height: 16px;
    width: 16px;
  }
`;

const ButtonHolderTwo = styled.div`
  margin-top: 16px;
  width: 320px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Spacer = styled.div`
  width: 100%;
  background: transparent;
  height: 28px;
`;

const ConfirmWrapper = styled.div`
  width: 100%;
  padding: 20px;
  height: 260px;
  border-radius: 4px;
  border: 2px solid #EEEEEE67;
  display: flex;
  align-items: center;
  justify-content :center;
  flex-direction: column;
`;

const OuterFlexDiv = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 6px 4px;
  border-bottom: 1px solid #EEEEEE;
  margin-bottom: 10px;
`;

const Centered = styled.div`
  width: 100%;
  border: 2px solid #EEEEEE;
  margin: auto;
  min-height: 320px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const DarkGrey = styled(Typography)`
  color: darkgrey;
  font-weight: 400;
  text-transform: uppercase;
  font-size: 15px;
`;

const COMPONENT_STATES = {
  CONFIRMING: 'confirming',
  PROCESSING: 'processing',
  FINISHED: 'finished',
};

const Confirm = ({
  state,
  dispatch,
  ACTIONS,
  setAddTeamModalOpen,
}) => {
  const queryClient = useQueryClient();
  const { snack } = useSnack();
  const { SNACK_TYPES, setSnack } = snack;
  const [componentState, setComponentState] = useState(COMPONENT_STATES.CONFIRMING);

  const handleSubmit = async () => {
    setComponentState(COMPONENT_STATES.PROCESSING);
    try {
      await API.post('/teams/search/add/', {
        location_id: state.selectedLocation.id,
        company_id: state.selectedCompany.id,
        roles: state.selectedRoles.map(r => Number(r.id)),
      });
      await queryClient.invalidateQueries('availQuizzes', { refetchInactive: true });
      queryClient.invalidateQueries('users');
      setSnack(`Company ${state.selectedCompany.name} joined`, SNACK_TYPES.SUCCESS);
      setAddTeamModalOpen(false);
    } catch (error) {
      setSnack('Failed to join company', SNACK_TYPES.ERROR);
    }
  };

  return (
    <Wrapper>
      <InnerWrapper>
        <Typography variant="body1" color="primary">
          Step 4
        </Typography>
        <Typography variant="h3" mb={2}>
          Confirm Details
        </Typography>
        <ConfirmWrapper>
          <OuterFlexDiv>
            <FlexDiv>
              <Briefcase />
              <Typography variant="subtitle1" sx={{ ml: 2 }}>
                Company Name
              </Typography>
            </FlexDiv>
            <DarkGrey variant="body1" noWrap sx={{ ml: 2 }}>
              {state.selectedCompany.name}
            </DarkGrey>
          </OuterFlexDiv>
          <OuterFlexDiv>
            <FlexDiv>
              <MapPin />
              <Typography variant="subtitle1" sx={{ ml: 2 }}>
                Company Location
              </Typography>
            </FlexDiv>
            <DarkGrey variant="body1" sx={{ ml: 2 }} noWrap>
              {state.selectedLocation.name}
            </DarkGrey>
          </OuterFlexDiv>
          <OuterFlexDiv>
            <FlexDiv>
              <Tag />
              <Typography variant="subtitle1"  sx={{ ml: 2 }}>
                Roles
              </Typography>
            </FlexDiv>
            <DarkGrey  noWrap sx={{ ml: 2 }}>
              {state.selectedRoles.map((r, i) => <MarginSpan key={r.id}>{`${r.name}${(i === (state.selectedRoles.length - 1)) ? '' : ','}`}</MarginSpan>)}
            </DarkGrey>
          </OuterFlexDiv>
        </ConfirmWrapper>
        <Spacer />
        <ButtonHolder sx={{ mt: 2 }}>
          <Button
            variant="outlined"
            color="secondary"
            size="small"
            onClick={() => dispatch({ type: ACTIONS.REMOVE_SELECTED_ROLES })}
          >
            Go Back
          </Button>
          <Button
            variant="contained"
            color="primary"
            size="small"
            sx={{ ml: 1 }}
            onClick={handleSubmit}
            startIcon={componentState === COMPONENT_STATES.PROCESSING ? <CircularProgress size={15} color="primary" /> : null}
            disabled={componentState === COMPONENT_STATES.PROCESSING}
          >
            {componentState === COMPONENT_STATES.PROCESSING ? '...' : 'Finish'}
          </Button>
        </ButtonHolder>
      </InnerWrapper>
    </Wrapper>
  );
};

export default Confirm;

Confirm.propTypes = {
  state: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  ACTIONS: PropTypes.object.isRequired,
  setAddTeamModalOpen: PropTypes.func.isRequired,
};
