import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Paper, Button } from '@mui/material';
import styled from 'styled-components/macro';
import ReviewTrueFalse from './ReviewTrueFalse';
import ReviewSelectAll from './ReviewSelectAll';
import ReviewMultipleChoice from './ReviewMultipleChoice';

const EditButton = styled(Button)`
  font-size: 11px, 
  background-color: 'white'; 
  color: black; 
  border: none;

  &:hover {
    border: none;
    background-color: '#EEEEEE24';
  }
`;

const Wrapper = styled.div`
  width: 100%;
  padding: 10px 0px 20px 0px;
`;

const HoldAnswers = styled.div`
  max-width: 1200px;
  display: flex;
  width: 100%;
  flex-direction: column;
  margin: auto;
`;

const ShowAnswer = styled(Paper)`
  background-color: ${props => props.theme.palette.background.paper};
  border-radius: 0px;
  padding: 12px;
  margin-bottom: 16px;
`;

const FlexDiv = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${props => props.theme.palette.background.contrast};
  padding: 2px 8px;
  margin-bottom: 4px;
`;

const Flex = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2px 10px;
`;

const QuestionType = styled(Typography)`
  font-size: 10px;
  font-weight: 700;
  color: ${props => props.theme.palette.background.bw};
`;

const QUESTION_TYPES = {
  tf: 'True/False',
  mc: 'Multiple Choice',
  sa: 'Select All',
};

const ShowReviewType = ({
  question,
  state,
  dispatch,
}) => {
  if (question.type === 'sa') {
    return <ReviewSelectAll question={question} state={state} dispatch={dispatch} />;
  }

  if (question.type === 'mc') {
    return <ReviewMultipleChoice question={question} state={state} dispatch={dispatch} />;
  }

  return (
    <ReviewTrueFalse question={question} state={state} dispatch={dispatch} />
  );
};

ShowReviewType.propTypes = {
  question: PropTypes.object.isRequired,
  state: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
};

const Review = ({
  ACTIONS,
  state,
  dispatch,
}) => {
  const { questions } = state;

  return (
    <Wrapper>
      <HoldAnswers>
        {
          questions.map(question => (
            <ShowAnswer key={question.id}>
              <FlexDiv>
                <div>
                  <span style={{ fontSize: 9, fontWeight: 300 }}>
                    Question {question.order + 1}
                  </span>
                  <Typography variant="subtitle2" sx={{ padding: '0px' }}>
                    {question.question}
                  </Typography>
                </div>
                <EditButton
                  sx={{
                    fontSize: 11,
                    backgroundColor: 'white',
                    color: 'black',
                    border: 'none',
                    whiteSpace: 'noWrap',
                  }}
                  size="small"
                  variant="outlined"
                  color="primary"
                  onClick={() => dispatch({ type: ACTIONS.EDIT_QUESTION, payload: question.order })}
                >
                  Edit Answer
                </EditButton>
              </FlexDiv>
              <Flex>
                <QuestionType>{QUESTION_TYPES[question.type]}</QuestionType>
              </Flex>
              <Typography variant="subtitleSmall" color="secondary" sx={{ ml: 4, mb: -1 }}>
                Options
              </Typography>
              <ShowReviewType question={question} state={state} dispatch={dispatch} />
            </ShowAnswer>
          ))
        }
      </HoldAnswers>
    </Wrapper>
  );
};

export default Review;

Review.propTypes = {
  ACTIONS: PropTypes.object.isRequired,
  state: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
};
