/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { useNavigate } from 'react-router-dom';
import { Typography, Tooltip } from '@mui/material';
import InfoPopper from './InfoPopper';
import ModuleCard from './ModuleCard';
import { addLogoString } from '../../../helpers';
import { TrendingUp } from 'react-feather';
import { STREAM_TYPES_ARR } from '../../../constants/common';
import { motion } from 'framer-motion'

const Wrapper = styled.div`
  width: 100%;
  margin-bottom: 24px;
`;

const StreamNameSpan = styled.span`
  color: black;
  font-size: 12px;
  font-weight: 300;
`

const StreamWrapper = styled.div`
  display: flex; 
  align-items: center; 
  justify-content: center; 
  border-radius: 0px; 
  cursor: pointer;
  border-radius: 4px;

`

const ScrollCards = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  overflow-x: scroll;
  padding: 10px 20px 20px 2px;
`;

const CompanyImage = styled.img`
  aspect-ratio: 9/5;
  height: 48px;
  object-fit: contain;
  border-radius: 6px;
  background: white;
  margin-left: -6px;
`;

const CompanyImageTwo = styled.img`
  height: 42px;
  width: 42px;
  object-fit: contain;
  border-radius: 8px;
  background: white;
  border: 1px solid lightgray;
`;

const FlexDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 4px;
  height: 100%;
  overflow: hidden;
`;

const CompanyInfo = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 10px;
  border-radius: 10px;
  background-color: ${props => props.theme.palette.background.contrast};
`;


const ToolTipContainer = styled.div`
  min-width: 120px;
  padding: 6px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
`;

const ShowStreamInfoAsTooltip = ({
  streamInfo,
}) => {

  const { parent, name } = streamInfo;

  return (
      <Tooltip 
        arrow
        PopperProps={{
          sx: {
            '& .MuiTooltip-tooltip': {
              backgroundColor: 'white',
              border: '1px solid #bdbdbd',
            },
          },
        }}
        title={(
          <ToolTipContainer>
            <Typography variant="subtitleSmall">Stream Created By:</Typography>
            <Typography 
              variant="subtitle2" 
              style={{color: 'black'}}
            >
              {parent}
            </Typography>
          </ToolTipContainer>
        )}
      >

        <StreamWrapper>
          <StreamNameSpan>
            {name}
          </StreamNameSpan>
          <div style={{ backgroundColor: 'white', height: 17, width: 17, borderRadius: 4, border: '1px solid #EEEEEE', display: 'flex', alignItems: 'center', justifyContent: 'center', marginLeft: 4}}>
            <TrendingUp height={12} width={12} />
          </div> 
        </StreamWrapper>
      </Tooltip>
  )
}

const ScrollableRow = ({
  list,
}) => {

  const navigate = useNavigate();
  const { rowType } = list
  const isRowAStream = STREAM_TYPES_ARR.includes(rowType);

  return (
    <Wrapper>
      <CompanyInfo>
        <FlexDiv
          as={motion.div}
          initial={{ x: 100}}
          animate={{ x: 0 }}
        > 
          <CompanyImageTwo 
            src={addLogoString(list.company_logo)} 
            as={motion.img}
            initial={{ scale: .6, rotate: 90 }}
            animate={{ scale: 1, rotate: 0 }}
            transition={{ delay: .25 }}
          />
          <div style={{ display: 'flex', height: '100%', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'center', marginLeft: 10}}>
              <Typography style={{ fontWeight: '500', fontSize: isRowAStream ? 13: 14, textTransform: 'uppercase', color: 'darkgrey', lineHeight: 1.2}}>
                {list.company_name}
              </Typography>
              {
                isRowAStream && (
                  <ShowStreamInfoAsTooltip streamInfo={{
                    parent: list.marketplace_parent_company_name ? list.marketplace_parent_company_name : list.company_name, 
                    name: list.name,
                  }} />
                )
              }
          </div>
        </FlexDiv>
        <FlexDiv>
          <InfoPopper item={list} />
        </FlexDiv>
      </CompanyInfo>
      <ScrollCards>
        {
          list.quizzes.map(mod => (
            <ModuleCard mod={mod} key={mod.id} navigate={navigate} />
          ))
        }
      </ScrollCards>
    </Wrapper>
  );
};

export default ScrollableRow;

ScrollableRow.propTypes = {
  list: PropTypes.object.isRequired,
};
