import styled from 'styled-components/macro';
import { Paper } from '@mui/material';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { fadeIn } from './keyframes';

export const BaseCard = styled(Paper)`
  background-color: ${props => props.theme.palette.background.paper}; 
  padding: 20px;
  min-height: 100px;
  min-width: 100px;
  box-shadow: ${props => props.theme.palette.boxShadow.main};
`;

export const BrandCard = styled(Paper)`
  background-color: white;
  text-align: center;
  width: 260px;
  min-width: 260px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  position: relative;
  border-radius: 12px;
  margin-right: 16px;
  margin-bottom: 16px;
  height: 200px;
  animation: ${fadeIn} 240ms ease-out;
  
  &:hover{
    box-shadow: 2px 1px 6px 4px rgba(0, 0, 0, .15);
  }

  @media(max-width: 500px) {
    width: 160px;
    min-width: 160px;
    height: 140px;
    max-height: 140px;
  }
`;

export const BrandImage = styled(LazyLoadImage)`
  width: 100%;
  object-fit: contain;
  height: 160px;
  border-top-right-radius: 12px;
  border-top-left-radius: 12px;
  background-color: white;

  @media(max-width: 500px) {
    height: 100px;
    max-height: 100px;
  }

`;
