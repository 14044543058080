import React, { useState } from 'react';
import styled from 'styled-components/macro';
import {
  Typography,
  Checkbox,
  InputAdornment,
  LinearProgress,
  Button,
  Modal,
} from '@mui/material';
import { Mail, User } from 'react-feather';
import { Formik } from 'formik';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import * as Yup from 'yup';
import QRCode from 'react-qr-code';
import { BaseCard } from '../../../styles/cards';
import useSnack from '../../../store/snack';
import PageHeader from '../../../components/Reuseable/PageHeader';
import { CustomTextField, StyledButton } from '../../../styles/forms';
import API from '../../../axios/API';
import useReferralCode from '../../../reactQueryHooks/useReferralCode';

const Wrapper = styled.div`
  padding: 20px;
`;

const DarkerBackdropModal = styled(Modal)`
  background-color: rgba(0, 0, 0, .7);
`;

const CenterForm = styled.div`
  width: 100%;
  height: 70vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const FormPaper = styled(BaseCard)`
  width: 440px;
  background: ${props => props.theme.palette.background.paper};

  @media(max-width: 461px) {
    padding: 20px 10px;
    width: 100%;
  }

`;

const HoldCheckbox = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 16px;
`;

const QrCodeWrapper = styled.div`
  position: absolute;
  top: 50%; 
  left: 50%; 
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: lightgray;
`;

const Invite = () => {
  const { snack } = useSnack();
  const { setSnack, SNACK_TYPES } = snack;
  const [showQrCode, setShowQrCode] = useState(false);
  const { data, isLoading, error: referralError } = useReferralCode();

  const handleFormSubmit = async (values, { setSubmitting, resetForm }) => {
    setSubmitting(true);
    try {
      await API.post('/contact/invite/', {
        name: values.name,
        email: values.email,
      });
      setSubmitting(false);
      resetForm();
      return setSnack('Successfully invited your friend', SNACK_TYPES.SUCCESS);
    } catch (error) {
      setSubmitting(false);
      resetForm();
      return setSnack('Failed to invite your friend, please try again later', SNACK_TYPES.ERROR);
    }
  };

  const InitialValues = {
    name: '',
    email: '',
    includeRef: true,
  };

  const ValidationSchema = Yup.object().shape({
    email: Yup.string()
      .email('Must be a valid email')
      .required('Email is required'),
    name: Yup.string()
      .required('Name of person is required')
      .min(2, '2 characters minimum'),
    includeRef: Yup.bool(),
  });

  return (
    <>
      <PageHeader text="Invite A Friend" />
      <Wrapper>
        <CenterForm>
          <FormPaper>
            <Formik
              validateOnBlur
              initialValues={InitialValues}
              validationSchema={ValidationSchema}
              onSubmit={handleFormSubmit}
            >
              {({
                errors,
                touched,
                values,
                handleChange,
                handleBlur,
                isSubmitting,
                handleSubmit,
              }) => (
                <form noValidate onSubmit={handleSubmit}>
                  <Typography variant="h2">
                    Invite A Friend
                  </Typography>
                  <Typography variant="body1" sx={{ mb: 6 }}>
                    Enter your friends name and email to get them started with Spiffy
                  </Typography>
                  <CustomTextField
                    name="name"
                    onChange={handleChange}
                    value={values.name}
                    fullWidth
                    error={Boolean(touched.name && errors.name)}
                    onBlur={handleBlur}
                    helperText={touched.name && errors.name}
                    label="Invitee Name"
                    size="small"
                    sx={{
                      mb: 5,
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <User />
                        </InputAdornment>
                      ),
                    }}
                  />
                  <CustomTextField
                    type="email"
                    name="email"
                    label="Invitee Email"
                    size="small"
                    onBlur={handleBlur}
                    variant="outlined"
                    value={values.email}
                    onChange={handleChange}
                    error={Boolean(touched.email && errors.email)}
                    helperText={touched.email && errors.email}
                    fullWidth
                    sx={{
                      mb: 2,
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Mail />
                        </InputAdornment>
                      ),
                    }}
                  />
                  <HoldCheckbox>
                    <Checkbox
                      size="small"
                      name="includeRef"
                      color="primary"
                      checked={values.includeRef}
                      onChange={handleChange}
                      sx={{ mr: 1 }}
                    />
                    <Typography variant="body1">
                      Include referral code?
                    </Typography>
                  </HoldCheckbox>
                  <StyledButton
                    type="submit"
                    fullWidth
                    variant="outlined"
                    disabled={isSubmitting}
                    sx={{ p: 1, fontSize: 14 }}
                    onClick={handleSubmit}
                  >
                    {isSubmitting ? '...' : 'Invite'}
                  </StyledButton>
                  {
                    isSubmitting && <LinearProgress variant="indeterminate" color="primary" />
                  }
                </form>
              )}
            </Formik>
          </FormPaper>
          <div
            style={{
              width: 440,
              marginLeft: 'auto',
              marginRight: 'auto',
              marginTop: 12,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <CopyToClipboard
              text={data?.referral_code || ''}
              onCopy={() => setSnack('Referral code copied to your clipboard ✍️', SNACK_TYPES.SUCCESS)}
            >
              <Button
                variant="contained"
                color="inherit"
                size="small"
                fullWidth
                sx={{ mr: 2 }}
                disabled={isLoading || referralError}
              >
                Copy Your Referral Code
              </Button>
            </CopyToClipboard>
            <Button
              variant="contained"
              color="inherit"
              size="small"
              fullWidth
              sx={{ ml: 2 }}
              disabled={isLoading || referralError}
              onClick={() => setShowQrCode(true)}
            >
              Invite User With QR Code
            </Button>
          </div>
          <DarkerBackdropModal
            open={showQrCode}
            onClose={() => setShowQrCode(false)}
            aria-labelledby="Qr Code Modal"
            aria-describedby="Modal to display referall link via qr code"
          >
            <QrCodeWrapper>
              <div style={{ background: 'white', padding: 10, marginBottom: 12 }}>
                <Typography
                  style={{ color: 'black', fontWeight: '600', width: 340 }}
                  variant="h6"
                  align="center"
                >
                  Scan QR With Phone Camera
                </Typography>
              </div>
              <div style={{
                height: 340,
                width: 340,
                padding: 12,
                background: 'white',
                marginBottom: 12,
              }}
              >
                <QRCode
                  style={{ height: '100%', width: '100%' }}
                  value={data?.link || ''}
                />
              </div>

            </QrCodeWrapper>
          </DarkerBackdropModal>
        </CenterForm>
      </Wrapper>
    </>
  );
};

export default Invite;
