import styled from 'styled-components/macro';
import { CheckCircle } from 'react-feather';

export const BoldSpan = styled.span`
  font-weight: 700;
  border-radius: 8px;
  padding: 1px 2px;
  color: ${props => props.theme.palette.background.bw};
`;

export const AnswerContainer = styled.div`
  height: auto;
  width: 100%;
  flex-grow: 1;
`;

export const CheckImg = styled.img`
  width: 18px;
  height: 18px;
`;

export const CheckImgSmall = styled.img`
  width: 12px;
  height: 12px;
  margin-right: 4px;
`;

export const Option = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 4px;
  margin-bottom: 4px;
  padding: 12px 8px;
  background-color: ${props => {
    if (props.selected === 'yes') {
      return props.theme.palette.option.selected;
    }
    return '#EEEEEE34';
  }};

  border: 1px solid ${props => props.selected === 'yes' ? '#2f9efe2b' : 'transparent'};

  span {
    color: ${props => props.theme.palette.background.bg};
    font-size: 14px;
    font-weight: ${props => props.selected === 'yes' ? 500 : 400};

    @media( max-width: 500px) {
      font-size: 12px;
    }
  }

  &:hover{
    cursor: pointer;
    background-color: ${props => {
    if (props.selected === 'yes') {
      return props.theme.palette.option.selected;
    }
    return props.theme.palette.background.light;
  }};



  }
`;

export const ReviewOption = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2px 4px;
  margin-bottom: 2px;
  border-radius: 2px;
  background-color: ${props => {
    if (props.selected === 'yes') {
      return props.theme.palette.option.selected;
    }
    return props.theme.palette.background.light;
  }};

  border: 1px solid ${props => props.selected === 'yes' ? '#2f9efe2b' : 'transparent'};


  span {
    color: ${props => props.theme.palette.background.bg};
    font-size: 10px;
    font-weight: ${props => props.selected === 'yes' ? 500 : 400};
  };
`;

export const SelectedIcon = styled(CheckCircle)`
  color: ${props => props.theme.palette.loader};
  height: 14px;
  width: 14px;
  margin-right: 8px;
`;

export const FlexDiv = styled.div`
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  display: flex;
  flex-direction: row;
  margin-top: 8px;
  padding: 4px 0px;
`;

export const Center = styled.div`
  padding: 12px 0px 0px 0px;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
`;

export const ButtonHolder = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 24px;
`;

export const Indicator = styled.div`
  height: 13px;
  width: 13px; 
  min-width: 13px;
  min-height: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px; 
  background-color: rgba(51,122,183, .2);
  border: 1px solid rgba(51,122,183, .4);

  div {
    height: 6px;
    width: 6px; 
    border-radius: 50%;
    background-color: rgb(51, 122, 183);
  }
`;

export const ReviewIndicator = styled.div`
  height: 12px;
  width: 12px; 
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px; 
  background-color: rgba(51,122,183, .2);
  border: 1px solid rgba(51,122,183, .4);
  margin-right: 4px;
  
  div {
    height: 5px;
    width: 5px; 
    border-radius: 50%;
    background-color: rgb(51, 122, 183);
  }
`;
