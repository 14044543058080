import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Button } from '@mui/material';
import styled from 'styled-components/macro';
import { ChevronLeft } from 'react-feather';
import {
  AnswerContainer,
  Option,
  FlexDiv,
  Center,
  ButtonHolder,
  Indicator,
} from '../styles';

const ButtonHolderTrueFalse = styled(ButtonHolder)`
  margin-top: auto;
  align-self: flex-end;
`;

const TrueFalseWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-height: 300px;
`;

const TrueFalse = ({
  question,
  dispatch,
  ACTIONS,
  state,
}) => {
  const isLastQuestion = question.order === state.numQuestions - 1;

  return (
    <TrueFalseWrapper>
      <FlexDiv>
        <Typography variant="subtitle2" sx={{ ml: 2 }}>
          True/False:
        </Typography>
        <Typography variant="subtitleSmall" sx={{ ml: 2 }}>
          Select either true or false.
        </Typography>
      </FlexDiv>
      <AnswerContainer>
        <Center>
          <Option
            onClick={() => dispatch({
              type: ACTIONS.SET_SAVED_ANSWER,
              payload: { questionId: question.id, answer: true },
            })}
            selected={question.savedAnswer === true ? 'yes' : 'no'}
          >
            <span>
              True
            </span>
            {
              question.savedAnswer === true
                ? <Indicator><div /></Indicator>
                : <div />
            }
          </Option>
          <Option
            onClick={() => dispatch({
              type: ACTIONS.SET_SAVED_ANSWER,
              payload: { questionId: question.id, answer: false },
            })}
            selected={question.savedAnswer === false ? 'yes' : 'no'}
          >
            <span>
              False
            </span>
            {
              question.savedAnswer === false
                ? <Indicator><div /></Indicator>
                : <div />
            }
          </Option>
        </Center>
      </AnswerContainer>
      <ButtonHolderTrueFalse>
        <Button
          size="small"
          variant="text"
          color="secondary"
          sx={{
            mr: 2,
          }}
          disabled={question.order === 0}
          startIcon={<ChevronLeft style={{ height: 16, width: 16 }} />}
          onClick={() => dispatch({
            type: ACTIONS.DECREASE_ACTIVE_QUESTION,
          })}
        >
          Previous
        </Button>
        <Button
          size="small"
          variant="contained"
          color="primary"
          disabled={isLastQuestion}
          onClick={() => dispatch({
            type: ACTIONS.INCREASE_ACTIVE_QUESTION,
          })}
        >
          Next Question
        </Button>
      </ButtonHolderTrueFalse>
    </TrueFalseWrapper>
  );
};

export default TrueFalse;

TrueFalse.propTypes = {
  question: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  ACTIONS: PropTypes.object.isRequired,
  state: PropTypes.object.isRequired,
};
