import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { TextField, Typography, Button } from '@mui/material';
import styled from 'styled-components/macro';
import { ThumbsDown, ThumbsUp } from 'react-feather';
import API from '../../../../axios/API';

const ThumbsUpGreen = 'rgba(28, 172, 120, .5)';
const ThumbsDownRed = 'rgba(255, 8, 0, .37)';

const Wrapper = styled.div`
  padding: 10px; 
  width: 100%; 
  border-radius: 4px; 
  background-color: #337ab7; 
  margin-top: 12px;
  display: flex; 
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  border: 1px solid darkgrey;
  background-color: #EEEEEE;
`;

const FlexDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  background-color: ${props => props.active === 'yes' ? 'white' : 'transparent'};
  border: 1px solid ${props => props.active === 'yes' ? '#EEEEEE' : 'transparent'};
  border-radius: 4px;
  padding: 2px 8px 2px 4px;
  
  span {
    font-size: 11px;
    margin-left: 4px;
    color: black;
    white-space: nowrap;
  }

  svg {
    height: 22px;
    width: 22px;
  }

  &:hover{
    cursor: pointer;
    border: 1px solid lightgrey;
  }
`;

const SendFeedback = ({
  moduleId,
}) => {
  const [allowFeedback, setAllowFeedback] = useState(false);
  const [liked, setLiked] = useState(null);
  const [feedbackText, setFeedbackText] = useState('');
  const [feedbackSent, setFeedbackSent] = useState(false);

  const getFeedback = async () => {
    try {
      const { data } = await API.get(`/quizzes/feedback/?id=${moduleId}`);
      return setAllowFeedback(data.ask_for_feedback);
    } catch (error) {
      return setAllowFeedback(false);
    }
  };

  useEffect(() => {
    getFeedback();
  }, []);

  const handleSendFeedback = async () => {
    try {
      if (liked === null || feedbackSent) return null;

      const sendObj = {
        id: moduleId,
        liked,
        text: feedbackText || '',
      };
      await API.post('/quizzes/feedback/', sendObj);
      setFeedbackText('');
      return setFeedbackSent(true);
    } catch (error) {
      return setFeedbackSent(true);
    }
  };

  if (!allowFeedback || feedbackSent) {
    return (
      <Wrapper>
        <Typography variant="subtitle2" style={{ color: 'black' }}>
          Feedback
        </Typography>
        <span style={{ fontSize: 10, fontWeight: 300, color: 'darkgrey' }}>
          We have recieved your feedback for this module
        </span>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <div
        style={{
          display: 'flex',
          alignItems: 'flex-start',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        <div>
          <Typography variant="subtitle2" style={{ color: 'black', marginBottom: -4 }}>
            Feedback
          </Typography>
          <span style={{ fontSize: 10, fontWeight: 300, color: 'darkgrey' }}>
            How did you like this module?
          </span>
        </div>
        <div>
          <div
            style={{
              width: 200,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-around',
            }}
          >
            <FlexDiv
              active={liked === false ? 'yes' : 'no'}
              onClick={() => setLiked(false)}
            >
              <ThumbsDown color={(liked === false) ? 'tomato' : ThumbsDownRed} />
              <span style={{ color: (liked === false) ? 'tomato' : 'darkgrey' }}>
                Disliked It
              </span>
            </FlexDiv>
            <FlexDiv
              active={(liked === true) ? 'yes' : 'no'}
              onClick={() => setLiked(true)}
            >
              <ThumbsUp color={liked ? 'mediumseagreen' : ThumbsUpGreen} />
              <span style={{ color: liked ? 'mediumseagreen' : 'darkgrey' }}>
                Liked It
              </span>
            </FlexDiv>
          </div>
        </div>
      </div>
      {
        liked !== null && (
          <>
            <TextField
              sx={{ mt: 2, backgroundColor: 'white' }}
              multiline
              rows={2}
              size="small"
              fullWidth
              disabled={feedbackSent}
              placeholder={feedbackSent ? 'Feedback recieved for this module' : 'Include any comments here...'}
              value={feedbackText}
              onChange={e => setFeedbackText(e.target.value)}
            />
            <Button
              style={{
                fontSize: 12,
                color: feedbackSent ? 'darkgrey' : 'rgb(51,122,183)',
                height: 28,
                marginTop: 4,
                marginLeft: 'auto',
              }}
              variant="text"
              color="primary"
              disabled={feedbackSent}
              onClick={handleSendFeedback}
            >
              Send Feedback
            </Button>
          </>
        )
      }
    </Wrapper>
  );
};

export default SendFeedback;

SendFeedback.propTypes = {
  moduleId: PropTypes.number.isRequired,
};
