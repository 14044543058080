/* eslint-disable */
import React from 'react';
import { 
  Paper,
  Button,
  Typography,
} from '@mui/material'
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components/macro'

import API from '../../../../axios/API';
import useSnack from '../../../../store/snack';

const Wrapper = styled(Paper)`
  width: 340px;
  padding: 20px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const FollowAndStartModal = ({
  followAndStartModuleId, 
  companyId,
  companyName,
}) => {

  const navigate = useNavigate();
  const { snack } = useSnack();
  const { setSnack, SNACK_TYPES } = snack;

  const handleFollowAndStart = async () => {

    try {
      await API.post('/following/', {
        company_id: companyId, 
      })
      setSnack(`You followed ${companyName}`, SNACK_TYPES.SUCCESS)
      return navigate(`/module/${followAndStartModuleId}`)
    } catch (error) {

      return setSnack('There was an issue with this action. Please try again.', SNACK_TYPES.ERROR);
    }


  }

  return (
    <Wrapper>
      <Typography style={{ fontSize: 15, fontWeight: '500'}}>
        Follow this brand to start module!
      </Typography>
      <Typography style={{ color: 'darkgrey'}}>
        You must follow brands before attempting modules
      </Typography>
      <Button 
        variant="contained"
        fullWidth
        color="action"
        size="small"
        style={{ marginTop: 16, color: 'white'}}
        onClick={handleFollowAndStart}
      >
        Follow Brand & Start Module
      </Button>
    </Wrapper>
  )
}

export default FollowAndStartModal;