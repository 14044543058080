/* eslint-disable */

import React, { useState } from 'react';
import { 
  Paper,
  Button,
  Typography,
} from '@mui/material'
import styled from 'styled-components/macro'

import API from '../../../../axios/API';
import useSnack from '../../../../store/snack';

const Wrapper = styled(Paper)`
  width: 340px;
  padding: 20px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
const ConfirmLeaveTeamModal = ({
  id, 
  name,
  setOpenLeaveTeam,
  refetch,
}) => {

  const [isLeaving, setIsLeaving] = useState(false);
  const { snack } = useSnack();
  const { setSnack, SNACK_TYPES } = snack;

  const handleLeaveTeam = async () => {
    setIsLeaving(true);
    
    try {
      await API.delete(`/v2/employee-team/${id}/`);
      await refetch();
      setIsLeaving(false);
      setSnack(`Successfully left team ${name}`, SNACK_TYPES.SUCCESS);
      return setOpenLeaveTeam(false);
    } catch (error) {
      setIsLeaving(false);
      return setSnack('Failed to leave team, please try again later', SNACK_TYPES.ERROR);
    }
  };

  return (
    <Wrapper>
      <Typography style={{ fontSize: 15, fontWeight: '500'}}>
        Confirm Leave Team
      </Typography>
      <Typography style={{ color: 'darkgrey'}}>
        You will lose your progress with {name}
      </Typography>
      <Button 
        variant="contained"
        fullWidth
        color="error"
        size="small"
        disabled={isLeaving}
        style={{ marginTop: 16, color: 'white'}}
        onClick={handleLeaveTeam}
      >
        Confirm Leave Team
      </Button>
    </Wrapper>
  )
}

export default ConfirmLeaveTeamModal