/* eslint-disable */
import { useQuery } from "react-query";
import API from "../axios/API";

const getCompanies = async () => {
  const { data } = await API.get("following/explore/");
  return data;
};

const useAvailToFollowCompanies = () => useQuery("availToFollow", getCompanies);

export default useAvailToFollowCompanies;
