import React, { useReducer } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { Typography, Paper } from '@mui/material';
import { Tag, Briefcase, MapPin } from 'react-feather';
import SelectCompany from './SelectCompany';
import SelectRoles from './SelectRoles';
import SelectLocation from './SelectLocation';
import Confirm from './Confirm';

const AddTeamPaper = styled(Paper)`
  width: min(80vw, 1200px);
  min-width: 500px;
  min-height: 500px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  padding: 20px;
  border: 2px solid black;
`;

const ThirdDiv = styled.div`
  flex: .3333333;
  height: 100%;
  display: flex;
  align-items: center; 
  justify-content: flex-start;
  
  span{
    color: ${props => props.theme.palette.secondary.dark};
    font-size: 13px;
  }

  svg{
    color: ${props => {
    if (props.selected === 'yes') {
      // return props.theme.palette.highlight.dark;
      return 'black';
    }
    return 'darkgrey';
  }};
    height: 16px;
    width: 16px;
    margin: 0px 8px;
  }
`;

const AddTeamHeader = styled.div`
  width: 100%;
  height: 40px;
  border: 1px solid ${props => props.theme.palette.background.bw};
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  display: flex;
  
  > div:not(:first-child) {
    border-left: 1px solid ${props => props.theme.palette.background.bw};
  }
`;

const AddTeamBody = styled.div`
  width: 100%;
  display: flex;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  > div:not(:first-child) {
    border-left: 1px solid ${props => props.theme.palette.background.bw};
  }
`;

const initialState = {
  selectedCompany: null,
  selectedLocation: null,
  selectedRoles: null,
  index: 0,
};

const ACTIONS = {
  ADD_SELECTED_COMPANY: 'addSelectedCompany',
  ADD_SELECTED_LOCATION: 'addSelectedLocation',
  ADD_SELECTED_ROLES: 'addSelectedRoles',
  SET_INDEX: 'setIndex',
  REMOVE_SELECTED_ROLES: 'removeSelectedRoles',
  REMOVE_SELECTED_LOCATION: 'removeSelectedLocation',
  REMOVE_SELECTED_COMPANY: 'removeSelectedCompany',
  RESET: 'reset',
};

const reducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.SET_INDEX:
      return {
        ...state,
        index: action.payload,
      };
    case ACTIONS.ADD_SELECTED_COMPANY:
      return {
        ...state,
        index: 1,
        selectedCompany: action.payload,
        selectedLocation: null,
        selectedRoles: null,

      };
    case ACTIONS.ADD_SELECTED_LOCATION:
      return {
        ...state,
        index: 2,
        selectedLocation: action.payload,
        selectedRoles: null,
      };
    case ACTIONS.ADD_SELECTED_ROLES:
      return {
        ...state,
        selectedRoles: action.payload,
        index: 3,
      };
    case ACTIONS.REMOVE_SELECTED_ROLES:
      return {
        ...state,
        index: 2,
        selectedRoles: null,
      };
    case ACTIONS.REMOVE_SELECTED_LOCATION:
      return {
        ...state,
        index: 1,
        selectedLocation: null,
      };
    case ACTIONS.REMOVE_SELECTED_COMPANY:
      return {
        ...state,
        index: 0,
        selectedCompany: null,
      };
    case ACTIONS.RESET:
      return initialState;
    default:
      return {
        ...state,
      };
  }
};

const AddTeam = ({
  setAddTeamModalOpen,
}) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const {
    selectedCompany,
    selectedLocation,
    selectedRoles,
    index,
  } = state;

  const showSelectedRoles = (rolesArr) => {
    if (rolesArr.length === 1) {
      return rolesArr[0].name;
    }
    return `${rolesArr[0].name} + ${rolesArr.length - 1} others`;
  };

  return (
    <AddTeamPaper>
      <AddTeamHeader>
        <ThirdDiv selected={selectedCompany ? 'yes' : 'no'}>
          <Briefcase />
          {
            selectedCompany ? <Typography variant="subtitle1">{selectedCompany.name}</Typography> : <span>None Selected</span>
          }
        </ThirdDiv>
        <ThirdDiv selected={selectedLocation ? 'yes' : 'no'}>
          <MapPin />
          {
            selectedLocation ? <Typography variant="subtitle1">{selectedLocation.name}</Typography> : <span>None Selected</span>
          }
        </ThirdDiv>
        <ThirdDiv selected={selectedRoles ? 'yes' : 'no'}>
          <Tag />
          {
            selectedRoles ? <Typography variant="subtitle1">{showSelectedRoles(selectedRoles)}</Typography> : <span>None Selected</span>
          }
        </ThirdDiv>
      </AddTeamHeader>
      <AddTeamBody>
        {
          index === 0 && (
            <SelectCompany state={state} dispatch={dispatch} ACTIONS={ACTIONS} />
          )
        }
        {
          index === 1 && (
            <SelectLocation state={state} dispatch={dispatch} ACTIONS={ACTIONS} />
          )
        }
        {
          index === 2 && (
            <SelectRoles state={state} dispatch={dispatch} ACTIONS={ACTIONS} />
          )
        }
        {
          index === 3 && (
            <Confirm
              state={state}
              dispatch={dispatch}
              ACTIONS={ACTIONS}
              setAddTeamModalOpen={setAddTeamModalOpen}
            />
          )
        }
      </AddTeamBody>
    </AddTeamPaper>
  );
};

export default AddTeam;

AddTeam.propTypes = {
  setAddTeamModalOpen: PropTypes.func.isRequired,
};
