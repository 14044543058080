/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import styled from 'styled-components/macro';
import TrueFalse from './TrueFalse';
import MultipleChoice from './MultipleChoice';
import SelectAll from './SelectAll';
import GREENCHECK from '../../assets/check.png';
import REDX from '../../assets/redx.png';

const Wrapper = styled.div`
  width: 100%;
  overflow: scroll;
`;

const HoldAnswers = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

const ShowAnswer = styled.div`
  border-radius: 0px;
  padding: 6px 4px;
  margin-bottom: 16px;
  border: 1px solid #EEEEEE;
  box-shadow: ${props => props.theme.palette.boxShadow.main};
  border-radius: 4px;
`;

const FlexDiv = styled.div`
  width: 100%;
  display: flex;
  border-radius: 2px;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.theme.palette.background.contrast};
  padding: 2px 4px;
`;

const QuestionTypeSpan = styled.span`
  color: ${props => props.theme.palette.background.bw};
  font-size: 11px;
  flex: .05;
  margin-left: auto;
  align-self: center;
`;

const Indicator = styled.div`
  height: 10px;
  width: 10px; 
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px; 
  background-color: rgba(51,122,183, .2);
  border: 1px solid rgba(51,122,183, .4);
  
  div {
    height: 4px;
    width: 4px; 
    border-radius: 50%;
    background-color: rgb(51, 122, 183);
  }
`;

const IndicatorCorrect = styled(Indicator)`
  background-color: rgba(146, 255, 146, 0.18);
  border: 1px solid mediumseagreen;

  div {
    border-radius: 50%;
    background-color: mediumseagreen;
  }
`;

const ShowAnswerType = ({
  question,
  userAnswer,
}) => {
  if (question.type === 'tf') {
    return <TrueFalse question={question} userAnswer={userAnswer} />;
  }

  if (question.type === 'mc') {
    return <MultipleChoice question={question} userAnswer={userAnswer} />;
  }

  return (
    <SelectAll question={question} userAnswer={userAnswer} />
  );
};

const getQuestionType = type => {
  if (type === 'tf') return 'T/F';
  if (type === 'mc') return 'M/C';
  return 'S/A';
};

const getCorrectIcon = (question, userAnswer) => {
  let isAnswerCorrect = false;
  try {
    if (question.type === 'tf') {
      const parsedAnswer = JSON.parse(userAnswer).toLowerCase();
      isAnswerCorrect = parsedAnswer === question.correct_answer;
    }

    if (question.type === 'mc') {
      const parsedAnswerMC = JSON.parse(userAnswer).toLowerCase();
      isAnswerCorrect = parsedAnswerMC === question.correct_answer;
    }

    if (question.type === 'sa') {
      const parsedAnswerSA = JSON.parse(userAnswer).join(', ');
      const providedAnswer = question.correct_answer.join(', ');
      isAnswerCorrect = parsedAnswerSA === providedAnswer;
    }
  } catch (error) {
    return null;
  }

  return (
    <img
      src={isAnswerCorrect ? GREENCHECK : REDX}
      alt="signifier"
      style={{
        position: 'absolute',
        top: -3,
        right: -3,
        height: isAnswerCorrect ? 10 : 12,
        width: isAnswerCorrect ? 10 : 12,
        objectFit: 'contain',
      }}
    />
  );
};

const ShowAnswers = ({
  questions,
  userAnswers,
}) => (
  <Wrapper>
    <div
      style={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        marginBottom: 8,
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: 10,
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: 4,
          }}
        >
          <Indicator>
            <div />
          </Indicator>
          <span style={{ fontSize: 10, color: 'darkgrey', fontWeight: 300 }}>
            Your Answer
          </span>
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: 4,
          }}
        >
          <IndicatorCorrect>
            <div />
          </IndicatorCorrect>
          <span style={{ fontSize: 10, color: 'darkgrey', fontWeight: 300 }}>
            Correct Answer
          </span>
        </div>

      </div>
    </div>
    <HoldAnswers>
      {
        questions.map((question, index) => (
          <ShowAnswer key={question.id}>
            <FlexDiv>
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  alignItems: 'flex-start',
                  justifyContent: 'center',
                }}
              >
                <div
                  style={{
                    position: 'relative',
                    minWidth: 24,
                    minHeight: 24,
                    marginRight: 8,
                    height: 24,
                    width: 24,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    background: 'white',
                    border: '1px solid black',
                    borderRadius: 4,
                  }}
                >
                  {getCorrectIcon(question, userAnswers[index])}
                  <span
                    style={{ fontWeight: 500 }}
                  >
                    {question.order + 1}
                  </span>
                </div>
                <Typography
                  variant="subtitle2"
                  sx={{ lineHeight: 1.3, alignSelf: 'center', textAlign: 'left', paddingRight: 8 }}
                >
                  {question.question}
                </Typography>
                <QuestionTypeSpan>
                  {getQuestionType(question.type)}
                </QuestionTypeSpan>
              </div>
            </FlexDiv>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '2px', marginTop: 4, marginLeft: 4}}>
              <Typography  variant="subtitleSmall" color="secondary" style={{ marginRight: 'auto'}}>
                Options
              </Typography>
            </div>
            <ShowAnswerType question={question} userAnswer={userAnswers[index]} />
          </ShowAnswer>
        ))
      }
    </HoldAnswers>
  </Wrapper>
);

export default ShowAnswers;

ShowAnswers.propTypes = {
  questions: PropTypes.array.isRequired,
  userAnswers: PropTypes.array.isRequired,
};

ShowAnswerType.propTypes = {
  question: PropTypes.object.isRequired,
  userAnswer: PropTypes.object.isRequired,
};
