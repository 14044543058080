/* eslint-disable */
import { useContext, useMemo } from 'react';
import { ThemeProvider, CssBaseline } from '@mui/material';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import { Routes, Route } from 'react-router-dom';
import SignIn from './pages/public/SignIn';
import Register from './pages/public/Register';
import Auth from './layouts/Auth';
import Dashboard from './layouts/Dashboard';
import GlobalSnack from './components/GlobalSnack';
import Learn from './pages/private/Learn';
import Review from './pages/private/Review';
import Contact from './pages/private/Contact';
import Invite from './pages/private/Invite';
import Explore from './pages/private/Explore';
import Module from './pages/private/Module';
import getTheme from './theme';
import { GlobalState } from './store/global';
import ProtectedRoute from './routes/ProtectedRoute';
import Profile from './pages/private/Profile';
import NoMatch from './pages/public/NoMatch';
import Leaderboards from './pages/private/Leaderboards';
import ForgotPassword from './pages/public/ForgotPassword';
import BrandOverview from './pages/private/Explore/BrandOverview';
import AcceptInvite from './pages/public/AcceptInvite';
import AcceptReferral from './pages/public/AcceptReferral';
// import DeleteAccount from './pages/public/DeleteAccount';
import DeleteAccount from './pages/private/DeleteAccountWithAuth';

function App() {
  const { themeLight } = useContext(GlobalState);
  const myTheme = useMemo(() => getTheme(themeLight), [themeLight]);

  return (
    <div className="App">
      <StyledThemeProvider theme={myTheme}>
        <ThemeProvider theme={myTheme}>
          <CssBaseline />
          <Routes>
            <Route element={<Auth />}>
              <Route
                path="signin/"
                element={<SignIn />}
              />
              <Route
                path="register/"
                element={<Register />}
              />
              <Route
                path="forgot-password/"
                element={<ForgotPassword />}
              />
              <Route
                path="invitation/:token"
                element={<AcceptInvite />}
              />
              <Route
                path="referral/:token"
                element={<AcceptReferral />}
              />
            </Route>
            <Route element={<Dashboard />}>
              <Route
                path="/"
                element={
                  <ProtectedRoute>
                    <Learn />
                  </ProtectedRoute>
                }
              />
              <Route
                path="profile/"
                element={
                  <ProtectedRoute>
                    <Profile />
                  </ProtectedRoute>
                }
              />
              <Route
                path="module/:id/"
                element={
                  <ProtectedRoute>
                    <Module />
                  </ProtectedRoute>
                }
              />
              <Route
                path="review/"
                element={
                  <ProtectedRoute>
                    <Review />
                  </ProtectedRoute>
                }
              />
              <Route
                path="explore/"
                element={
                  <ProtectedRoute>
                    <Explore />
                  </ProtectedRoute>
                }
              />
              <Route
                path="explore/:id"
                element={
                  <ProtectedRoute>
                    <BrandOverview />
                  </ProtectedRoute>
                }
              />
              <Route
                path="invite/"
                element={
                  <ProtectedRoute>
                    <Invite />
                  </ProtectedRoute>
                }
              />
              <Route
                path="contact/"
                element={
                  <ProtectedRoute>
                    <Contact />
                  </ProtectedRoute>
                }
              />
              <Route
                path="leaderboards/"
                element={
                  <ProtectedRoute>
                    <Leaderboards />
                  </ProtectedRoute>
                }
              />
              <Route
                path="delete-personal-data/"
                element={
                  <ProtectedRoute>
                    <DeleteAccount />
                  </ProtectedRoute>
                }
              />
            </Route>
            <Route
              path="*"
              element={<NoMatch />}
            />
          </Routes>
          <GlobalSnack />
        </ThemeProvider>
      </StyledThemeProvider>
    </div>
  );
}

export default App;
