/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { Lock } from 'react-feather';
import { Typography } from '@mui/material';
import styled from 'styled-components/macro';
import ShowLevelProgress from './ShowLevelProgress';
import CHECK from '../../../../../assets/check.png';

const SPIFFY_BLUE = 'rgb(51, 122, 183)';

const LevelWrapper = styled.div`
  width: 100%;
  border-radius: 4px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px 8px;
  margin-bottom: 4px;
`;

const LevelResultIndicator = styled.div`
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #EEEEEE;
  border-radius: 4px;
  position: relative;
  

  span {
    font-size: 20px;
  }

  svg {
    height: 20px;
    width: 20px;
    color: white;
  }
`;

const LevelWrapperInProgress = styled.div`
  
  background-color: white;
  border-radius: 4px;
  border: 1px solid slategray;
  width: 100%;
  margin-top: 4px;
  margin-bottom: 8px;
  box-shadow: 0px 0px 4px 2px lightgray;
  padding: 4px 8px;
`;

const AbosluteCheck = styled.img`
  position: absolute;
  top: -4px;
  right: -4px;
  height: 11px;
  width: 11px;
  object-fit: contain;
  z-index: 1001;
`;

const ShowLevel = ({
  level,
  progressData,
  isInProgress = false,
  allLevelsComplete,
}) => {
  if (isInProgress && !allLevelsComplete) {
    return (
      <LevelWrapperInProgress>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <span style={{ color: 'slategrey', fontSize: 11, fontWeight: 500 }}>
            Level: {level.level}
          </span>
          <span
            style={{
              // fontStyle: 'italic',
              fontSize: 10,
              fontWeight: 400,
              color: 'slategrey',
            }}
          >
           *In Progress
          </span>
        </div>
        <Typography
          style={{
            fontSize: 14,
            fontWeight: 500,
            textAlign: 'center',
            maxWidth: '94%',
            marginBottom: 4,
          }}
        >
          {level.descriptions.requirements_description.english}
        </Typography>
        <ShowLevelProgress level={level} progressData={progressData} />
      </LevelWrapperInProgress>
    );
  }

  return (
    <LevelWrapper>
      <div
        style={{
          display: 'flex',
          alignItems: 'flex-start',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <div>
          <span style={{ fontSize: 10, color: 'lightgray' }}>
            Level: {level.level}
          </span>
          {
            level.achieved && (
              <span style={{ fontSize: 9, color: 'darkgrey', marginLeft: 4 }}>
                (Achieved: {new Date(level.achievement_date).toDateString()})
              </span>
            )
          }
        </div>
        {
          level.achieved ? (
            <p style={{ fontSize: 11, fontWeight: 500 }}>
              {level.descriptions.short_description.english}
            </p>
          ) : (
            <div style={{ height: 13, width: 180, background: '#EEE' }} />
          )
        }
      </div>
      <LevelResultIndicator style={{ backgroundColor: level.achieved ? 'white' : '#EEEEEE' }}>
        {
          level.achieved && (
            <AbosluteCheck src={CHECK} />
          )
        }
        {level.achieved ? (
          <span>
            🏆
          </span>
        ) : (
          <Lock />
        )}
      </LevelResultIndicator>
    </LevelWrapper>
  );
};

export default ShowLevel;

ShowLevel.propTypes = {
  level: PropTypes.object.isRequired,
  progressData: PropTypes.object.isRequired,
  isInProgress: PropTypes.bool,
};

ShowLevel.defaultProps = {
  isInProgress: false,
};
