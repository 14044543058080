import React, { useState, useRef } from 'react';
import styled from 'styled-components/macro';
import {
  Paper,
  LinearProgress,
  InputAdornment,
  Typography,
  Button,
} from '@mui/material';
import {
  Mail,
  MessageSquare,
  MinusCircle,
  PlusCircle,
} from 'react-feather';
import { Formik } from 'formik';
import * as Yup from 'yup';
import PageHeader from '../../../components/Reuseable/PageHeader';
import API from '../../../axios/API';
import useSnack from '../../../store/snack';
import { CustomTextField, StyledButton } from '../../../styles/forms';

const WEB_APP_DATA_STRING = ' | Sent From Web App';

const Wrapper = styled.div`
  padding: 20px;
`;

const FormPaper = styled(Paper)`
  background: ${props => props.theme.palette.background.paper};
  padding: 20px;
  width: 440px;

  @media(max-width: 461px) {
    padding: 20px 10px;
    width: 100%;
  }
`;

const CenterForm = styled.div`
  width: 100%;
  height: 70vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const UploadImageContainer = styled.div`
  width: 100%; 
  display: flex; 
  align-items: center; 
  justify-content: center;
  padding: 2px 0px; 
  margin-bottom: 4px;
`;

const Contact = () => {
  const { snack } = useSnack();
  const { setSnack, SNACK_TYPES } = snack;
  const [uploadImage, setUploadImage] = useState(null);
  const inputImageRef = useRef();

  const handleFormSubmit = async (values, { setSubmitting, resetForm }) => {
    setSubmitting(true);
    try {
      const formData = new FormData();
      formData.append('email', values.email);
      formData.append('content', values.message.concat(WEB_APP_DATA_STRING));
      formData.append('platform', 1); // endpoint currently fails without this

      if (uploadImage) {
        formData.append('image', uploadImage);
      }

      await API.post('/contact/', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      setSubmitting(false);

      if (uploadImage) {
        setUploadImage(null);
      }

      resetForm();
      return setSnack('Message sent. We will get back to you as soon as we can', SNACK_TYPES.SUCCESS);
    } catch (error) {
      setSubmitting(false);
      resetForm();
      return setSnack('Failed to send your message, please try again later', SNACK_TYPES.ERROR);
    }
  };

  const InitialValues = {
    email: '',
    message: '',
  };

  const ValidationSchema = Yup.object().shape({
    email: Yup.string()
      .email('Must be a valid email')
      .required('Email is required'),
    message: Yup.string()
      .required('Message is required')
      .min(3, '3 characters minimum'),
  });

  const handleImageChange = e => {
    const file = e.target.files[0];
    const { type: fileType } = file;
    // if file type is not imagee.. abort
    if (!fileType.includes('image')) {
      return setSnack('Only image files are accepted', SNACK_TYPES.WARNING);
    }
    return setUploadImage(file);
  };

  return (
    <>
      <PageHeader text="Contact Us" />
      <Wrapper>
        <CenterForm>
          <FormPaper>
            <Formik
              validateOnBlur
              initialValues={InitialValues}
              validationSchema={ValidationSchema}
              onSubmit={handleFormSubmit}
            >
              {({
                errors,
                values,
                touched,
                handleChange,
                handleBlur,
                isSubmitting,
                handleSubmit,
              }) => (
                <form
                  noValidate
                  onSubmit={handleSubmit}
                >
                  <Typography variant="h2">
                    Contact Us
                  </Typography>
                  <Typography variant="body1" sx={{ mb: 6 }}>
                    Feel free to ask any questions here, we will get back to you.
                  </Typography>
                  <CustomTextField
                    type="email"
                    name="email"
                    label="Email"
                    size="small"
                    value={values.email}
                    onBlur={handleBlur}
                    variant="outlined"
                    onChange={handleChange}
                    error={Boolean(touched.email && errors.email)}
                    helperText={touched.email && errors.email}
                    fullWidth
                    sx={{
                      mb: 5,
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Mail />
                        </InputAdornment>
                      ),
                    }}
                  />
                  <CustomTextField
                    name="message"
                    onChange={handleChange}
                    value={values.message}
                    multiline
                    rows={4}
                    fullWidth
                    error={Boolean(touched.message && errors.message)}
                    onBlur={handleBlur}
                    helperText={touched.message && errors.message}
                    label="Your Message"
                    size="small"
                    sx={{
                      mb: 4,
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <MessageSquare />
                        </InputAdornment>
                      ),
                    }}
                  />
                  {
                    uploadImage ? (
                      <UploadImageContainer>
                        <MinusCircle
                          color="tomato"
                          height={16}
                          width={16}
                          style={{ marginRight: 6, cursor: 'pointer' }}
                          onClick={() => setUploadImage(null)}
                        />
                        <img
                          style={{
                            width: 50,
                            height: 30,
                            borderRadius: 4,
                            objectFit: 'contain',
                          }}
                          alt="not found"
                          src={URL.createObjectURL(uploadImage)}
                        />
                      </UploadImageContainer>
                    ) : (
                      <Button
                        fullWidth
                        size="small"
                        color="secondary"
                        variant="text"
                        sx={{ mb: 2 }}
                        onClick={() => inputImageRef.current.click()}
                        startIcon={
                          <PlusCircle color="dodgerblue" height={16} width={16} />
                        }
                      >
                        Attach Image
                      </Button>
                    )
                  }
                  <input
                    type="file"
                    ref={inputImageRef}
                    style={{ display: 'none' }}
                    onChange={handleImageChange}
                  />
                  <StyledButton
                    type="submit"
                    fullWidth
                    variant="outlined"
                    disabled={isSubmitting}
                    sx={{ p: 1, fontSize: 14 }}
                    onClick={handleSubmit}
                  >
                    {isSubmitting ? '...' : 'Submit'}
                  </StyledButton>
                  {
                    isSubmitting && <LinearProgress variant="indeterminate" />
                  }
                </form>
              )}
            </Formik>
          </FormPaper>
        </CenterForm>
      </Wrapper>
    </>
  );
};

export default Contact;
