import styled from 'styled-components/macro';
import { Button } from '@mui/material';

export const ScrollBrands = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  overflow-x: scroll;
  padding: 20px 20px 20px 6px;
  margin-bottom: 24px;
`;

export const FakeTab = styled.div`

  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px 12px;
  z-index: 2;

  span {
    font-size: 14px;
    font-weight: 500;
    color: ${props => props.theme.palette.background.bw};
  }


  svg{
    height: 16px;
    width: 16px;
    color: ${props => props.theme.palette.primary.main};
  }
`;

export const BrandsContainer = styled.div`
  width: 100%;

`;

export const CenterWrapper = styled.div`
  height: 280px;
  width: 100%;
`;

export const FlexDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const FlexBetween = styled.div`
  width: 100%;  
  display: flex;
  z-index: 2
  align-items: center;
  justify-content: space-between;
  border-radius: 4px;
  padding: 4px;
  background-color: ${props => props.theme.palette.background.contrast};
`;

export const ClearBtn = styled(Button)`
  color: ${props => props.theme.palette.background.bw};

  &:disabled {
    color: ${props => props.theme.palette.grey[700]} !important;
  }

  @media(max-width: 500px) {
    display: none;
  }
`;
