import React from 'react';
import styled from 'styled-components/macro';
import { Typography, Avatar } from '@mui/material';
import useUserProfile from '../../../reactQueryHooks/useUserProfile';

const CustomAvatar = styled(Avatar)`
  height: 22px;
  width: 22px;
  font-size: 11px;

  margin-right: 4px;
  transform: translateY(-3px);
`;

const FlexStart = styled.div`
  display: flex;
  align-items: center: 
  justify-content: center;
  width: 100%;
  margin-bottom: -2px;
  margin-left: -2px;
`;

const FlexDiv = styled.div`
  width: 100%;
  display: flex;

  p {
    font-size: 8px;

    strong {
      font-size: 10px;
    }
  }

  div {
    flex: .5;
  }
`;

const MiniProfile = () => {
  const { data, error, isLoading } = useUserProfile();

  if (isLoading) {
    return (
      <Typography variant="subtitle2">
        Getting data..
      </Typography>
    );
  }

  if (error) {
    return (

      <Typography variant="subtitle2">
        Error loading component
      </Typography>
    );
  }

  return (
    <>
      <FlexStart>
        <CustomAvatar src={data.photo} />
        <Typography variant="subtitle2" style={{ marginTop: 0 }}>
          {data.name}
        </Typography>
      </FlexStart>
      <FlexDiv>
        <div>
          <p>Spiffy Score: <strong>{data.spiffy_score}</strong></p>
        </div>
        <div>
          <p>Completions: <strong>{data.completions}</strong></p>
        </div>
      </FlexDiv>
      <FlexDiv>
        <div>
          <p>Submissons: <strong>{data.submissions}</strong></p>
        </div>
        <div>
          <p>Pass Rate: <strong>{data.pass_rate}%</strong></p>
        </div>
      </FlexDiv>
    </>
  );
};

export default MiniProfile;
