/* eslint-disable */
/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components/macro';
import {
  Button,
  CircularProgress,
  Typography,
  Paper,
  Collapse,
  Fade,
  Grow,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Briefcase, Clock, Target } from 'react-feather';
import { useQueryClient } from 'react-query';
import { formatDistanceStrict } from 'date-fns';
import ShowAnswers from './ShowAnswers';
import API from '../../../../axios/API';
import SendFeedback from './SendFeedback';
import { GlobalState } from '../../../../store/global';
import GREENCHECK from '../../../../assets/check.png';
import REDX from '../../../../assets/redx.png';

const SPIFFY_BLUE = 'rgb(51, 122, 183)';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const CustomCircularProgress = styled(CircularProgress)`

  position: absolute;
  top: 24px;
  z-index: 1;
`;

const CustomCircularProgressGrey = styled(CustomCircularProgress)`

  z-index: 0;

  .MuiCircularProgress-circleDeterminate {
    color: lightgray;
    z-index: 0;
  }
`;

const PrimaryActionButton = styled(Button)`
  background-color: mediumseagreen;
  color: white;

  &:hover {
    background-color: seagreen;
  }
`;

const ResultsWrapper = styled(Paper)`
  padding: 20px;
  width: 700px;
  max-width: 100%;
  min-height: 560px;
  display: flex;
  margin: 0px auto 80px auto;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;

  @media (max-width: 701px) {
    width: 96vw;
    max-width: 100%;
    margin-top: 12px;
    padding: 10px;
  }
`;

const ButtonHolder = styled.div`
  width: 100%;
  margin-top: 12px;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @media(max-width: 400px) {
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    gap: 4px;
  }
`;

const Bubble = styled.div`
  height: 40px;
  width: 40px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: white;
  margin-right: 8px;
  border: 1px solid black;

  span {
    font-weight: 400;
    color: black;
    font-size: 18px;
  }
`;

const AbsoluteIcon = styled.div`
  position: absolute;
  top: -4px;
  right: -4px;
`;

const ResultSpan = styled.span`
  animation: ${fadeIn} 500ms ease-in-out;
  font-weight: 700;
  line-height: 1.2;
`;

const KeyStatWrapper = styled.div`
  background: white; 
  border: 1px solid darkgrey;
  padding: 2px;
  border-radius: 4px;
  width: 200px;
  display: flex;
  align-items: center; 
  justify-content: flex-start;
`;

const IconDiv = styled.div`
  height: 40px;
  width: 40px;
  background-color: #EEEEEE;
  border: 1px solid lightgray;

  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;

  svg {
    color: rgb(51, 122, 183);
    height: 18px;
    width: 18px;
  }
`;

const QuestionBreakdownWrapper = styled.div`
  padding: 10px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #EEEEEE;
  border-radius: 4px;
  margin-top: 10px;
  border: 1px solid darkgrey;
  
  @media(max-width: 501px) {
    flex-direction: column; 
    align-items: center;
    justify-content: center;
  }
`;

const CircularProgressBox = styled.div`
  position: relative;
  height: 100px;
  width: 100px; 
  min-width: 100px;
  display: flex; 
  flex-direction: column; 
  align-items: center;
  justify-content: flex-start; 
  border-radius: 4px; 
  background-color: white;
  border: 1px solid black;
  
  @media(max-width: 501px) {
    order: 1;
    margin-bottom: 12px;
  }
`;

const HoldQuestionBreakdown = styled.div`
  height: 100%,
  flex-grow: 1,
  display: flex;
  alignItems: flex-start;
  justify-content: center;
  flex-direction: column;
  min-width: 240px;

  @media(max-width: 501px) {
    order: 2;
    min-width: 100px;
  }

  @media(max-width: 360px) {
    width: 100%;
  }
`;

const ModuleImage = styled.img`
  height: 140px;
  width: 140px;  
  border-radius: 6px; 
  object-fit: contain; 
  background-color: white;
`;

const DisplayAnswersButton = styled(Button)`
  border-radius: 4px;
  height: 18px;
  margin-left: 0px;
  display: flex; 
  align-items: center; 
  justify-content: center; 
  background-color: transparent; 
  font-size: 10px;
  color: rgb(51, 122, 183);
  text-decoration: underline;
`;

const InnerWrapper = styled.div`
  width: 98%; 
  min-height: 240px;
  border-radius: 4px;
  padding: 10px;
`;

const KeyStatInner = styled.div`
  padding: 2px 8px; 
  display: flex; 
  align-items: flex-start; 
  justify-content: 'center; 
  flex-direction: column;
`;

const Top = styled.div`
  display: flex; 
  align-items: center;
  justify-content: center; 
  flex-direction: column; 
  text-align: center;
`;

const ContainKeyStats = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 12px;
  flex-wrap: wrap;
  gap: 4px;

  > div {
    flex-grow: 1;
  }

`;

const Results = ({
  state,
  module,
  dispatch,
  ACTIONS,
}) => {
  const navigate = useNavigate();
  const {
    passed,
    numQuestions,
    startTime,
    moduleId,
    questionsCorrect,
    questions,
  } = state;
  const [isSubmitting, setIsSubmitting] = useState(true);
  const [completionTime, setCompletionTime] = useState(null);
  const [showFullAnswers, setShowFullAnswers] = useState(false);
  const [isDuplicateSub, setIsDuplicateSub] = useState(false);
  const queryClient = useQueryClient();

  const percent = isSubmitting ? 0 : Math.floor((questionsCorrect / numQuestions) * 100);

  const {
    triggerBadgeDataUpdate,
  } = useContext(GlobalState);

  const handleSubmit = async () => {
    const finalAnswers = state.questions.map(q => {
      if (q.type === 'sa') {
        const sorted = q.savedAnswer.sort();
        return String(sorted);
      }
      return String(q.savedAnswer);
    });
    const timeToComplete = formatDistanceStrict(new Date(), startTime);
    setCompletionTime(timeToComplete);

    try {
      const { data } = await API.post('v2/quizzes/submit/', {
        quiz_submissions: [
          {
            quiz_id: moduleId,
            user_answers: JSON.stringify(finalAnswers),
            answer_times: JSON.stringify(Array(finalAnswers.length).fill(10)),
            date_completed_in_ms: new Date().getTime(),
            status: 'Created',
            num_retries: 0,
            amount_earned: 0,
            video_watch_percent: 0,
            num_answers_changed: 1,
            passed,
            last_retry_outcome_unknown: false,
          },
        ],
      });

      if (data && data[0] && data[0]?.status_code === 400 && data[0]?.data === 'Duplicate Submission') {
        setIsDuplicateSub(true);
      }

      if (passed) {
        queryClient.invalidateQueries('availQuizzes', { refetchInactive: true });
      }
      queryClient.invalidateQueries('last10Submissions', { refetchInactive: true });
      setIsSubmitting(false);
      return triggerBadgeDataUpdate();
    } catch (error) {
      return setIsSubmitting(false);
    }
  };

  useEffect(() => {
    handleSubmit();
  }, []);

  if (isSubmitting) {
    return (
      <ResultsWrapper>
        <Typography variant="subtitle1">
          Checking your results...
        </Typography>
        <CircularProgress size={30} />
      </ResultsWrapper>
    );
  }

  if (isDuplicateSub) {
    return (
      <ResultsWrapper>
        <div style={{ height: 540, width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
          <div style={{ height: 200, width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', backgroundColor: '#EEEEEE'}}> 
            <Typography color="error" style={{ fontSize: 18, fontWeight: '600'}}>
              This submission was a duplicate
            </Typography>
            <Typography variant="body1" style={{ color: 'darkgrey'}}>
              You have previously completed this module in the past.
            </Typography>
            <Typography variant="body1" style={{ color: 'darkgrey'}}>
              This submission will not count towards your stats.
            </Typography>
            <Button 
              sx={{ mt: 6}} 
              variant='contained' 
              color="secondary" 
              size="small"
              onClick={() => navigate('/')}
            >
              Back To Feed
            </Button>
          </div>

        </div>
      </ResultsWrapper>
    )
  }

  return (
    <ResultsWrapper>
      <InnerWrapper>
        <Fade
          in
          timeout={{
            appear: 600,
          }}
        >
          <Top>
            <ModuleImage src={module.image} />
            <div style={{
              textAlign: 'center',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              padding: 10,
            }}
            >
              <span style={{ fontSize: 17, fontWeight: 500, textAlign: 'center' }}>
                {module.name}
              </span>
              <ResultSpan style={{
                color: passed ? 'mediumseagreen' : 'tomato',
                fontFamily: 'Raleway',
                fontSize: 16,
                fontWeight: 800,
                textAlign: 'center',
              }}
              >
                {passed ? 'Module Passed' : 'Module Failed'}
              </ResultSpan>
            </div>
          </Top>

        </Fade>
        <Fade
          in
          timeout={1200}
        >
          <ContainKeyStats>
            <KeyStatWrapper>
              <IconDiv>
                <Clock />
              </IconDiv>
              <KeyStatInner>
                <span style={{ fontSize: 10, color: 'lightgray' }}>
                  Time To Complete
                </span>
                <span style={{ fontSize: 12, fontWeight: 500 }}>
                  {completionTime}
                </span>
              </KeyStatInner>
            </KeyStatWrapper>
            <KeyStatWrapper>
              <IconDiv>
                <Target />
              </IconDiv>
              <KeyStatInner>
                <span style={{ fontSize: 10, color: 'lightgray' }}>Score To Pass</span>
                <span style={{ fontSize: 12, fontWeight: 500 }}>
                  {`${module.pass_score}/${module.total_questions}`}
                </span>
              </KeyStatInner>
            </KeyStatWrapper>
            <KeyStatWrapper>
              <IconDiv>
                <Briefcase />
              </IconDiv>
              <KeyStatInner>
                <span style={{ fontSize: 10, color: 'lightgray' }}>
                  Module By
                </span>
                <span style={{ fontSize: 12, fontWeight: 500 }}>
                  {module.company_name}
                </span>
              </KeyStatInner>
            </KeyStatWrapper>
          </ContainKeyStats>
        </Fade>
        <Fade
          in
          timeout={1200}
        >
          <QuestionBreakdownWrapper>
            <HoldQuestionBreakdown>
              <Typography variant="subtitle2" sx={{ mt: 1, mb: 0, color: 'black' }}>
                Question Breakdown
              </Typography>
              <div
                style={{
                  minHeight: 50,
                  flexGrow: 1,
                  gap: 4,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  flexWrap: 'wrap',
                }}
              >
                {questions.map((q, i) => (
                  <Grow
                    in
                    timeout={i * 1000}
                  >
                    <Bubble>
                      <span>
                        {i + 1}
                      </span>
                      <AbsoluteIcon>
                        <img
                          alt="check"
                          src={q.correct ? GREENCHECK : REDX}
                          style={{
                            height: q.correct ? 13 : 17,
                            width: q.correct ? 13 : 17,
                          }}
                        />
                      </AbsoluteIcon>
                    </Bubble>
                  </Grow>
                ))}
              </div>
              {
                module.display_answers && (
                <DisplayAnswersButton
                  onClick={() => setShowFullAnswers(p => !p)}
                >
                  <span style={{ fontSize: 10, color: SPIFFY_BLUE, textDecoration: 'underline' }}>
                    {showFullAnswers ? 'Hide Your Answers' : 'View Your Answers'}
                  </span>
                </DisplayAnswersButton>
                )
              }
            </HoldQuestionBreakdown>
            <CircularProgressBox>
              <span
                style={{
                  fontSize: 10,
                  fontWeight: 400,
                  marginTop: 8,
                  marginBottom: 2,
                }}
              >
                You Scored
              </span>
              <CustomCircularProgress
                variant="determinate"
                size={60}
                value={percent}
              />
              <CustomCircularProgressGrey
                variant="determinate"
                size={60}
                value={100}
              />
              <span style={{
                fontSize: 22,
                fontWeight: 600,
                position: 'absolute',
                top: 38,
              }}
              >
                {questionsCorrect}/{numQuestions}
              </span>
            </CircularProgressBox>
          </QuestionBreakdownWrapper>
        </Fade>
        <Collapse
          in={showFullAnswers}
        >
          <ShowAnswers questions={questions} />
        </Collapse>
        <Fade
          in
          timeout={1200}
        >
          <div>
            <SendFeedback moduleId={module.id} />

          </div>
        </Fade>
        <Fade
          in
          timeout={1200}
        >
          <ButtonHolder>
            <Button
              variant={passed ? 'contained' : 'outlined'}
              color="secondary"
              size="small"
              style={{ maxWidth: 140, whiteSpace: 'nowrap' }}
              onClick={() => navigate('/')}
            >
              Back to Feed
            </Button>
            {
              !passed && (
                <PrimaryActionButton
                  variant="contained"
                  size="small"
                  sx={{ ml: 2, whiteSpace: 'nowrap' }}
                  onClick={() => dispatch({ type: ACTIONS.RESET_MODULE })}
                >
                  Retry Module
                </PrimaryActionButton>
              )
            }
          </ButtonHolder>
        </Fade>
      </InnerWrapper>
    </ResultsWrapper>
  );
};

export default Results;

Results.propTypes = {
  state: PropTypes.object.isRequired,
  module: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  ACTIONS: PropTypes.object.isRequired,
};
