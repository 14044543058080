import { useQuery } from 'react-query';
import API from '../axios/API';

const getReferralCode = async () => {
  const { data } = await API.get('referrals/link/');
  return data;
};

const useReferralCode = () => useQuery('referralCode', getReferralCode);

export default useReferralCode;
