import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import ShowAnswers from '../../../ShowAnswers';

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
`;

const CompletionResults = ({
  data,
}) => {
  const parsedQuestions = JSON.parse(data.quiz__questions);

  return (
    <Wrapper>
      <ShowAnswers questions={parsedQuestions.questions} userAnswers={data.user_answers} />
    </Wrapper>
  );
};

export default CompletionResults;

CompletionResults.propTypes = {
  data: PropTypes.object.isRequired,
};
