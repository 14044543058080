/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { Paper, Typography } from '@mui/material';
import { ExitModal } from '../../styles/buttons';
import MOBILE from '../../assets/svg/mobile.svg';
import AppStoreBtn from '../../assets/appstore-black.svg';
import GoolePlayBtn from '../../assets/google-play-badge.png';

const Wrapper = styled(Paper)`
  position: absolute;
  left: 50%;
  top: 50%;
  text-align: center;
  transform: translate(-50%, -50%);
  padding: 20px;
  min-height: 200px;
  width: 520px;
  background: ${props => props.theme.palette.background.paper};
`;

const FlexDiv = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Left = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: flex-start;
  justify-content: center;
  height: 170px;
  text-align: left;
`;

const RegisterImg = styled.img`
  margin-top: auto;
  height: 120px;
  width: 120px;
`;

const AlignImages = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
`;

const CustomImgApple = styled.img`
  width: 100px;
`;

const CustomImgGooglePlay = styled.img`
  width: 120px;
  height: 48px;
`;

const HyperLink = styled.a`
  text-decoration: none;
`;

const NewRegisterModal = ({
  setShowRegisterModal,
}) => (
  <Wrapper>
    <ExitModal onClick={() => setShowRegisterModal(false)} />
    <Typography variant="h3" align="center">
      Welcome to Spiffy
    </Typography>
    <Typography variant="subtitle1" align="center" sx={{ mb: 5, mt: -1 }}>
      A couple tips for getting started! 👇
    </Typography>
    <FlexDiv>
      <Left>
        <Typography variant="subtitle2">
          1) Make sure you are on a team
        </Typography>
        <Typography variant="subtitleSmall" sx={{ mb: 2, ml: 4 }}>
          Joining your team will start your spiffy progress, and make modules show up in your feed
        </Typography>
        <Typography variant="subtitle2">
          2) Follow a brand you are interested in
        </Typography>
        <Typography variant="subtitleSmall" sx={{ mb: 2, ml: 4 }}>
          Following brands will put extra modules in your feed and help you expand your knowledge
        </Typography>
        <Typography variant="subtitle2">
          3) Download our mobile app
        </Typography>
        <AlignImages>
          <HyperLink target="_blank" href="https://apps.apple.com/ca/app/spiffy/id1138634357">
            <CustomImgApple src={AppStoreBtn} alt="Get it on apple appstore" />
          </HyperLink>
          <HyperLink target="_blank" href="https://play.google.com/store/apps/details?id=com.spiffy.app">
            <CustomImgGooglePlay src={GoolePlayBtn} alt="Get it on google playstore" />
          </HyperLink>
        </AlignImages>
      </Left>
      <RegisterImg src={MOBILE} />
    </FlexDiv>
  </Wrapper>
);

export default NewRegisterModal;

NewRegisterModal.propTypes = {
  setShowRegisterModal: PropTypes.func.isRequired,
};
