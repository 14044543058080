import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import Question from './Question';

const ShowQuestion = styled.div`
  width: 100%;
  background-color: ${props => props.theme.palette.background.paper};
  min-height: 340px;
  margin-top: 8px;
  padding: 20px;
  border-radius: 0;
  box-shadow: ${props => props.theme.palette.boxShadow.main};
`;

const Questions = ({
  state,
  dispatch,
  ACTIONS,
}) => {
  const { questions, activeQuestion } = state;

  return (
    <ShowQuestion>
      <Question
        question={questions[activeQuestion]}
        dispatch={dispatch}
        ACTIONS={ACTIONS}
        state={state}
      />
    </ShowQuestion>
  );
};

export default Questions;

Questions.propTypes = {
  state: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  ACTIONS: PropTypes.object.isRequired,
};
