/* eslint-disable */
import React, { useState, useMemo } from 'react';
import styled from 'styled-components/macro';
import { Paper, Modal, Alert, AlertTitle } from '@mui/material'
import ModuleCard from './ModuleCard';
import StreamCard from './StreamCard';
import { CustomTextField } from '../../../../styles/forms';
import { Search, X } from 'react-feather';
import FollowAndStartModal from './FollowAndStartModal';

const Wrapper = styled(Paper)`
  width: 640px;
  min-height: 1000px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  background-color: rgba(245,245,245, .6);

`

const SubtitleSpan = styled.span`
  font-size: 13px;
  font-weight: ${props => props.isActive === 'y' ? 400 : 300};
  color: ${props => props.isActive === 'y' 
    ? props.theme.palette.primary.main 
    : 'darkgrey'
  };
  text-transform: uppercase;
  letter-spacing: 1px;
  line-height: 1;
  cursor: pointer;
`;


const Top = styled.div`
  width: 100%;
  border-bottom: 1px solid lightgray;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: white;
  height: 39px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0px 10px;
`;

const Bottom = styled.div`
  padding: 10px;
  width: 100%; 
`

const NoContentWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 500px;

  span {
    font-size: 18px;
    color: darkgrey;
  }

`

const TabDiv = styled.div`
  height: 39px;
  width: 100px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`

const SearchButton = styled.div`
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  border: 1px solid lightgray;
  border-radius: 4px;
  max-width: 36px;
  cursor: pointer;

  &:hover {
    svg {
      height: 21px;
      width: 21px;
    }
  }

  svg {
    color: darkgrey;
    height: 20px;
    width: 20px;
  }
`

const IndicationDiv = styled.div`
  width: 108px; 
  height: 32px; 
  padding: 4px; 
  border-radius: 3px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    font-size: 12px;
    font-weight: 500;
  }

`

const TotalsHeader = styled.p`
  font-size: 15px; 
  font-weight: 500;
  line-height: 1;
  color: black;
`

const DarkerBackdropModal = styled(Modal)`
  background-color: rgba(0,0,0, .6);
`

const getNumStreamsCompleted = streams => {
  let numCompleted = 0;
  let numIncomplete = 0;

  streams.forEach(stream => {
    const isComplete = stream.quizzes.every(mod => mod.completed);
    if (isComplete) {
      numCompleted +=1 
    } else {
      numIncomplete +=1
    }
    
  })

  return {
    numStreamsCompleted: numCompleted,
    numStreamsIncomplete: numIncomplete,
  }
}

const getCleanModules = (moduleArray, isSearchActive, searchText) => {
  if (isSearchActive) {
    return moduleArray
      .filter(mod => mod.name.toLowerCase().includes(searchText.toLowerCase()))
      .sort((a, b) => a.created - b.created)
  }
  return moduleArray.sort((a, b) => a.created - b.created)
};


const BrandContent = ({
  data,
}) => {

  const [tab, setTab] = useState(0);
  const [searchText, setSearchText] = useState('');
  const [searchOpen, setSearchOpen] = useState(false);
  const [followAndStartModuleId, setFollowAndStartModuleId] = useState(null);

  const isSearchActive = searchText.length > 2;

  const cleanModuleArray = useMemo(() => getCleanModules(data.available_quizzes, isSearchActive, searchText), [searchText]);

  const brandHasNoModules = data.available_quizzes.length === 0;
  const brandHasNoStreams = data.streams.length === 0;
  const nunModulesCompleted = data.available_quizzes.filter(mod => mod.completed).length;
  const numModulesIncomplete = data.available_quizzes.length - nunModulesCompleted;
  const { numStreamsCompleted, numStreamsIncomplete} = getNumStreamsCompleted(data.streams);

  const handleSearchChange = e => setSearchText(e.target.value);

  return (
    <Wrapper>
      <Top>
        <div style={{  display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start'}}>
          <TabDiv
            style={{ borderBottom : tab === 0 ? '2px solid  rgb(51, 122, 183)' : 'none'}}
            onClick={() => {
              setSearchText('')
              return setTab(0)
            }}
          >
            <SubtitleSpan
              isActive={tab === 0 ? 'y' : 'n'}
            >
              Modules
            </SubtitleSpan>
          </TabDiv>
          <TabDiv
            style={{ borderBottom : tab === 1 ? '2px solid rgb(51, 122, 183)' : 'none'}}
            onClick={() => {
              setSearchText('')
              return setTab(1)
            }}
          >
            <SubtitleSpan
              isActive={tab === 1 ? 'y' : 'n'}
            >
              Streams
            </SubtitleSpan>
          </TabDiv>
        </div>
      </Top>
      <Bottom>
        <div style={{ width: '100%', display: 'flex', alignItems: 'flex-start', justifyContent: 'center', flexDirection: 'column', marginBottom: 12}}>
          <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
            <IndicationDiv
              style={{ width: 160, border: 'none', background: 'transparent', height: 32 }}
            >
            <TotalsHeader>
              {
                tab === 0 ? `${data.available_quizzes.length} modules total` : `${data.streams.length} streams total`
              }
            </TotalsHeader>
            </IndicationDiv>
            <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', gap: 8}}>
              {
                searchOpen ? (
                  <CustomTextField 
                    style={{ width: 260, transform: 'translateY(-2px)'}}
                    placeholder={`Search by ${tab === 0 ? 'module' : 'stream'} name...`}
                    onChange={handleSearchChange}
                    size="small"
                    value={searchText}
                    variant='outlined'
                    autoFocus
                  /> 
                ) : (
                  <>
                    <IndicationDiv style={{  borderLeft: '4px solid #50c878'}}>
                      <span>
                        {
                          tab === 0 ? (
                            `${nunModulesCompleted} Completed`
                          ) : (
                            `${numStreamsCompleted} Completed`
                          )
                        }
                      </span>
                    </IndicationDiv>
                    <IndicationDiv style={{ borderLeft: '4px solid #FAA0A0'}}>
                      <span>
                        {
                          tab === 0 ? (
                            `${numModulesIncomplete} Incomplete`
                          ) : (
                            `${numStreamsIncomplete} Incomplete`
                          )
                        }
                      </span>
                    </IndicationDiv>

                  </>

                )
              }
              <SearchButton
                onClick={() => setSearchOpen(p => {
                  if(p) {
                    setSearchText('');
                  };

                  return !p
                })}
              >
                {
                  searchOpen ? <X /> : <Search />
                }
              </SearchButton>
            </div>
          </div>
        </div>
        {
          tab === 0 && (
            brandHasNoModules ? (
              <NoContentWrapper>
                <span>
                  Brand currently has no modules
                </span>
              </NoContentWrapper>
            ) : (
              cleanModuleArray.map(mod => 
              <ModuleCard 
                key={mod.id} 
                mod={mod} 
                setFollowAndStartModuleId={setFollowAndStartModuleId} 
                isCurrentlyFollowing={data.is_followed} 
              /> 
              )
            )
          )
        }
        {
          tab === 1 && (
            brandHasNoStreams ? (
              <NoContentWrapper>
                <span>
                  Brand currently has no streams
                </span>
              </NoContentWrapper>
            ) : (
              <>
                <Alert severity="info" sx={{ mb: 2}}>
                  <AlertTitle style={{ fontSize: 14}}>What are streams?</AlertTitle>
                  <p style={{ fontSize: 13}}>Streams give companies a way to organize similar modules together in a row.</p>
                </Alert>
                {isSearchActive ? (
                  data.streams
                    .filter(stream => stream.name.toLowerCase().includes(searchText.toLowerCase()))
                      .map(stream => <StreamCard stream={stream} key={stream.id} />)
                  )
                : (
                  data.streams.map(stream => <StreamCard stream={stream} key={stream.id} />)
                )}
              
              </>
            )
          )
        }
      </Bottom>
      <DarkerBackdropModal
        open={Boolean(followAndStartModuleId)}
        onClose={() => setFollowAndStartModuleId(null)}
      >
        <div>
          <FollowAndStartModal
            followAndStartModuleId={followAndStartModuleId}
            companyId={data.company.id}
            companyName={data.company.name}
          />
        </div>
      </DarkerBackdropModal>
    </Wrapper>
  )
}

export default BrandContent