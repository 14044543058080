import { useQuery } from 'react-query';
import API from '../axios/API';

const getBadgesDetails = async (code, timezone) => {
  const { data } = await API.get(`achievements/details/?achievement_code=${code}&timezone_name=${timezone}`);
  return data;
};

const useBadgeDetails = (code, timezone) => useQuery(['badgeDetails', code, timezone], () => getBadgesDetails(code, timezone));

export default useBadgeDetails;
