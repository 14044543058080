/* eslint-disable arrow-body-style */
import React, { useEffect, useState } from 'react';
import { Tag } from 'react-feather';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { Typography, Button, CircularProgress } from '@mui/material';
import API from '../../../../../axios/API';
import {
  ButtonHolder,
  ShowSearchResults,
  Option,
  Centered,
  SelectedIcon,
} from './styles';

const Wrapper = styled.div`
  padding: 20px;
  width: 100%;
`;

const ResultTag = styled(Tag)`
  color: ${props => props.theme.palette.background.bw};
  margin-right: 8px;
  height: 12px;
  width: 12px;
  transform: translateY(2px);
`;

const SelectRoles = ({
  state,
  dispatch,
  ACTIONS,
}) => {
  const [availableRoles, setAvailableRoles] = useState([]);
  const [fetching, setFetching] = useState(false);
  const [selected, setSelected] = useState([]);

  const { selectedCompany } = state;

  useEffect(() => {
    const getAvailRoles = async () => {
      setFetching(true);
      try {
        const { data } = await API.get(`/teams/search/roles/?company_id=${selectedCompany.id}`);
        setAvailableRoles(data.roles.map(role => {
          return {
            id: role[0],
            name: role[1],
          };
        }));
        setFetching(false);
      } catch (error) {
        setFetching(false);
      }
    };

    getAvailRoles();
  }, []);

  const selectedIds = selected.map(r => r.id);

  const handleSelectRole = (role) => {
    if (selectedIds.includes(role.id)) {
      return setSelected(p => p.filter(r => r.id !== role.id));
    }
    return setSelected(p => [...p, role]);
  };

  return (
    <Wrapper>
      <Typography variant="body1" color="primary">
        Step 3
      </Typography>
      <Typography variant="h3">
        Select your roles
      </Typography>
      <ShowSearchResults>
        {
          fetching ? (
            <Centered>
              <CircularProgress size={20} color="primary" />
            </Centered>
          ) : (
            availableRoles.map(role => (
              <Option
                key={role.id}
                onClick={() => handleSelectRole(role)}
              >
                <span>
                  <ResultTag />
                  {role.name}
                </span>
                {
                  (selected.length > 0
                    && selectedIds.includes(role.id)) ? <SelectedIcon /> : <div />
                }
              </Option>
            ))
          )
          }
      </ShowSearchResults>
      <ButtonHolder>
        <Button
          size="small"
          variant="outlined"
          color="secondary"
          onClick={() => dispatch({ type: ACTIONS.REMOVE_SELECTED_LOCATION })}
        >
          Go Back
        </Button>
        <Button
          size="small"
          variant="contained"
          sx={{ ml: 1 }}
          disabled={selected.length === 0}
          color="primary"
          onClick={() => {
            if (selected) {
              return dispatch({ type: ACTIONS.ADD_SELECTED_ROLES, payload: selected });
            }
            return dispatch({ type: ACTIONS.SET_INDEX, payload: 3 });
          }}
        >
          Next Step
        </Button>
      </ButtonHolder>
    </Wrapper>
  );
};

export default SelectRoles;

SelectRoles.propTypes = {
  state: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  ACTIONS: PropTypes.object.isRequired,
};
