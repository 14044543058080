/* eslint-disable */
/* eslint-disable no-use-before-define */
import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import {
  CircularProgress,
  InputAdornment,
  IconButton,
  Tooltip,
} from '@mui/material';
import styled from 'styled-components/macro';
import { Archive, ArrowRightCircle, Search } from 'react-feather';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import PageLoader from '../../../components/Loaders/PageLoader';
import PageHeader from '../../../components/Reuseable/PageHeader';
import useUserCompletions from '../../../reactQueryHooks/useUserCompletions';
import PageError from '../../../components/Reuseable/PageError';
import { CustomTextField } from '../../../styles/forms';
import { FakeTab, FlexBetween } from '../Explore/styles';
import { addLogoString } from '../../../helpers';
import useWindowSize from '../../../hooks/useWindowSize';
import MaxWidthWrapper from '../../../components/Reuseable/MaxWidthWrapper';
import { useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';
import { GlobalState } from '../../../store/global';


const Center = styled.div`
  height: auto;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  margin-bottom: 40px;
  -ms-overflow-style: none;
  scrollbar-width: none; 

  ::-webkit-scrollbar {
    display: none;
  }
`;

const CustomTextFieldWithHide = styled(CustomTextField)`
  @media(max-width: 500px) {
    display: none;
  }
`;

const ModuleImage = styled(LazyLoadImage)`
  margin-right: 4px;
  height: 36px; 
  width: 36px; 
  border-radius: 4px;
  object-fit: contain; 
  border: 1px solid #EEEEEE;
  background-color: white;
`;

const StyledTableHeader = styled.thead`
  background-color: #EEEEEE;
  border-radius: 6px;
  height: 40px; 
  position: sticky;
  top: 0;
  z-index: 2;


  th {
    text-align: start;
    padding: 4px;
  }
`;

const StyledTableBody = styled.tbody`

  border: 1px solid darkgrey;

  tr {
    border: 1px solid #EEEEEE;

    td {
      padding: 4px 6px;
    }
  }
  
  tr:nth-child(even) {
    background-color: #EEEEEE80;
  }

`;

const CustomCircularProgress = styled(CircularProgress)`

  position: absolute;
  top: 2px;
  z-index: 1;

  .MuiCircularProgress-circleDeterminate {
    color: mediumseagreen;
    z-index: 1;
  }
`;

const CustomCircularProgressGrey = styled(CustomCircularProgress)`

  z-index: 0;

  .MuiCircularProgress-circleDeterminate {
    color: lightgray;
    z-index: 0;
  }
`;

const ShowScore = ({ questions, score }) => {


  const progress = questions < 1 ? 0 : Math.floor((score / questions) * 100);

  return (
    <div
      style={{
        height: 40,
        width: 40,
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <CustomCircularProgress variant="determinate" value={progress} size={36} />
      <CustomCircularProgressGrey variant="determinate" value={100} size={36} />
      <span style={{ fontSize: 10, fontWeight: 500, marginTop: 2 }}>
        {score}/{questions}
      </span>
    </div>
  );
};

ShowScore.propTypes = {
  questions: PropTypes.number.isRequired,
  score: PropTypes.number.isRequired,
};

const Review = () => {
  
  const {
    reviewSubmissionInfo,
    setReviewSubmissionInfo,
  } = useContext(GlobalState);

  const { isLoading, error, data } = useUserCompletions();
  const [search, setSearch] = useState('');
  const [matchingCompletions, setMatchingCompletions] = useState([]);
  const { width } = useWindowSize();

  const location = useLocation();

  const allCompletions = data && data.unpaid_quiz_results ? data.unpaid_quiz_results : [];

  useEffect(() => {
    if (!search || isLoading) return;
    if (search.length < 3) return;
    const matching = allCompletions.filter(comp => {
      if (!comp.quiz__name) return false;
      const lowerCompName = comp.quiz__name.toLowerCase();
      return lowerCompName.includes(search);
    });
    setMatchingCompletions(matching);
  }, [search]);


  // useEffect(() => {
  //   /// check if we routed here from brand overview page
  //   /// if we did... open the review for that submission id
  //   if (location && location.state && location.state.submissionId) {
  //     setReviewInfo({
  //       id: location.state.submissionId,
  //       logo: addLogoString(location.state.logo),
  //     });
  //   }


  // }, [])

  if (isLoading) {
    return (
      <>
        <PageHeader text="Review" />
        <PageLoader />
      </>
    );
  }

  if (error) {
    return <PageError />;
  }

  return (
    <>
      <PageHeader text="Review" />
      <MaxWidthWrapper>
        <FlexBetween>
          <FakeTab style={{ zIndex: 2 }}>
            <div style={{
              height: 32,
              width: 32,
              border: '1px solid lightgray',
              marginRight: 8,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              background: 'white',
              borderRadius: 6,
            }}
            >
              <Archive />
            </div>
            {
              (search.length < 3 || width < 600) ? (
                <span variant="subtitleSmall" style={{ whiteSpace: 'nowrap' }}>
                  Your Completions
                </span>
              ) : (
                <span variant="subtitleSmall" style={{ whiteSpace: 'nowrap' }}>
                  Completions matching search
                </span>
              )
            }
            {
              (search.length > 2 && width > 600) && (
              <span
                style={{
                  marginLeft: 4,
                  fontSize: 12,
                  color: 'slategrey',
                }}
              >
                ({search})
              </span>
              )
            }
          </FakeTab>
          <CustomTextFieldWithHide
            style={{ minWidth: 260, marginRight: 2, marginBottom: 0 }}
            value={search}
            onChange={(e) => setSearch(e.target.value.toLowerCase())}
            placeholder="Search Completions..."
            size="small"
            type="search"
            variant="outlined"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
          />
        </FlexBetween>
        <Center
          style={{
            border: '1px solid gainsboro',
            marginTop: 12,
            borderRadius: 4,
            maxHeight: 800,
            overflowY: 'auto',
          }}
        >
          <table style={{
            borderSpacing: 0,
            borderRadius: 4,
            width: '100%',
            background: 'white',
            zIndex: 1,
            padding: 0,
            margin: 0,
          }}
          >
            <StyledTableHeader>
              <tr>
                <th style={{ padding: '4px 12px' }}>
                  <span>
                    Modules
                  </span>
                  <span
                    style={{
                      marginLeft: 4,
                      fontSize: 12,
                      color: 'slategrey',
                    }}
                  >
                    {search.length < 3
                      ? `(${allCompletions.length})`
                      : `(${matchingCompletions.length})`
                    }
                  </span>
                </th>
                {
                  width > 600 && (
                  <>
                    <th style={{ padding: 4 }}>Company</th>
                    <th style={{ padding: 4 }}>Date</th>
                  </>
                  )
                }
                <th style={{ padding: 4, textAlign: 'center' }}>Score</th>
                <th style={{ padding: 4 }} />
              </tr>
            </StyledTableHeader>
            <StyledTableBody>
              {
                (search.length < 3 ? allCompletions : matchingCompletions).length === 0 && (
                  <div
                    style={{
                      minHeight: 40,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-start',
                      paddingLeft: 12,
                    }}
                  >
                    <span style={{ fontWeight: 400, color: 'darkgrey', fontSize: 12 }}>
                      {
                        search.length < 3
                          ? 'You dont have any completions yet'
                          : 'No completions found matching this search...'
                      }
                    </span>
                  </div>
                )
              }
              {
                (search.length < 3 ? allCompletions : matchingCompletions).map(comp => (
                  <tr
                    key={comp.created}
                    style={{ borderSpacing: 3 }}
                  >
                    <td>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'flex-start',
                          maxWidth: 360,
                        }}
                      >
                        <ModuleImage
                          src={addLogoString(comp.quiz__image)}
                          height={36}
                          width={36}
                        />
                        <span style={{ fontSize: width > 600 ? 14 : 12, fontWeight: 300, color: 'black' }}>
                          {comp.quiz__name}
                        </span>
                      </div>
                    </td>
                    {
                      width > 600 && (
                        <>
                          <td>
                            <span style={{ fontSize: 13, fontWeight: 300, color: 'black' }}>
                              {comp.quiz__company__name}
                            </span>
                          </td>
                          <td>
                            <span style={{ fontSize: 13, fontWeight: 300, color: 'black' }}>
                              {new Date(comp.created).toDateString()}
                            </span>
                          </td>
                        </>
                      )

                    }
                    <td>
                      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <ShowScore questions={comp.questions} score={comp.score} />
                      </div>
                    </td>
                    <td
                      style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          height: 40,
                          width: 30,
                        }}
                      >
                        <Tooltip
                          title="View"
                          arrow
                          placement="left"
                        >
                          <IconButton
                            color="primary"
                            onClick={() => {
                              setReviewSubmissionInfo({
                                id: comp.id,
                                logo: addLogoString(comp.quiz__image),
                              });
                            }}
                          >
                            <ArrowRightCircle style={{ height: 20, width: 20 }} />
                          </IconButton>

                        </Tooltip>
                      </div>
                    </td>
                  </tr>
                ))
              }
            </StyledTableBody>
          </table>
        </Center>
      </MaxWidthWrapper>
      {/* <ResuseableDrawer
        isOpen={Boolean(reviewInfo)}
        handleClose={() => setReviewInfo(null)}
      >
        <CompletionDetails reviewInfo={reviewInfo} />
      </ResuseableDrawer> */}
    </>
  );
};

export default Review;
