/* eslint-disable */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import {
  Typography,
  InputAdornment,
  Button,
  LinearProgress,
} from '@mui/material';
import {
  Search,
  Briefcase,
} from 'react-feather';
import {
  ButtonHolder,
  ShowSearchResults,
  Option,
  Centered,
  SelectedIcon,
} from './styles';
import {
  CustomTextField,
} from '../../../../../styles/forms';
import API from '../../../../../axios/API';

const Wrapper = styled.div`
  padding: 20px;
  width: 100%;
`;

const NoResultsSpan = styled.span`
  color: ${props => props.theme.palette.background.bg};
`;

const ResultBriefcase = styled(Briefcase)`
  margin-right: 8px;
  height: 12px;
  width: 12px;
  transform: translateY(2px);
`;

const SelectCompany = ({
  state,
  dispatch,
  ACTIONS,
}) => {
  const [search, setSearch] = useState('');
  const [fetching, setFetching] = useState(false);
  const [selected, setSelected] = useState(null);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (search.length < 3) {
      setOptions([]);
      return undefined;
    }
    const searchCompany = async () => {
      setFetching(true);
      try {
        const { data } = await API.get(`/teams/search/company/?search=${search}`);
        setOptions(data.companies);
        if (selected) {
          // check to see if the currently selected is still avail in the options
          const selectedAvail = data.companies.some(company => company.id === selected.id);
          if (!selectedAvail) {
            setSelected(null);
          }
        }
        setFetching(false);
      } catch (error) {
        setFetching(false);
      }
    };

    searchCompany();
  }, [search]);

  return (
    <Wrapper>
      <Typography variant="body1" color="primary">
        Step 1
      </Typography>
      <Typography variant="h3">
        Find your team
      </Typography>
      <CustomTextField
        type="text"
        size="small"
        sx={{
          width: 380,
          mt: 5,
        }}
        value={search}
        autoFocus
        onChange={(e) => setSearch(e.target.value)}
        label="Search for teams"
        color="primary"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          ),
        }}
      />
      {
        fetching && <LinearProgress color="secondary" variant="indeterminate" sx={{ width: 376, height: 2, ml: 1 }} />
      }
      <ShowSearchResults>
        <Typography variant="subtitle2" mb={2}>
          Results
        </Typography>
        {
          options.length === 0 ? (
            <Centered>
              <NoResultsSpan>
                {
                  search.length > 2 ? 'No results for this search' : 'Use search to find teams'
                }
              </NoResultsSpan>
            </Centered>
          ) : (
            <>
              {options.map(option => (
                <Option
                  key={option.id}
                  onClick={() => setSelected(option)}
                >
                  <span>
                    <ResultBriefcase />
                    {option.name}
                  </span>
                  {
                    (selected && selected.id === option.id) ? <SelectedIcon /> : <div />
                  }
                </Option>
              ))}
            </>
          )
        }
      </ShowSearchResults>
      <ButtonHolder>
        <Button
          color="primary"
          variant="contained"
          size="small"
          disabled={(!selected && !state.selectedCompany)}
          onClick={() => {
            setSearch('');
            setOptions([]);
            if (selected) {
              return dispatch({ type: ACTIONS.ADD_SELECTED_COMPANY, payload: selected });
            }
            return dispatch({ type: ACTIONS.SET_INDEX, payload: 1 });
          }
          }
        >
          Next Step
        </Button>
      </ButtonHolder>
    </Wrapper>
  );
};

export default SelectCompany;

SelectCompany.propTypes = {
  state: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  ACTIONS: PropTypes.object.isRequired,
};
