/* eslint-disable */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, {
  useState,
  useRef,
  useContext,
} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import {
  Modal,
  Paper,
  Typography,
  MenuItem,
  Menu,
  Avatar,
  Slide,
  Button,
} from '@mui/material';
import {
  Edit2,
  Trash,
  MapPin,
  Calendar,
  Mail,
  Clock,
} from 'react-feather';
import { GlobalState } from '../../../store/global';
import DeleteAccountModal from './DeleteAccountModal';
import ExtraInfo from '../../../components/Reuseable/ExtraInfo';

const SPIFFY_BLUE = 'rgb(51, 122, 183)';

const Wrapper = styled(Paper)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
  position: relative;
  margin-bottom: 12px;
  border-radius: 8px;
  height: 270px;
`;

const ProfileImage = styled(Avatar)`
  height: 200px;
  width: 200px;
  font-size: 28px;
  object-fit: cover;
`;

const FlexDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 4px 0px;

  svg {
    color: black;
    margin-right: 8px;
    height: 14px;
    width: 14px;
  }

  span {
    font-size: 13px;
    font-weight: 300;
  }
`;

const MiniProfileHolder = styled.div`
  height: 58px;
  width: 58px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #EEEEEE;
  margin-right: 8px;

    
  @media (min-width: 1200px) {
    display: none;
  }
`;

const MiniProfileP = styled.img`
  height: 50px;
  width: 50px;
  border-radius: 25px;
  background-color: #EEEEEE88;
  align-items: center;
  justify-content: center;
`;

const ProfileLeft = styled.div`
  width: 240px;
  background-color: #EEEEEE;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  border-radius: 8px;

  @media (max-width: 1200px) {
    display: none;
  }
`;

const ProfileMiddle = styled.div`
  flex-grow: 1;
  min-width: 300px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  height: 100%;
  padding-left: 16px;
`;

const ProfileRight = styled.div`
  margin-left: 16px;
  // padding: 8px 0px 8px 10px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: row;
  height: 100%;


  @media(max-width: 660px){
    display: none;
  }
`;

const StyledMenuItem = styled(MenuItem)`
  display: flex;
  align-items: flex-start: 
  justify-content: center;
  color: ${props => props.theme.palette.background.bw};
  
  svg {
    height: 12px;
    width: 12px;
    margin-right: 8px;
    color: ${props => props.theme.palette.grey[500]};
  }
`;

const TextColored = styled.span`
  color: ${props => props.theme.palette.background.bw};
`;

const AvatarWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  overflow-y: hidden;
`;

const SlidingEdit = styled.div`
  height: 34%;
  width: 110%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  position: absolute;
  bottom: -3px;
  text-align: center;
  background-color: rgba(0, 0, 0, .2);
 
  p {
    margin-top: 6px;
    font-size: 13px;
    color: white;

    &:hover {
      color: ${props => props.theme.palette.highlight.dark};
      cursor: pointer;
    }
  }
`;

const SpiffyScoreBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-left: auto; 
  height: 100%;
  width: 240px; 
  border-radius: 8px;
  background-color: ${SPIFFY_BLUE};
`;

const ProfileHero = ({
  data,
  spiffyScore,
  dateJoined,
}) => {
  const { profile } = data;
  const [anchor, setAnchor] = useState(false);
  const [deleteAccount, setDeleteAccount] = useState(false);
  const [show, setShow] = useState(false);
  const containerRef = useRef(null);
  const { first_name: firstName, last_name: lastName, email } = profile;
  const { setShowUploadModal, setShowEditInfoModal } = useContext(GlobalState);

  const initials = `${firstName.trim()[0]}${lastName.trim()[0]}`;

  return (
    <Wrapper>
      <ProfileLeft>
        <AvatarWrapper
          ref={containerRef}
          onMouseOver={() => setShow(true)}
          onMouseLeave={() => setShow(false)}
        >
          <ProfileImage
            src={profile.photo}
          >
            {initials}
          </ProfileImage>
          <Slide direction="up" in={show} container={containerRef.current}>
            <SlidingEdit>
              <p onClick={() => setShowUploadModal(true)}>
                {profile.photo ? 'Edit' : 'Add Photo'}
              </p>
            </SlidingEdit>
          </Slide>
        </AvatarWrapper>

      </ProfileLeft>
      <ProfileMiddle>
        <div
          style={{
            height: '100%',
            display: 'flex',
            alignItems: 'flex-start',
            flexDirection: 'column',
            justifyContent: 'flex-start',
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
              marginTop: 20,
            }}
          >
            <MiniProfileHolder>
              <MiniProfileP
                src={profile.photo}
              />
            </MiniProfileHolder>
            <Typography variant="h2" style={{ fontSize: 34}}>
              {`${firstName} ${lastName}`}
            </Typography>
          </div>
          <div style={{ minWidth: 260, height: 'auto', padding: '10px 0px' }}>
            <FlexDiv>
              <Mail />
              <span>
                Email: {email}
              </span>
            </FlexDiv>
            <FlexDiv>
              <Calendar />
              <span>
                Joined: {new Date(dateJoined).toDateString()}
              </span>
            </FlexDiv>
            <FlexDiv>
              <Clock />
              <span>
                Date of Birth: {profile?.birth_date ? profile.birth_date : null}
              </span>
            </FlexDiv>
            <FlexDiv>
              <MapPin />
              <span>
                Resides: {profile?.country ? profile.country.name : null}
              </span>
            </FlexDiv>
          </div>
          <Button
            variant="outlined"
            size="small"
            sx={{
              mt: 1,
              color: SPIFFY_BLUE,
              backgroundColor: 'lightgrey',
              border: 'none',
            }}
            onClick={e => setAnchor(e.currentTarget)}
          >
            Edit
          </Button>
        </div>
      </ProfileMiddle>
      <ProfileRight>
        <SpiffyScoreBox>
          <div>
            <span
              style={{
                fontSize: 20,
                color: 'white',
                fontWeight: '400',
                marginRight: 4,
              }}
            >
              Spiffy Score
            </span>
            <ExtraInfo isDarkBg>
              <div>
                <TextColored style={{ fontSize: 13, fontWeight: '500', display: 'block' }}>
                  What is this score?
                </TextColored>
                <TextColored style={{ fontSize: 11, fontWeight: '400' }}>
                  Your score is calculated based on your total knowledge gained on Spiffy all-time, your consistency in more recent learning, and your pass rate. Spiffy is designed for regular consumption of small bites of information, so you may notice you earn less points when you've overloaded your brain
                </TextColored>
              </div>
            </ExtraInfo>
          </div>
          <span style={{ fontSize: 62, fontWeight: '800', color: 'white' }}>
            {spiffyScore}
          </span>
        </SpiffyScoreBox>
        <Menu
          anchorEl={anchor}
          keepMounted
          open={Boolean(anchor)}
          onClose={() => setAnchor(null)}
        >
          <StyledMenuItem
            onClick={() => {
              setAnchor(null);
              return setShowEditInfoModal(true);
            }}
          >
            <Edit2 height={12} width={12} />
            Edit Information
          </StyledMenuItem>
          <StyledMenuItem
            onClick={() => {
              setAnchor(null);
              return setDeleteAccount(true);
            }}
          >
            <Trash />
            Delete Account
          </StyledMenuItem>
        </Menu>
      </ProfileRight>
      <Modal
        open={deleteAccount}
        onClose={() => setDeleteAccount(false)}
        aria-labelledby="modal-delete-account"
        aria-describedby="modal-to-delete-account"
      >
        <div>
          <DeleteAccountModal
            setDeleteAccount={setDeleteAccount}
          />
        </div>
      </Modal>
    </Wrapper>
  );
};

export default ProfileHero;

ProfileHero.propTypes = {
  data: PropTypes.object.isRequired,
  dateJoined: PropTypes.string.isRequired,
  spiffyScore: PropTypes.number.isRequired,
};
