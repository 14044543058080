import React, { useState } from 'react';
import { ChevronRight, ChevronLeft } from 'react-feather';
import styled from 'styled-components/macro';
import { SmallIconButton } from '../../../styles/buttons';
import Streak from './Streak';
import MiniProfile from './MiniProfile';

const Wrapper = styled.div`
  height: 70px;
  position: fixed;
  bottom: 6px;
  right: 6px;
  border-radius: 6px;
  width: 230px;
  display: flex;
  align-items: flex-start;
  padding: 8px 10px;
  z-index: 20;
  flex-direction: column;
  background-color: ${props => props.theme.palette.nav.background};

  @media(max-width: 460px) {
    display: none;
  }
`;

const AbosluteSmallIconButton = styled(SmallIconButton)`
  position: absolute;
  bottom: 2px;
  right: 2px;
  padding: 2px;

  &:hover {
    background-color: ${props => props.theme.palette.background.light};
  }
`;

const StreakContainer = () => {
  const [index, setIndex] = useState(0);
  const [hidden, setHidden] = useState(false);

  const handleChangeIndex = () => {
    if (index === 0) return setIndex(1);

    return setIndex(0);
  };

  if (hidden) return null;

  return (
    <Wrapper>
      {
        index === 0 && <Streak setHidden={setHidden} />
      }
      {
        index === 1 && <MiniProfile />
      }
      <AbosluteSmallIconButton
        onClick={handleChangeIndex}
      >
        {
          index === 0 ? <ChevronRight /> : <ChevronLeft />
        }
      </AbosluteSmallIconButton>
    </Wrapper>
  );
};

export default StreakContainer;
