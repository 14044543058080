import React from 'react';
import styled from 'styled-components/macro';
import { Typography } from '@mui/material';

const CenterText = styled.div`
  width: 100%;
  min-height: 216px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const NoBrandsFound = () => (
  <CenterText>
    <Typography variant="subtitle1">
      No brands found for this search
    </Typography>
  </CenterText>
);

export default NoBrandsFound;
