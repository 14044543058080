import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import MultipleChoice from './MultipleChoice';
import SelectAll from './SelectAll';
import TrueFalse from './TrueFalse';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const QuestionHeader = styled.div`
  width: 100%;
  padding: 6px 8px;
  border-radius: 2px;
  background-color: ${props => props.theme.palette.background.contrast};

  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  text-align: center;

  span{
    font-size: 18px;
    font-weight: 400;
    color: ${props => props.theme.palette.background.bw};
    text-align: start;
    line-height: 1.4;

    @media(max-width: 500px) {
      font-size: 14px;
    }
  }
`;

const QUESTION_TYPES = {
  TRUE_FALSE: 'tf',
  MULTIPLE_CHOICE: 'mc',
  SELECT_ALL: 'sa',
};

const QuestionComponents = {
  [QUESTION_TYPES.TRUE_FALSE]: TrueFalse,
  [QUESTION_TYPES.MULTIPLE_CHOICE]: MultipleChoice,
  [QUESTION_TYPES.SELECT_ALL]: SelectAll,
};

const Question = ({
  question,
  ACTIONS,
  dispatch,
  state,
}) => {
  const CurrentQuestionComponent = QuestionComponents[question.type];

  return (
    <Wrapper>
      <QuestionHeader>
        <p style={{ fontSize: 10, fontWeight: 300 }}>
          Question {state.activeQuestion + 1}
        </p>
        <span>
          {question.question}
        </span>
      </QuestionHeader>
      <CurrentQuestionComponent
        question={question}
        ACTIONS={ACTIONS}
        dispatch={dispatch}
        state={state}
      />
    </Wrapper>
  );
};

export default Question;

Question.propTypes = {
  question: PropTypes.object.isRequired,
  ACTIONS: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  state: PropTypes.object.isRequired,
};
