/* eslint-disable */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import {
  Modal,
  Typography,
  Button,
  Menu,
  ManuItem,
} from '@mui/material';
import ShowTeam from './ShowTeam';
import AddTeam from './AddTeam';
import { HeaderDiv } from '../../../../styles/wrappers';
import { Briefcase, PlusCircle } from 'react-feather';

const Wrapper = styled.div`
  padding: 10px;
  border-radius: 0px;
  min-height: 208px;
  flex-grow: 1;
  background: transparent;
  overflow: hidden;

`;

const NoTeamsHolder = styled.div`
  width: 100%;
  height: 100%;
  min-height: 160px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
`;

const TeamsHolder = styled.div`
  padding: 4px;
  margin-top: 8px;
  min-height: 197px;
  max-width: 100%;
  width: 100%;
  flex-grow: 1;
  display: flex;
  align-items: flex-start: 
  justify-content: flex-start;
  gap: 8px;
  overflow: scroll;
`;


const Teams = ({
  teams,
  refetch,
  addTeamModalOpen,
  setAddTeamModalOpen,
}) => {
  return (
  <Wrapper>
    <HeaderDiv 
      title="Your Teams" 
      icon={<Briefcase />} 
      SecondaryComponent={() => (
      <Button
        size="small"
        variant="text"
        color="primary"
        startIcon={<PlusCircle height={12} width={12} color="rgb(51, 122, 183)" />}
        onClick={() => setAddTeamModalOpen(true)}
      >
        Add Team
      </Button>
      )}
    />
    {teams.length === 0 ? (
      <NoTeamsHolder>
        <Typography variant="h6">
          You have no teams
        </Typography>
        <Typography variant="subtitleSmall" sx={{ mb: 4 }}>
          Join your team to recieve modules
        </Typography>
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={() => setAddTeamModalOpen(true)}
        >
          Join A Team
        </Button>
      </NoTeamsHolder>
    ) : (
      <>
        <TeamsHolder>
          {
            teams.map(team => (
              <ShowTeam team={team} key={team.id} refetch={refetch} />
            ))
          }
        </TeamsHolder>
      </>
    )} 
    <Modal
      open={addTeamModalOpen}
      onClose={() => setAddTeamModalOpen(false)}
      aria-labelledby="modal-to-add-team"
      aria-describedby="modal-to-add-team"
    >
      <div>
        <AddTeam setAddTeamModalOpen={setAddTeamModalOpen} />
      </div>
    </Modal>
  </Wrapper>
  )
};

export default Teams;

Teams.propTypes = {
  teams: PropTypes.array.isRequired,
  refetch: PropTypes.func.isRequired,
  addTeamModalOpen: PropTypes.bool.isRequired,
  setAddTeamModalOpen: PropTypes.func.isRequired,
};
