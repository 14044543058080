/* eslint-disable max-len */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-extra-boolean-cast */
/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
/* eslint-disable */
import { camelizeKeys } from 'humps';
import { ROW_TYPES, STREAM_TYPES_ARR, ASSIGNED_STREAMS_ARR } from '../constants/common';

const TOKEN_KEY = 'spflearningtokenxyxyxygoleafsgo';
const THEME_VAR = 'spiffyPreferedTheme';

export const setToken = token => localStorage.setItem(TOKEN_KEY, token);
export const getToken = () => localStorage.getItem(TOKEN_KEY);
export const removeToken = () => localStorage.removeItem(TOKEN_KEY);

export const getInitialTheme = () => {
  const theme = localStorage.getItem(THEME_VAR);
  if (!theme || theme === 'light') {
    return true;
  }
  return false;
};

export const setInitialTheme = preferred => localStorage.setItem(THEME_VAR, preferred);

export const addLogoString = logoStr => `https://spiffy-staging.s3.amazonaws.com/media/${logoStr}`;

export const handleModuleResponseV2 = response => {
  const cleanResponse = camelizeKeys(response);

  const { assigned, follower } = cleanResponse;
  const allMods = [];

  /// CUSTOM STREAMS
  const cleanAssignedStreams =
    assigned.streams.length === 0
      ? []
      : assigned.streams.map(stream => {
          allMods.push(...stream.quizzes);
          return {
            company: {
              companyId: stream.companyId,
              companyName: stream.marketplaceParentCompanyName || stream.companyName,
              assigningCompany: stream.companyName,
              streamName: stream.name,
              logo: stream.marketplaceParentCompanyId
                ? addLogoString(stream.marketplaceParentCompanyLogo)
                : addLogoString(stream.companyLogo),
              usersCompany: !Boolean(stream.marketplaceParentCompanyId),
            },
            modules: stream.quizzes,
            isStream: true,
            isFollower: false,
          };
        });

  /// Custom Modules
  const assignedModulesGrouped = {};
  assigned.quizzes.forEach(mod => {
    allMods.push(mod);
    if (!mod.marketplaceParentCompanyId) {
      if (assignedModulesGrouped.hasOwnProperty(mod.companyId)) {
        assignedModulesGrouped[mod.companyId].modules.push(mod);
      } else {
        assignedModulesGrouped[mod.companyId] = {
          company: {
            companyName: mod.companyName,
            name: mod.companyName,
            assigningCompany: mod.companyName,
            logo: addLogoString(mod.companyLogo),
            companyId: mod.companyId,
            usersCompany: true,
          },
          modules: [mod],
          isStream: false,
          isFollower: false,
        };
      }
    }
    if (mod.marketplaceParentCompanyId) {
      if (assignedModulesGrouped.hasOwnProperty(mod.marketplaceParentCompanyId)) {
        assignedModulesGrouped[mod.marketplaceParentCompanyId].modules.push(mod);
      } else {
        assignedModulesGrouped[mod.marketplaceParentCompanyId] = {
          company: {
            companyName: mod.marketplaceParentCompanyName,
            name: mod.marketplaceParentCompanyName,
            assigningCompany: mod.companyName,
            logo: addLogoString(mod.marketplaceParentCompanyLogo),
            companyId: mod.marketplaceParentCompanyId,
            usersCompany: false,
          },
          modules: [mod],
          isStream: false,
          isFollower: false,
        };
      }
    }
  });

  const cleanAssignedModules = Object.values(assignedModulesGrouped).sort(
    g => g.company.usersCompany
  );
  /// Follower Streams

  const cleanFollowerStreams =
    follower.streams.length === 0
      ? []
      : follower.streams.map(stream => {
          allMods.push(...stream.quizzes);
          return {
            isFollower: true,
            isStream: true,
            modules: stream.quizzes,
            company: {
              companyId: stream.companyId,
              companyName: stream.companyName,
              logo: addLogoString(stream.companyLogo),
              streamName: stream.name,
              order: 0, // take out later
            },
          };
        });

  /// Follower Modules

  const followerModulesGrouped = {};

  follower.quizzes.forEach(mod => {
    allMods.push(mod);
    if (followerModulesGrouped.hasOwnProperty(mod.companyId)) {
      followerModulesGrouped[mod.companyId].modules.push(mod);
    } else {
      followerModulesGrouped[mod.companyId] = {
        isStream: false,
        isFollower: true,
        company: {
          companyName: mod.companyName,
          logo: addLogoString(mod.companyLogo),
          name: mod.companyName,
          usersCompany: false,
        },
        modules: [mod],
      };
    }
  });

  const cleanFollowerModules = Object.values(followerModulesGrouped);

  return {
    allMods,
    assignedStreams: cleanAssignedStreams,
    assignedModules: cleanAssignedModules,
    followerStreams: cleanFollowerStreams,
    followerModules: cleanFollowerModules,
  };
};

const hideHiddenRowsAndModules = feedRows => {
  try {
    const modIds = [];

    feedRows.forEach(row => {
      row.quizzes.forEach(quiz => {
        if (
          modIds.includes(quiz.id) ||
          (quiz?.marketplaceParentId && modIds.includes(quiz?.marketplaceParentId))
        ) {
          if (!ASSIGNED_STREAMS_ARR.includes(row.rowType)) {
            quiz.hidden = true;
          } else {
            quiz.hidden = false;
          }
        } else {
          quiz.hidden = false;
          const moduleIds = quiz.marketplaceParentId
            ? [quiz.marketplaceParentId, quiz.id]
            : [quiz.id];
          modIds.push(...moduleIds);
        }
      });
    });

    feedRows.forEach(row => {
      if (ASSIGNED_STREAMS_ARR.includes(row.rowType)) {
        row.hidden = false;
      } else {
        const allQuizzesInRowHidden = row.quizzes.every(quiz => quiz.hidden);
        if (allQuizzesInRowHidden) {
          row.hidden = true;
        } else {
          row.hidden = false;
        }
      }
    });

    return feedRows.filter(row => !row.hidden);
  } catch (error) {
    console.warn(error);
  }
};

export const handleModuleResponseV3 = response => {
  try {
    const { assigned, follower } = response;

    //------Assigned Streams----//
    // first show assigned streams, ordered ASC first, company id second
    const orderedAssignedStreamRows = assigned.streams
      .sort((a, b) => {
        if (a.stream_order === b.stream_order) {
          return a.companyId - b.companyId;
        }
        return a.stream_order - b.stream_order;
      })
      .map(stream => {
        return {
          ...stream,
          rowType: stream.marketplace_parent_company_id
            ? ROW_TYPES.ASSIGNED_FOLLOWER_STREAM
            : ROW_TYPES.ASSIGNED_STREAM,
        };
      });

    //------Assigned Modules----//
    // Return rows of custom modules first, then ones assigned from marketplace
    const orderedAssignedModuleRows = assigned.quizzes.map(arr => {
      return {
        quizzes: [...arr],
        company_name: arr[0].company__name,
        company_logo: arr[0].company__logo,
        rowType: arr[0].marketplace_parent_company_id
          ? ROW_TYPES.ASSIGNED_MP_MODULE
          : ROW_TYPES.ASSIGNED_CUSTOM_MODULE,
      };
    });

    //---- Follower Content--------//
    const orderedFollowerRows = [];
    follower.forEach(company => {
      const hasQuizzes = company.quizzes.length > 0;
      const hasStreams = company.streams.length > 0;

      if (hasStreams) {
        company.streams
          .sort((a, b) => a.stream_order - b.stream_order)
          .forEach(stream =>
            orderedFollowerRows.push({
              ...stream,
              company_name: stream.company__name,
              company_logo: stream.company__logo,
              rowType: ROW_TYPES.FOLLOWER_STREAM,
            })
          );
      }

      if (hasQuizzes) {
        orderedFollowerRows.push({
          quizzes: company.quizzes,
          company_name: company.quizzes[0].company__name,
          company_logo: company.quizzes[0].company__logo,
          rowType: ROW_TYPES.FOLLOWER_MODULE,
        });
      }
    });

    const feedRows = [
      ...orderedAssignedStreamRows,
      ...orderedAssignedModuleRows,
      ...orderedFollowerRows,
    ];

    const feedRowsHiddenRemoved = hideHiddenRowsAndModules(feedRows);

    const allModsOrdered = feedRowsHiddenRemoved.flatMap(row => [
      ...row.quizzes.filter(quiz => !quiz.hidden),
    ]);

    return {
      feedRows: feedRowsHiddenRemoved,
      allMods: allModsOrdered,
    };
  } catch (error) {
    return [];
  }
};

export const getNumberWithOrdinal = n => {
  if (!n) return 'N/A';

  const s = ['th', 'st', 'nd', 'rd'],
    v = n % 100;
  if (n === 1) {
    return '🥇1st';
  }
  if (n === 2) {
    return '🥈2nd';
  }
  if (n === 3) {
    return '🥉3rd';
  }
  return n + (s[(v - 20) % 10] || s[v] || s[0]);
};

export const getTimezoneName = () => {
  const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;

  return tz || 'America/Toronto';
};
