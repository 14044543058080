/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import { ChevronLeft } from 'react-feather';
import styled from 'styled-components/macro';
import { LEADERBOARD_TYPES } from '../../../constants/common';

const Text = styled.span`
  color: rgb(51,122,183);
  font-weight: 500;
`;

const Flex = styled.div`
  display: flex;
  align-items; center;
  justify-content: center;
`;

const GreyText = styled.span`
  margin-left: 4px;
  font-weight: 500;
  color: darkgrey;
`;

const GreyDiv = styled.div`
  width: 140px;
  height: 24px;
  background-color: lightgrey;
`;

const SwitchBtn = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  width: 20px;
  background-color: white;
  margin-right: 8px;

  svg {
    color: rgb(51,122,183)
  }
`;

const LeaderboardInfoText = ({
  data,
  isLoading,
  leaderboardType,
  setTeamId,
}) => {
  if (isLoading) return <GreyDiv />;

  const { selected_team_member, leaderboard, other_team_member_options } = data;

  const hasMultipleTeamMembers = other_team_member_options.length > 0;

  if (leaderboardType === LEADERBOARD_TYPES.LOCATION) {
    return (
      <Flex>
        <Text>📍 {selected_team_member.location__name}</Text>
        <GreyText>{leaderboard.size} people</GreyText>
      </Flex>
    );
  }

  if (leaderboardType === LEADERBOARD_TYPES.COMPANY) {
    return (
      <Flex>
        {
          hasMultipleTeamMembers && (
            <SwitchBtn onClick={() => setTeamId(other_team_member_options[0].id)}>
              <ChevronLeft />
            </SwitchBtn>
          )
        }
        <Text>🏢 {selected_team_member.company__name}</Text>
        <GreyText>{leaderboard.size} people</GreyText>
      </Flex>

    );
  }

  return (
    <Flex>
      <Text>🌐 {selected_team_member.vertical__name}</Text>
      <GreyText>{leaderboard.size} people</GreyText>
    </Flex>
  );
};

export default LeaderboardInfoText;

LeaderboardInfoText.propTypes = {
  data: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  leaderboardType: PropTypes.string.isRequired,
  setTeamId: PropTypes.func.isRequired,
};
