import React from 'react';
import { CircularProgress } from '@mui/material';
import styled from 'styled-components/macro';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CustomCircular = styled(CircularProgress)`
  color: ${props => props.theme.palette.background.wb};
`;

const ComponentLoader = () => (
  <Wrapper>
    <CustomCircular size={30} />
  </Wrapper>
);

export default ComponentLoader;
