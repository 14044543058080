import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import styled from 'styled-components/macro';
import { MapPin } from 'react-feather';
import { Option, SelectedIcon } from './styles';

const ResultPin = styled(MapPin)`
  margin-right: 8px;
  height: 12px;
  width: 12px;
  color: ${props => props.theme.palette.background.bw};
`;

const FlexDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FixMargin = styled(Typography)`
  margin-bottom: -3px;
  margin-left: 8px;
`;

const LocationOption = ({
  loc,
  selected,
  setSelected,
}) => {
  const { line1, city, country } = loc.address;

  return (
    <Option onClick={() => setSelected(loc)}>
      <FlexDiv>
        <ResultPin />
        <span>
          {loc.name}
        </span>
        <FixMargin variant="subtitleSmall">
          {`${line1}, ${city.name}, ${country.name}`}
        </FixMargin>
      </FlexDiv>
      {
        (selected && (selected.id === loc.id)) ? <SelectedIcon /> : <div />
      }
    </Option>
  );
};

export default LocationOption;

LocationOption.propTypes = {
  loc: PropTypes.object.isRequired,
  selected: PropTypes.object.isRequired,
  setSelected: PropTypes.func.isRequired,
};
