/* eslint-disable */
/* eslint-disable no-case-declarations */
import React, { useReducer, useEffect } from 'react';
import styled from 'styled-components/macro';
import { useParams } from 'react-router-dom';
import MaxWidthWrapper from '../../../components/Reuseable/MaxWidthWrapper';
import PageHeader from '../../../components/Reuseable/PageHeader';
import Stepper from './Stepper';
import Overview from './Overview';
import Content from './Content';
import Questions from './Questions';
import Review from './Review';
import Results from './Results';
import useModuleInfo from '../../../reactQueryHooks/useModuleInfo';
import PageLoader from '../../../components/Loaders/PageLoader';
import PageError from '../../../components/Reuseable/PageError';

const Wrapper = styled.div`
  padding: 20px;
  max-width: 100vw;

  @media(max-width: 500px) {
    padding: 10px;
  }

  @media(max-width: 300px) {
    padding: 0px 4px;
  }
`;

const initialState = {
  index: 0,
  hasDisclaimer: false,
  disclaimerChecked: false,
  questions: [],
  numQuestions: null,
  activeQuestion: 0,
  passed: null,
  videoWatchPercent: null,
  startTime: null,
  moduleId: null,
  isEditing: false,
  questionsCorrect: null,
};

const ACTIONS = {
  INCREASE_INDEX: 'increaseIndex',
  DECREASE_INDEX: 'decreaseIndex',
  INCREASE_ACTIVE_QUESTION: 'increaseActiveQuestion',
  DECREASE_ACTIVE_QUESTION: 'decreaseActiveQuestion',
  TOGGLE_DISCLAIMER: 'toggleDisclaimer',
  SET_INITIAL_QUESTIONS: 'setInitialQuestions',
  SET_SAVED_ANSWER: 'setSavedAnswer',
  EDIT_QUESTION: 'editQuestion',
  SUBMIT_MODULE: 'submitModule',
  RESET_MODULE: 'resetModule',
  SET_VIDEO_WATCH_PERCENT: 'setVideoWatchPercent',
  JUMP_TO_QUESTION: 'jumpToQuestion',
};

const updateQuestionWithAnswer = (id, answer, questions) => {
  const newQuestions = questions.map(q => {
    if (q.id === id) {
      return {
        ...q,
        savedAnswer: answer,
      };
    }

    return {
      ...q,
    };
  });

  return newQuestions;
};

const reducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.INCREASE_INDEX:
      if (state.index === 0) {
        return {
          ...state,
          index: state.index + 1,
          startTime: new Date(),
        };
      }
      return {
        ...state,
        index: state.index + 1,
      };
    case ACTIONS.DECREASE_INDEX:
      return {
        ...state,
        index: state.index - 1,
      };
    case ACTIONS.TOGGLE_DISCLAIMER:
      return {
        ...state,
        disclaimerChecked: action.payload,
      };
    case ACTIONS.INCREASE_ACTIVE_QUESTION:
      return {
        ...state,
        activeQuestion: state.activeQuestion + 1,
      };
    case ACTIONS.DECREASE_ACTIVE_QUESTION:
      return {
        ...state,
        activeQuestion: state.activeQuestion - 1,
      };
    case ACTIONS.JUMP_TO_QUESTION:
      return {
        ...state,
        activeQuestion: action.payload.index,
      };
    case ACTIONS.SET_SAVED_ANSWER:
      return {
        ...state,
        questions: updateQuestionWithAnswer(
          action.payload.questionId,
          action.payload.answer,
          state.questions,
        ),
      };
    case ACTIONS.SET_INITIAL_QUESTIONS:
      return {
        ...state,
        questions: action.payload.questions,
        numQuestions: action.payload.questions.length,
        moduleId: action.payload.moduleId,
        hasDisclaimer: action.payload.hasDisclaimer,
      };
    case ACTIONS.SUBMIT_MODULE:
      return {
        ...state,
        index: 4,
        passed: action.payload.passed,
        questions: action.payload.updatedQuestions,
        questionsCorrect: action.payload.questionsCorrect,
      };
    case ACTIONS.EDIT_QUESTION:
      return {
        ...state,
        index: 2,
        activeQuestion: action.payload,
      };
    case ACTIONS.RESET_MODULE:
      const resetQuestions = state.questions.map(q => (
        {
          ...q,
          savedAnswer: null,
        }
      ));
      return {
        ...initialState,
        questions: resetQuestions,
        numQuestions: state.numQuestions,
        moduleId: state.moduleId,
      };
    default:
      return { ...state };
  }
};

const Module = () => {
  const params = useParams();
  const { id } = params;

  if (!id) {
    return <PageError />;
  }

  const { data: module, isLoading, error } = useModuleInfo(id);

  const [state, dispatch] = useReducer(reducer, initialState);
  const { index } = state;

  useEffect(() => {
    if (isLoading) return;
    if (error) return;

    const cleanQuestions = JSON.parse(module.questions);
    const initialQuestions = cleanQuestions.questions.map((question) => ({
      ...question,
      savedAnswer: null,
    }));
    // eslint-disable-next-line max-len
    return dispatch({ type: ACTIONS.SET_INITIAL_QUESTIONS, payload: { questions: initialQuestions, moduleId: module.id, hasDisclaimer: module.disclaimer } });
  }, [isLoading]);

  if (isLoading) {
    return (
      <PageLoader />
    );
  }

  if (error || !id) {
    return (
      <PageError />
    );
  }

  return (
    <>
      <PageHeader text={`Module | ${module.name}`} isNested />
      <Wrapper>
        <MaxWidthWrapper>
          <Stepper
            state={state}
            dispatch={dispatch}
            ACTIONS={ACTIONS}
            scoreToPass={module.pass_score}
          />
          {
            index === 0 && (
              <Overview module={module} state={state} dispatch={dispatch} ACTIONS={ACTIONS} />
            )
          }
          {
            index === 1 && (
              <Content module={module} state={state} dispatch={dispatch} ACTIONS={ACTIONS} />
            )
          }
          {
            index === 2 && (
              <Questions module={module} state={state} dispatch={dispatch} ACTIONS={ACTIONS} />
            )
          }
          {
            index === 3 && (
              <Review module={module} state={state} dispatch={dispatch} ACTIONS={ACTIONS} />
            )
          }
          {
            index === 4 && (
              <Results module={module} state={state} dispatch={dispatch} ACTIONS={ACTIONS} />
            )
          }
        </MaxWidthWrapper>
      </Wrapper>
    </>
  );
};

export default Module;
