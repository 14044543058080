/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { Typography, Button, Tooltip } from '@mui/material';
import { motion } from "framer-motion"
import { useNavigate } from "react-router-dom";
import { ArrowLeft, User } from 'react-feather';

const Wrapper = styled.div`
  margin: 4px 8px 0px 8px;
  padding: 8px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-bottom: 1px solid ${props => props.theme.palette.background.contrast};

  @media(max-width: 500px) {
    max-width: 98vw;
  } 
`;

const BackButton = styled(Button)`
  height: 36px;
  width: 36px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: 36px;
  max-width: 36px;
  min-height: 36px;
  min-width: 36px;
  background: white;
  border: 1px solid #eeeeee;
  transition: 200ms ease-in;


  svg {
    color: slategrey;
  }

  &:hover {

    background: #eeeeee;

    svg {
      color: black;
    }
  }
`

const TitleText = styled(Typography)`
  width: fit-content;
  color: ${props => props.theme.palette.background.bw};
  font-family: 'Raleway', sans-serif;
  padding: 4px 12px;
  background-color: ${props => props.theme.palette.background.paper};
  font-size: 18px;
  border-radius: 4px;
  font-weight: 600;
  height: 36px;
  min-height: 36px;
  white-space: nowrap;
  // letter-spacing: 1px;
  border: 1px solid #EEEEEE;
  // border-left: 4px solid ${props => props.theme.palette.primary.main};

  @media(max-width: 600px) {
    font-size: 14px;
    max-width: 100%;
  }

  @media(max-width: 400px) {
    padding: 4px 4px;
    font-size: 14px;
    max-width: 100%;
  }
`;

const HoldChildren = styled.div`
  justify-self: flex-end;
  flex-grow: 1;
`;

const PageHeader = ({
  text,
  isNested = false,
  children,
}) => {

  const navigate = useNavigate();

  return (
    <Wrapper>
      {
        isNested && (
        <>
      <Tooltip arrow title="go back">

      <BackButton
        onClick={() => navigate(-1)}
      >
        <ArrowLeft />
      </BackButton>
      </Tooltip>
      <div style={{ width: 6 }} />
        </>
        )        
      }
      <TitleText
        // as={motion.span}
        // initial={{ scale : 0.9, x: 10 }}
        // animate={{ scale: 1, x: 0 }}
      >        
        {text}
      </TitleText>
      {
        children && (
          <HoldChildren>
            {children}
          </HoldChildren>
        )
      }
    </Wrapper>
  );
}


export default PageHeader;

PageHeader.propTypes = {
  text: PropTypes.string.isRequired,
  children: PropTypes.node,
  isLearnPage: PropTypes.bool,
};

PageHeader.defaultProps = {
  isLearnPage: false,
  children: undefined,
};
