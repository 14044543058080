import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

const Wrapper = styled.div`
  width: 100%;
  min-width: 300px;
  padding-top: 10px;
  margin-top: -8px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  flex-grow: 1;
  overflow-y: scroll;
  padding: 10;
  background-color: white;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  border: 1px solid lightgrey;

  @media(max-width: 400px) {
    min-width: 100%;
  }
`;

const QuestionWrapper = styled.div`
  width: 100%;
  border-bottom: 1px solid #EEEEEE; 
  display: flex; 
  flex-direction: column; 
  align-items: flex-start; 
  justify-content: flex-start; 
  padding: 10px;
`;

const getAnswer = question => {
  const { type, savedAnswer, options } = question;
  if (type === 'tf') {
    return <p style={{ textTransform: 'capitalize' }}>{String(savedAnswer)}</p>;
  }

  if (type === 'mc') {
    const answer = options[savedAnswer.toLowerCase()];
    return <p>{`${savedAnswer}) ${answer}`}</p>;
  }

  // For Select All
  const answerArr = [];
  savedAnswer.forEach(answer => {
    answerArr.push(`${answer}) ${options[answer.toLowerCase()]}`);
  });

  // return answerArr.join(' ');
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
      }}
    >
      {
        answerArr.map(a => <p>{a}</p>)
      }
    </div>
  );
};

const ShowAnswers = ({
  questions,
}) => (
  <Wrapper>
    {
      questions.map(question => (
        <QuestionWrapper key={question.id}>
          <p style={{ fontSize: 10, color: 'lightgray' }}>
            Question {question.order + 1}
          </p>
          <span style={{ fontWeight: 500, textAlign: 'left' }}>
            {question.question}
          </span>
          <p style={{ fontSize: 10, color: 'lightgray', marginTop: 5 }}>
            You Answered
          </p>
          <div
            style={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              color: question.correct ? 'mediumseagreen' : 'firebrick',
            }}
          >
            {getAnswer(question)}
            {
              question.correct
                ? <span style={{ color: 'mediumseagreen', fontWeight: 500 }}>Correct</span>
                : <span style={{ color: 'firebrick', fontWeight: 500 }}>Incorrect</span>
            }
          </div>
        </QuestionWrapper>
      ))
    }
  </Wrapper>
);

export default ShowAnswers;

ShowAnswers.propTypes = {
  questions: PropTypes.array.isRequired,
};
