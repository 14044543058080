import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { Checkbox } from '@mui/material';
import {
  ReviewOption,
  ReviewIndicator,
} from '../styles';

const Wrapper = styled.div`
  padding: 4px 8px;
`;

const StyledCheckbox = styled(Checkbox)`
  padding: 0px; 
  height: 14px;
  width: 14px;
  margin-right: 3px;

  svg {
    height: 14px;
    width: 14px;
  }
`;

const ReviewSelectAll = ({
  question,
}) => (
  <Wrapper>
    {Object.entries(question.options).map(option => (
      <ReviewOption
        key={option[0]}
        selected={question.savedAnswer.includes(option[0].toUpperCase()) ? 'yes' : 'no'}
      >
        <span style={{ fontSize: 12 }}>
          {`${option[0].toUpperCase()})  ${option[1]}`}
        </span>
        {
          question.savedAnswer.includes(option[0].toUpperCase())
            ? <ReviewIndicator><div /></ReviewIndicator>
            : <StyledCheckbox checked={false} size="small" disableRipple style={{ color: 'darkgrey' }} />
        }
      </ReviewOption>
    ))}
  </Wrapper>
);

export default ReviewSelectAll;

ReviewSelectAll.propTypes = {
  question: PropTypes.object.isRequired,
};
