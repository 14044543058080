import { useQuery } from 'react-query';
import API from '../axios/API';

const getUserProfile = async () => {
  const { data } = await API.get('/accounts/profile/');
  return data;
};

const useUserProfile = () => useQuery('profile', getUserProfile);

export default useUserProfile;
