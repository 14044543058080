import React, { useState } from 'react';
import styled from 'styled-components/macro';
import {
  Paper,
  Typography,
  Button,
} from '@mui/material';
import useAuth from '../../../store/auth';
import useSnack from '../../../store/snack';
import API from '../../../axios/API';
import LinearProgLoader from '../../../components/Loaders/LinearProgLoader';

const ModalWrapper = styled(Paper)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
  padding: 20px;
  display: flex;
  flex-direction: column;
`;

const DeleteButton = styled(Button)`
  background-color: ${props => props.theme.palette.error.main};
  color: white;

&:hover {
    background-color: ${props => props.theme.palette.error.light};
  }
`;

const DeleteAccountModal = () => {
  const [isDeleting, setIsDeleting] = useState(false);
  const { logout } = useAuth();
  const { snack } = useSnack();
  const { setSnack, SNACK_TYPES } = snack;

  const handleDeleteAccount = async () => {
    setIsDeleting(true);
    try {
      await API.delete('/accounts/delete/');
      setIsDeleting(false);
      setSnack('Your account has been successfully deleted', SNACK_TYPES.WARNING);
      logout();
    } catch (error) {
      setIsDeleting(false);
      setSnack('Error trying to delete your account, please try again later', SNACK_TYPES.ERROR);
    }
  };

  return (
    <ModalWrapper>
      <Typography
        variant="h6"
        sx={{ mb: 1 }}
      >
        Are you sure you want to delete your account?
      </Typography>
      <Typography
        variant="subtitleSmall"
      >
        All your personal information and progress will be deleted.
      </Typography>
      <Typography
        variant="subtitleSmall"
        sx={{ mb: 8 }}
      >
        You cannot recover your account after this action.
      </Typography>
      <DeleteButton
        variant="contained"
        size="small"
        onClick={handleDeleteAccount}
      >
        Delete My Account
        {isDeleting && <LinearProgLoader />}
      </DeleteButton>
    </ModalWrapper>
  );
};

export default DeleteAccountModal;
