/* eslint-disable */
import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { Button, Paper, Typography, Alert, AlertTitle } from '@mui/material';
import API from '../../axios/API';
import useAuth from '../../store/auth';
import useSnack from '../../store/snack';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const Form = styled(Paper)`
  width: 440px;
  min-height: 220px;
  padding: 20px;
`;

const DeleteAccountWithAuth = () => {
  const { logout } = useAuth();
  const { snack } = useSnack();
  const { setSnack, SNACK_TYPES } = snack;
  const [isDeleting, setIsDeleting] = useState(false);

  const handleDeleteAccount = async () => {
    setIsDeleting(true);
    try {
      await API.delete('/accounts/delete/');
      setIsDeleting(false);
      setSnack('Your account has been successfully deleted', SNACK_TYPES.WARNING);
      logout();
    } catch (error) {
      setIsDeleting(false);
      setSnack('Error trying to delete your account, please try again later', SNACK_TYPES.ERROR);
    }
  };

  return (
    <Wrapper>
      <Form>
        <Typography variant="h4">Delete Account & Personal Data</Typography>

        <Alert
          severity="error"
          sx={{ mt: 4 }}
          style={{ borderRadius: 8 }}
        >
          <AlertTitle style={{ marginTop: 2 }}>All user data will be deleted</AlertTitle>
          <ul>
            <li style={{ marginLeft: 10 }}>
              <p style={{ fontSize: 10 }}>
                Confirming will result in all user data associated with your account being deleted
                from our database.
              </p>
            </li>
            <li style={{ marginLeft: 10 }}>
              <p style={{ fontSize: 10 }}>
                This action is not reversible. You will not be able to recover your account
                afterwards.
              </p>
            </li>
          </ul>
        </Alert>
        <Button
          fullWidth
          variant="contained"
          color="error"
          sx={{ p: 1, fontSize: 14, mt: 4 }}
          onClick={handleDeleteAccount}
        >
          Confirm Deletion
        </Button>
      </Form>
    </Wrapper>
  );
};

export default DeleteAccountWithAuth;
