/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { keyframes } from 'styled-components';

const SlideIn = keyframes`
  0% { transform: translateX(16px); opacity: .8; }
  100% { transform: translateX(0px); opacity: 1; }
`;

const Wrapper = styled.div`
  background-color: ${props => {
    if (props.isUser === 'yes') {
      return 'aliceblue';
    }
    return '#EEEEEE';
  }};
  animation-name: ${SlideIn};
  animation-duration: 260ms;
  border-radius: 10px;
  margin-bottom: 8px;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 2px;
`;

const HoldRank = styled.div`
  background-color: white;
  height: 34px;
  width: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
`;

const ScoreContainer = styled.div`
  flex: 0.2;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 22px;
`;

const ScoreSpan = styled.span`
  font-weight: 800; 
  color: rgb(51, 122, 183); 
  font-size: 21px;
`;

const LeaderboardItem = ({
  info,
  usersID,
}) => {
  
return (
  <Wrapper 
    isUser={usersID === info?.user_id ? 'yes' : 'no'}
  >
    <div style={{ flex: 0.1 }}>
      <HoldRank>
        <span style={{ fontWeight: 600, fontSize: 16, color: 'black', textTransform: 'uppercase',}}>
          {
            info?.rank
          }
        </span>
      </HoldRank>
    </div>
    <div style={{ flex: 0.7 }}>
      <span style={{ fontWeight: '400', fontSize: 16, color: 'black' }}>
        {info?.name}
      </span>
      {
          usersID === info?.user_id && (
          <span style={{ marginLeft: 4, fontSize: 16 }}>
            😎
          </span>
          )
      }
    </div>
    <ScoreContainer>
      <ScoreSpan>
        {
          info?.score
        }
      </ScoreSpan>
    </ScoreContainer>
  </Wrapper>

)};

export default LeaderboardItem;

LeaderboardItem.propTypes = {
  info: PropTypes.object.isRequired,
  usersID: PropTypes.number.isRequired,
};
