/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip, Typography } from '@mui/material';
import styled from 'styled-components/macro';
import { 
  ASSIGNED_TYPES_ARR, 
} from '../../../constants/common';
import { motion } from 'framer-motion'

const AssignedChip = styled.div`
  
  border-radius: 6px;
  width: 26px;
  height: 26px;
  background-color: rgba(0, 0, 250, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-transform: uppercase;

  span {
    font-size: 14px;
    font-weight: 500;
    color: rgb(88, 125, 234);
  }
`;


const ExtraCreditChip = styled.div`
  border-radius: 6px;
  background-color: #fffdd0;
  display: flex;
  width: 26px;
  height: 26px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-transform: uppercase;

  span {
    font-size: 14px;
    font-weight: 500;
    color: rgb(170, 151, 0);
  }
`;

const ToolTipContainer = styled.div`
  min-width: 120px;
  padding: 4px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
`;

const InfoPopper = ({
  item,
}) => {
  const { rowType } = item;
 
  if (ASSIGNED_TYPES_ARR.includes(rowType)) {
    return (
      <Tooltip
      arrow
      PopperProps={{
        sx: {
          '& .MuiTooltip-tooltip': {
            backgroundColor: 'white',
            border: '1px solid #bdbdbd',
          },
        },
      }}
      title={(
        <ToolTipContainer>
          <Typography variant="subtitleSmall">
            Content Assigned By
          </Typography>
          <Typography variant="subtitle2" style={{color: 'black'}}>
            {item.company_name}
          </Typography>
        </ToolTipContainer>
      )}
    >
      <AssignedChip
        as={motion.div}
        transition={{ delay: 0.3 }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
      >
        <span>
          A
        </span>
      </AssignedChip>
    </Tooltip>
    )
  }

  return (
      <Tooltip
        arrow
        PopperProps={{
          sx: {
            '& .MuiTooltip-tooltip': {
              backgroundColor: 'white',
              border: '1px solid #bdbdbd',
            },
          },
        }}
        title={(
          <ToolTipContainer>
            <Typography variant="subtitleSmall">
              You Follow Brand
            </Typography>
            <Typography 
              variant="subtitle2" 
              style={{color: 'black'}}
            >
              {item.company_name}
            </Typography>
          </ToolTipContainer>
      )}
      >
        <ExtraCreditChip
          as={motion.div}
          transition={{ delay: 0.3 }}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
        >
          <span>
            EC
          </span>
        </ExtraCreditChip>
      </Tooltip>
  );
}



export default InfoPopper;

InfoPopper.propTypes = {
  item: PropTypes.object.isRequired,
};
